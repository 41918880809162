import { Fragment, ReactElement } from "react";
import * as React from "react";
import { Theme } from "@emotion/react";
import {
  IconBankFilled,
  IconBrandPayPal,
  IconCardOutlined,
  IconCheck,
  IconInfoOutlined,
  IconLightningFilled,
  IconSpendingTransfers,
  IconStaticBrandPlaid,
} from "@hubble/icons";
import { CURRENCIES_DETAIL, CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { LOCKOUT_TYPES } from "@gemini-ui/components/Lockout/constants";
import LockoutMessage from "@gemini-ui/components/Lockout/LockoutMessage";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { Badge, IconBadge, Spacer, Text, Tooltip } from "@gemini-ui/design-system";
import {
  getIntlDescriptionCopy,
  LinkPaymentType,
  TEST_IDS,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import {
  PaymentMethodDescriptionContainer,
  StyledListItem,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/styles";
import {
  getDebitDescription,
  getManualDescription,
  getPlaidDescription,
} from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";
import { FlexContainer } from "@gemini-ui/pages/settings/BankSettings/styles";
import { IntlShape, useIntl } from "@gemini-ui/utils/intl";

interface RenderAddPaymentMethodsModalPropTypes {
  titles: { [key: string]: string };
  handleClick?: (patymentMethod: LinkPaymentType) => void;
  canManagePaymentMethods: boolean;
  isRedirectedFromWithdrawals: boolean;
  intl: IntlShape;
  isRecommended?: boolean;
  isDisabled?: boolean;
  templateProps;
  theme: Theme;
  shouldRenderUnavailable?: boolean;
  currency?: CurrencyShortNameFiat;
  countryCode?: string;
}

interface PaymentMethodPropTypes {
  icon: ReactElement;
  title: string;
  canManagePaymentMethods: boolean;
  isRecommended: boolean;
  isDisabled: boolean;
  description: ReactElement;
  dataTestId: string;
  tooltipTestId: string;
  onClick: () => void;
}

const PaymentMethodDescription = ({
  details,
  renderCustomDescription,
}: {
  details: string[];
  renderCustomDescription?: any;
}) => {
  return (
    <PaymentMethodDescriptionContainer>
      {details.map((detail, index) => (
        <FlexContainer key={typeof detail === "string" ? detail : index}>
          <IconBadge size="sm" backgroundColor="" icon={<IconCheck />} />
          <Text.Body ml={1} size="sm">
            {detail}
          </Text.Body>
        </FlexContainer>
      ))}
      {renderCustomDescription}
    </PaymentMethodDescriptionContainer>
  );
};

const PaymentMethod = ({
  canManagePaymentMethods,
  title,
  icon,
  description,
  dataTestId,
  tooltipTestId,
  onClick,
  isRecommended = false,
  isDisabled = false,
}: PaymentMethodPropTypes) => {
  const { intl } = useIntl();

  return (
    <StyledListItem
      isDisabled={isDisabled}
      data-testid={dataTestId}
      onClick={!isDisabled ? onClick : () => {}}
      left={icon}
      alignItems="center"
      hasDivider
      as={!isDisabled ? "button" : undefined}
    >
      <Tooltip
        disabled={canManagePaymentMethods}
        data-testid={tooltipTestId}
        overlay={<LockoutMessage lockout={LOCKOUT_TYPES.MANAGE_BANK_ACCOUNTS} />}
      >
        <Spacer ml={1.5}>
          <Text.Body bold mb={0.5}>
            {title}
          </Text.Body>
          {isRecommended && (
            <Spacer mb={0.5}>
              {isRecommended && (
                <Badge data-testid="recommended-badge" status="info" icon={<IconLightningFilled />}>
                  {intl.formatMessage({ defaultMessage: "Recommended" })}
                </Badge>
              )}
            </Spacer>
          )}
          {description}
        </Spacer>
      </Tooltip>
    </StyledListItem>
  );
};

const renderPlaidPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  templateProps,
  shouldRenderUnavailable,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const { DEPOSIT, WITHDRAW } = getIntlDescriptionCopy(intl);
  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={isNewAddPaymentsFlowEnabled ? titles.LINK_PLAID_OR_GIACT : titles.LINK_BANK_VIA_PLAID_TITLE}
      icon={<IconBadge icon={isNewAddPaymentsFlowEnabled ? <IconBankFilled /> : <IconStaticBrandPlaid />} />}
      description={
        <PaymentMethodDescription
          details={
            isNewAddPaymentsFlowEnabled
              ? [DEPOSIT, WITHDRAW]
              : getPlaidDescription(templateProps.user.countryCode, templateProps.account.geminiEntity, intl)
          }
        />
      }
      dataTestId={TEST_IDS.ADD_PLAID_BUTTON}
      tooltipTestId="addPlaidTooltip"
      onClick={() => handleClick(LinkPaymentType.PLAID)}
      isRecommended={isRecommended}
      isDisabled={isDisabled || shouldRenderUnavailable}
    />
  );
};

const renderGiactPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  shouldRenderUnavailable,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const { DEPOSIT, WITHDRAW } = getIntlDescriptionCopy(intl);
  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={titles.LINK_GIACT_TITLE}
      icon={<IconBadge icon={<IconBankFilled />} />}
      description={<PaymentMethodDescription details={[DEPOSIT, WITHDRAW]} />}
      dataTestId={TEST_IDS.ADD_GIACT_BUTTON}
      tooltipTestId="addBankViaGiact"
      onClick={() => handleClick(LinkPaymentType.GIACT)}
      isRecommended={isRecommended}
      isDisabled={isDisabled || shouldRenderUnavailable}
    />
  );
};

const renderPlaidOrGiactPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  shouldRenderUnavailable,
  theme,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const { DEPOSIT, WITHDRAW, TRADE } = getIntlDescriptionCopy(intl);
  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={titles.LINK_PLAID_OR_GIACT}
      icon={<IconBadge icon={<IconBankFilled />} />}
      description={<PaymentMethodDescription details={[TRADE, DEPOSIT, WITHDRAW]} />}
      dataTestId={TEST_IDS.ADD_PLAID_OR_GIACT_BUTTON}
      tooltipTestId="addPlaidTooltip"
      onClick={() => {
        handleClick(LinkPaymentType.PLAID_OR_GIACT);
        const { name } = EVENTS.OPEN_BANK_ACCOUNT;
        track(name);
      }}
      isRecommended={isRecommended}
      isDisabled={isDisabled || shouldRenderUnavailable}
    />
  );
};

const renderManualPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  templateProps,
  shouldRenderUnavailable,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const isInstitutionalXfersEnabled =
    optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_IS_INSTITUTIONAL_XFERS_ENABLED) &&
    templateProps.user.isInstitutional;
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const { DEPOSIT, WITHDRAW } = getIntlDescriptionCopy(intl);
  return (
    !isInstitutionalXfersEnabled && (
      <PaymentMethod
        canManagePaymentMethods={canManagePaymentMethods}
        title={titles.LINK_BANK_MANUALLY_TITLE}
        icon={<IconBadge icon={<IconBankFilled />} />}
        description={
          <PaymentMethodDescription
            details={
              isNewAddPaymentsFlowEnabled ? [DEPOSIT, WITHDRAW] : getManualDescription(templateProps.geminiEntity, intl)
            }
          />
        }
        dataTestId={TEST_IDS.ADD_BANK_MANUAL_BUTTON}
        tooltipTestId="addWireTooltip"
        onClick={() => handleClick(LinkPaymentType.MANUAL)}
        isRecommended={isRecommended}
        isDisabled={isDisabled || shouldRenderUnavailable}
      />
    )
  );
};

const renderDebitCardPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  templateProps,
}: RenderAddPaymentMethodsModalPropTypes) => {
  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={titles.LINK_DEBIT_CARD_TITLE}
      icon={<IconBadge icon={<IconCardOutlined />} />}
      description={<PaymentMethodDescription details={getDebitDescription(templateProps.account.geminiEntity, intl)} />}
      dataTestId={TEST_IDS.ADD_DEBIT_CARD_BUTTON}
      tooltipTestId="addDebitCardTooltip"
      onClick={() => handleClick(LinkPaymentType.DEBIT)}
      isRecommended={isRecommended}
      isDisabled={isDisabled}
    />
  );
};

const renderPaypalPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  theme,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const { DEPOSIT, TRADE } = getIntlDescriptionCopy(intl);

  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={titles.LINK_PAYPAL_TITLE}
      icon={<IconBadge icon={<IconBrandPayPal color={theme.isDark ? "#ffffff" : "#253B80"} />} />}
      description={<PaymentMethodDescription details={[TRADE, DEPOSIT]} />}
      dataTestId={TEST_IDS.ADD_PAYPAL_BUTTON}
      tooltipTestId="addPayPalTooltip"
      onClick={() => {
        handleClick(LinkPaymentType.PAYPAL);
        const { name } = EVENTS.OPEN_PAYPAL;
        track(name);
      }}
      isRecommended={isRecommended}
      isDisabled={isDisabled}
    />
  );
};

const renderBancolombiaPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const { DEPOSIT, WITHDRAW, LOGIN_WITH_BCOLO } = getIntlDescriptionCopy(intl);

  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={titles.LINK_BANCOLOMBIA_TITLE}
      icon={<IconBadge icon={<IconBankFilled />} />}
      description={<PaymentMethodDescription details={[DEPOSIT, WITHDRAW, LOGIN_WITH_BCOLO]} />}
      dataTestId={TEST_IDS.ADD_BCOLO_BUTTON}
      tooltipTestId="addBancolombiaTooltip"
      onClick={() => handleClick(LinkPaymentType.BANCOLOMBIA)}
      isRecommended={isRecommended}
      isDisabled={isDisabled}
    />
  );
};

const renderWireTransferPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isRecommended,
  isDisabled,
  isRedirectedFromWithdrawals,
  theme,
  currency,
  countryCode,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const { DEPOSIT, WITHDRAW } = getIntlDescriptionCopy(intl);
  const isFastTransfer = countryCode === "sg" && currency === CURRENCIES_DETAIL.SGD.symbol;
  const isAddPaymentsRevampEnabledForGlobal = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP_GLOBAL
  );
  const renderDescription = () => {
    if (!isRedirectedFromWithdrawals) {
      return <PaymentMethodDescription details={[DEPOSIT, WITHDRAW]} />;
    }

    return (
      <PaymentMethodDescription
        details={[DEPOSIT]}
        renderCustomDescription={
          <Fragment>
            <FlexContainer>
              <IconBadge icon={<IconInfoOutlined size="xs" />} />
              <Text.Body ml={1} size="sm">
                {WITHDRAW}
              </Text.Body>
            </FlexContainer>
            <Text.Body size="xs" mt={0.5}>
              {intl.formatMessage({
                defaultMessage:
                  "A deposit from this bank account is required to verify ownership before you can withdraw to it",
              })}
            </Text.Body>
          </Fragment>
        }
      />
    );
  };

  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={isFastTransfer && isAddPaymentsRevampEnabledForGlobal ? titles.FAST_TRANSFER : titles.LINK_WIRE_TRANSFER}
      icon={<IconBadge icon={<IconSpendingTransfers />} />}
      description={renderDescription()}
      dataTestId={TEST_IDS.ADD_WIRE_BUTTON}
      tooltipTestId="addWireTooltip"
      onClick={() => {
        handleClick(LinkPaymentType.WIRE);
        const { name } = EVENTS.OPEN_PLAID;
        track(name);
      }}
      isRecommended={isRecommended}
      isDisabled={isDisabled}
    />
  );
};

const renderBankFrickPaymentMethod = ({
  canManagePaymentMethods,
  titles,
  intl,
  handleClick,
  isDisabled,
}: RenderAddPaymentMethodsModalPropTypes) => {
  const { DEPOSIT, WITHDRAW } = getIntlDescriptionCopy(intl);
  const isAddPaymentsRevampEnabledForGlobal = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP_GLOBAL
  );

  return (
    <PaymentMethod
      canManagePaymentMethods={canManagePaymentMethods}
      title={isAddPaymentsRevampEnabledForGlobal ? titles.LINK_WIRE_TRANSFER : titles.LINK_BANKFRICK}
      icon={<IconBadge icon={<IconBankFilled />} />}
      description={<PaymentMethodDescription details={[DEPOSIT, WITHDRAW]} />}
      dataTestId={TEST_IDS.ADD_BANKFRICK}
      tooltipTestId="addWireTooltip"
      onClick={() => handleClick(LinkPaymentType.BANKFRICK)}
      isRecommended={true}
      isDisabled={isDisabled}
    />
  );
};

const renderPaymentMethodByType = {
  [LinkPaymentType.PLAID]: renderPlaidPaymentMethod,
  [LinkPaymentType.GIACT]: renderGiactPaymentMethod,
  [LinkPaymentType.PLAID_OR_GIACT]: renderPlaidOrGiactPaymentMethod,
  [LinkPaymentType.MANUAL]: renderManualPaymentMethod,
  [LinkPaymentType.DEBIT]: renderDebitCardPaymentMethod,
  [LinkPaymentType.PAYPAL]: renderPaypalPaymentMethod,
  [LinkPaymentType.BANCOLOMBIA]: renderBancolombiaPaymentMethod,
  [LinkPaymentType.WIRE]: renderWireTransferPaymentMethod,
  [LinkPaymentType.BANKFRICK]: renderBankFrickPaymentMethod,
};

export default renderPaymentMethodByType;
