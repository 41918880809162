import styled from "@emotion/styled";
import MaskedInput from "react-text-mask";
import { text as textTokens } from "@gemini-ui/design-system";

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
`;

export const StyledMaskedInput = styled(MaskedInput)`
  ::placeholder {
    color: ${({ theme }) => theme.colorScheme.content.primary};
  }

  :hover,
  :hover::placeholder {
    color: ${({ theme }) => theme.colorScheme.input.content.placeholder};
  }
`;

export const ScalableInput = styled.input<{ fontSize?: number }>`
  border: none;
  width: 100%;
  text-align: center;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${textTokens.typesets.numeric.display.desktop.md.fontWeight};
  line-height: ${textTokens.typesets.numeric.display.desktop.md.lineHeight};
  letter-spacing: ${textTokens.typesets.numeric.display.desktop.md.letterSpacing};
  font-variant-ligatures: none;

  :focus {
    outline: none;
  }
`;
