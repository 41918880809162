import { CurrentMethodState, TwoFactorViewType } from "@gemini-ui/components/TwoFactorAuth/types";
import { FallbackAuthView } from "@gemini-ui/components/TwoFactorAuth/views/FallbackAuthView";
import { WebAuthnView } from "@gemini-ui/components/TwoFactorAuth/views/WebAuthnView";
import { AuthenticationRequest } from "@gemini-ui/constants/initialData";

type TwoFactorFormProps = {
  allowsFallback: boolean;
  currentMethod: CurrentMethodState;
  onFallback: () => void;
  authRequest: AuthenticationRequest;
  rememberDuration: string;
  email: string;
  onSuccess?: (data: { redirect: string }) => void;
  sudo?: boolean;
  redirect?: string;
  parentType: TwoFactorViewType;
};

export const TwoFactorForm = (props: TwoFactorFormProps) => {
  const {
    authRequest,
    allowsFallback,
    onFallback,
    currentMethod,
    rememberDuration,
    sudo,
    email,
    redirect,
    onSuccess,
    parentType,
  } = props;
  // todo build screen for the list of options if a user has both passkey and security key
  return currentMethod === CurrentMethodState.Authy || currentMethod === CurrentMethodState.TextMessage ? (
    <FallbackAuthView
      type={currentMethod}
      rememberDuration={rememberDuration}
      sudo={sudo}
      email={email}
      redirect={redirect}
      onSuccess={onSuccess}
    />
  ) : (
    <WebAuthnView
      email={email}
      authRequest={authRequest}
      redirect={redirect}
      allowsFallback={allowsFallback}
      onFallback={onFallback}
      type={CurrentMethodState.Passkey} // default to passkey until able to support differentiating pk vs sk
      onSuccess={onSuccess}
      parentType={parentType}
    />
  );
};
