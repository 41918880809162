import { ComponentType, PropsWithChildren, useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import { get } from "lodash";
import { useShallowCompareEffect } from "react-use";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { Modal, ModalProps } from "@gemini-ui/design-system/Modal";
import { AuthyModal } from "@gemini-ui/pages/Authy/AuthyModal";
import axios, { CancelToken, isCancel } from "@gemini-ui/services/axios";
import { useIntl } from "@gemini-ui/utils/intl";

export enum AjaxModalState {
  ERROR,
  DEFAULT,
  LOADING,
}

type Props = {
  isInitialOpen?: boolean;
  alertOnError?: boolean;
  controller: {
    url: string;
    method: string;
  };
  headers?: any;
};

export type FetchModalData = (options: { url: string; method?: string; headers?: object; data?: object }) => void;
type UseAjaxModalReturnProps = {
  jsPathName: string;
  isOpen: boolean;
  modalState: AjaxModalState;
  setAjaxData: (ajaxData: any) => void;
  pageName: PageName;
  pageProps: any;
  closeModal: () => void;
  openModal: () => void;
  fetchModalData: FetchModalData;
  toggleModal: () => void;
  AjaxModal: ComponentType<PropsWithChildren<AjaxModalProps>>;
  ajaxModalProps: AjaxModalReturnProps;
  rawData: any;
};

type AjaxModalReturnProps = {
  needs2fa: boolean;
  on2faSuccess: (data?: any) => void;
  pageProps: any;
  isOpen: boolean;
};

type AjaxModalProps = AjaxModalReturnProps & ModalProps;

const DEFAULT_HEADERS_PROP = {};

const AjaxModal = ({
  needs2fa = false,
  multiStep = false,
  on2faSuccess = () => {},
  pageProps = {},
  ...props
}: AjaxModalProps) => {
  if (needs2fa && props.isOpen) {
    return <AuthyModal close={props.onClose} {...pageProps} {...props} onSuccess={on2faSuccess} />;
  }

  return <Modal {...props} multiStep={multiStep} hasLargeTitle={multiStep} />;
};

const useAjaxModal = ({
  controller,
  isInitialOpen = false,
  alertOnError = true,
  headers = DEFAULT_HEADERS_PROP,
}: Props): UseAjaxModalReturnProps => {
  const [modalState, setModalState] = useState<AjaxModalState>(AjaxModalState.LOADING);
  const [ajaxData, setAjaxData] = useState({});
  const [isOpen, setIsOpen] = useState(isInitialOpen);
  const { showAlert } = useAlert();
  const { intl } = useIntl();

  const fetchModalData = useCallback(
    async ({ url, method = "GET", headers = {}, ...axiosOptions }) => {
      setModalState(AjaxModalState.LOADING);
      try {
        const { data } = await axios({
          url,
          method,
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            ...headers,
          },
          ...axiosOptions,
        });
        setAjaxData(data);
        setModalState(AjaxModalState.DEFAULT);
      } catch (e) {
        if (!isCancel(e)) {
          const { response } = e;
          if (response?.data?.modal) {
            await fetchModalData({ url: response.data.modal });
          } else {
            setModalState(AjaxModalState.ERROR);
            if (alertOnError) {
              showAlert({
                type: AlertTypes.ERROR,
                message:
                  response?.data?.message ||
                  intl.formatMessage({ defaultMessage: "An error occurred. Please try again." }),
              });
            }
            Sentry.captureException(e);
          }
        }
      }
    },
    [alertOnError, showAlert, intl]
  );

  useShallowCompareEffect(() => {
    let source;
    if (isOpen) {
      source = CancelToken.source();
      fetchModalData({
        method: controller.method,
        url: controller.url,
        headers,
        cancelToken: source.token,
      }).then(() => {
        source.cancel("Axios request cancelled");
      });
    }
    return () => {
      if (source) source.cancel("Axios request cancelled");
    };
  }, [isOpen, controller.url, controller.method, fetchModalData, headers]);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  const toggleModal = () => setIsOpen(prevState => !prevState);
  const pageName = get(ajaxData, "pageName", "");
  const pageProps = get(ajaxData, "pageProps", {});
  const jsPathName = get(ajaxData, "jsPath", [])[0];
  const ajaxModalProps = {
    isOpen,
    loading: modalState === AjaxModalState.LOADING,
    needs2fa: pageName === PageName.Authy,
    pageProps: pageProps,
    on2faSuccess: data => {
      fetchModalData({ url: data.redirect, headers });
    },
  };

  return {
    isOpen,
    modalState,
    setAjaxData,
    pageName,
    pageProps,
    closeModal,
    openModal,
    fetchModalData,
    toggleModal,
    ajaxModalProps,
    AjaxModal,
    jsPathName,
    rawData: ajaxData,
  };
};

export default useAjaxModal;
