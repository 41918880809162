const browse = {
  browseCryptosCard: "browse-cryptos-card",
  viewMoreButton: "browse-view-more-button",
  assetRow: "browse-asset-row",
  assetRowPrice: "browse-asset-row-price",
  assetRowPriceDelta: "browse-asset-row-price-delta",
  assetRowPercentDelta: "browse-asset-row-percent-delta",
  goBack: "browse-go-back",
};

const createAlert = {
  openModalButton: "create-alert-open-modal-button",
  changeTypeDropdown: "create-alert-change-type-dropdown",
  priceInput: "create-alert-price-input",
  cancelButton: "create-alert-cancel-button",
  submitButton: "create-alert-submit-button",
};

const paymentSelectorModal = {
  dismissModal: "dismiss-modal-x",
};

const openLimitOrders = {
  limitOrdersCard: "open-limit-orders-card",
  limitOrdersViewMoreBtn: "open-limit-orders-view-more-btn",
  errorSectionMessage: "error-section-message",
};

const buySell = {
  activeTraderCta: "active-trader-cta",
  amountInput: "buysell-amount-input",
  amountInputMessage: "buysell-amount-input-message",
  buySelectedAsset: "buy-selected-asset",
  sellSelectedAsset: "sell-selected-asset",
  convertFromSelectedAsset: "convertFrom-selected-asset",
  convertToSelectedAsset: "convertTo-selected-asset",
  availableWithdrawalTooltip: "available-withdrawal-tooltip",
  biweeklyDropdown: "buysell-biweekly-dropdown",
  convertFromInput: "convert-from-input",
  convertFromSummary: "convert-from-summary",
  convertToSummary: "convert-to-summary",
  convertReceivingSummary: "convert-receiving-summary",
  convertExchangeRateSummary: "convert-exchange-rate-summary",
  convertToInput: "convert-to-input",
  convertSummaryInfoTooltip: "convert-summary-info-tooltip",
  dayOfWeekDropdown: "buysell-day-of-week-dropdown",
  earnStakingToggle: "buysell-asset-earn-staking-toggle",
  earnToggleContainer: "buysell-asset-earn-toggle-container",
  earnToggleCta: "buysell-asset-earn-toggle-cta",
  earnToggleLearnMoreButton: "buysell-asset-earn-toggle-learn-more-btn",
  earnToggleTooltip: "buysell-asset-earn-toggle-tooltip",
  errorMessage: "error-message",
  errorMessageBtn: "error-message-btn",
  currencySwitcherIconBtn: "buysell-currency-switcher-icon-btn",
  limitOrderDetailsModalCancelBtn: "buysell-limit-order-details-modal-cancel-btn",
  limitOrderDetailsModalDoneBtn: "buysell-limit-order-details-modal-done-btn",
  limitOrderDetailsOrderStatus: "buysell-limit-order-details-order-status",
  limitOrderFilledAmount: "buysell-limit-order-details-filled-amount",
  limitOrderInfoModalLearnMoreBtn: "buysell-limit-order-info-modal-learn-more-btn",
  limitOrderInfoModalDoneBtn: "buysell-limit-order-info-modal-done-btn",
  limitOrderReviewConfirmBtn: "buysell-limit-order-review-confirm-btn",
  limitOrderSuccessMessage: "buysell-limit-order-success-message",
  limitPriceInput: "buysell-limit-price-input",
  limitPriceInfoIconBtn: "buysell-limit-price-info-icon-btn",
  limitPriceMessageText: "buysell-limit-price-message-text",
  monthlyDropdown: "buysell-monthly-dropdown",
  orderSelectorBuy: "buysell-order-selector-buy-radio",
  orderSelectorConvert: "buysell-order-selector-convert-radio",
  orderSelectorSell: "buysell-order-selector-sell-radio",
  orderTypeBtn: "buysell-order-type-btn",
  orderTypeInfo: "buysell-order-type-info",
  orderTypeWarning: "buysell-order-type-warning",
  orderTypeLimitCardBtn: "buysell-order-type-limit-card-btn",
  otherOrderTypesBtn: "butysell-otherOrderTypes-button",
  placeOrderBtn: "buysell-place-order-btn",
  portfolioLink: "buysell-portfolio-link",
  promoInfoTooltip: "promo-info-tooltip",
  quickBuyTooltip: "quick-buy-tooltip",
  reviewEditButton: "buysell-review-edit-btn",
  reviewFrequency: "buysell-review-frequency",
  reviewOrderButton: "buysell-review-order-btn",
  reviewSection: "buysell-review-section",
  reviewOrderType: "buysell-review-order-type",
  reviewOrderMarketPrice: "buysell-review-order-market-price",
  scheduleDropdown: "buysell-schedule-dropdown",
  successModalButton: "buysell-success-modal-button",
  failureModalButtonPrimary: "buysell-failure-modal-primary",
  failureModalButtonTertiary: "buysell-failure-modal-tertiary",
  v2Container: "buy-sell-convert-v2-container",
  paymentMethodBtn: "order-review-select-payment-method-btn",
  orderAmountSummarySection: "order-amount-summary-section",
  orderAmountSummary: "order-amount-summary",
  orderSellFiatAmount: "order-sell-fiat-amount",
  orderSellAmount: "order-sell-amount",
  orderSellTransactionFee: "order-sell-transaction-fee",
  orderSellTransactionFeeBadge: "order-sell-transaction-fee-badge",
  orderReward: "order-reward",
  orderSellProceeds: "order-sell-proceeds",
  orderSuccessDoneBtn: "order-success-done-btn",
  orderSuccessDetailsBtn: "order-success-details-btn",
  orderToCurrencyAmount: "order-to-currency-amount",
  orderTransactionFeeSummarySection: "order-transaction-fee-summary-section",
  orderRewardsSection: "order-rewards-section",
  orderTransactionFeeAmountSummary: "order-transaction-fee-amount-summary",
  orderTotalSummarySection: "order-total-summary-section",
  orderTotalSummery: "order-total-summary",
  geminiBalanceBtn: "select-gemini-balance-method-btn",
  reviewLimitOrderFee: "review-limit-order-fee",
  limitOrderFee: "limit-order-fee",
  successModalHeader: "success-modal-header",
  successModalDetails: "success-modal-details",
  successModalRecurringMessageDetails: "success-modal-recurring-message-details",
  debitCardError: "debit-card-error",
  debitCardRecurringOrderDisabledError: "debit-card-recurring-order-disabled-error",
  earnBalance: "earn-balance",
  stakingBalance: "staking-balance",
  annualYield: "annual-yield",
  helpCenterLink: "order-help-center-link",
  reviewOrderErrorMessage: "review-order-error-message",
  tradeMaintenanceState: "buysell-trade-maintenance-state",
};

const about = {
  avgPriceTooltip: "about-average-price-tooltip",
  portfolioPercentageValueText: "portfolio-percentage-value-text",
  readMore: "about-readmore",
};

const portfolio = {
  accountSwitcherCurrentBadge: "account-switcher-current-badge",
  accountSwitcherScrollContainer: "account-switcher-scroll-container",
  accountSwitcherSearchInput: "account-switcher-search-input",
  backButton: "portfolio-back-button",
  balancesTable: "retail-portfolio-asset-rows",
  positionsTable: "derivatives-positions-table",
  depositQuickLink: "deposit-quick-link",
  holdsInfoModalAvailTradingText: "holds-info-modal-avail-trading-text",
  holdsInfoModalDoneBtn: "holds-info-modal-done-btn",
  holdsInfoModalOpenOrdersText: "holds-info-modal-open-orders-text",
  priceAlertsQuickLink: "price-alerts-quick-link",
  quickActionsCard: "quick-actions-card",
  recurringBuysQuickLink: "recurring-buys-quick-link",
  stakingCard: "staking-card",
  stakingEmptyStateCard: "staking-empty-state-card",
  stakingTable: "retail-portfolio-stake-asset-rows",
  transactionHistoryQuickLink: "transaction-history-quick-link",
  withdrawQuickLink: "withdraw-quick-link",
  positionsSymbol: "positions-symbol",
  positionsSymbolMobile: "positions-symbol-mobile",
  positionsUnrealizedPl: "positions-unrealized-pl",
  positionsSizeValue: "positions-size-value",
  positionsSizeNotionalValue: "positions-size-notional-value",
  portfolioChart: "portfolio-chart",
};

const rateDetails = {
  list: "rate-details-list",
  listItem: "rate-details-list-item",
  maxThreshold: "rate-details-max-threshold",
  rangeDescription: "rate-details-range-description",
  yield: "rate-details-yield",
};

const giftingCrypto = {
  amountInput: "gift-amount-input",
  recipientNameInput: "recipient-name-input",
  recipientEmailInput: "recipient-email-input",
  giftMessageInput: "gift-message-input",
  continueButton: "continue-button",
  confirmButton: "confirm-button",
  suggestedAmountButton: "suggested-amount-button",
  doneButton: "done-button",
  giveAnotherGiftButton: "give-another-gift-button",
  redemptionCodeInput: "gift-redemption-code-input",
  redeemButton: "redeem-button",
  giftRedeemAnimation: "gift-redeem-animation",
};

const homepage = {
  referralCodeInput: "referral-code-input",
  copiedReferralLink: "copied-referral-link",
  portfolioCard: "portfolio-card",
  portfolioCardCta: "portfolio-card-cta",
  portfolioCardStartTradingCta: "homepage-portfolio-start-trading-button",
  portfolioCardStartEarningCta: "homepage-start-earning-button",
  portfolioCardEmpty: "portfolio-card-empty",
  portfolioCardStartStakingCta: "homepage-start-staking-button",
  portfolioCardStakingBalance: "portfolio-card-staking-balance",
  homepageSidebar: "homepage-sidebar",
  sidebarCarouselContainer: "sidebar-carousel-container",
  sidebarCarouselNavIndicators: "sidebar-carousel-nav-indicators",
  sidebarCarouselCtaButton: "sidebar-carousel-cta-button",
  sidebarCarouselMoveLeftButton: "sidebar-carousel-move-left-button",
  sidebarCarouselMoveRightButton: "sidebar-carousel-move-right-button",
  resourcesPageLeft: "resources-page-left",
  resourcesPageRight: "resources-page-right",
  assetTile: "retail-homepage-asset-tile",
  assetTileIcon: "retail-homepage-asset-icon",
  assetTileSymbol: "retail-homepage-asset-symbol",
  assetTilePrice: "retail-homepage-asset-price",
  assetTilePercent: "retail-homepage-asset-percent",
  topMoversPageLeft: "topmovers-page-left",
  topMoversPageRight: "topmovers-page-right",
  topMover: "retail-homepage-top-mover",
  perpsCard: "perps-card",
  perpsCardCta: "perps-card-cta",
  leaderboardCard: "leaderboard-card",
  leaderboardCardCta: "leaderboard-card-cta",
  crossCollateralCard: "cross-collateral-card",
  crossCollateralCardAccountCta: "cross-collateral-card-create-account-button",
  crossCollateralCardSettingsCta: "cross-collateral-card-open-settings-button",
};

const chart = {
  responsiveChart: "responsive-chart",
  emptyChart: "empty-chart",
};

const settings = {
  whitelistAddAddressAttestationApprove: "submit-attestation-button",
  whitelistAddAddressAttestationDecline: "decline-attestation-button",
  whitelistAddAddressCancelButton: "cancel-add-approved-addresses",
  whitelistAddAddressChainNetworkSelect: "add-approved-address-chain-network-select",
  whitelistAddAddressConfirmAddressInput: "confirm-address-input",
  whitelistAddAddressConfirmCancelButton: "cancel-confirm-approved-addresses",
  whitelistAddAddressConfirmContinueButton: "confirm-approved-addresses",
  whitelistAddAddressConfirmMemoInput: "confirm-memo-input",
  whitelistAddAddressContinueButton: "continue-add-approved-addresses",
  whitelistAddAddressInput: "address-input",
  whitelistAddAddressLabelInput: "address-label-input",
  whitelistAddAddressMemoInput: "memo-input",
  whitelistAddAddressScopeSelect: "select-scope-approved-addresses-dropdown",
  whitelistAddAddressScopeSelectCancelBtn: "cancel-select-scope-add-approved-addresses",
  whitelistAddAddressScopeSelectContinueBtn: "continue-select-scope-add-approved-addresses",
  whitelistAddAddressSuccessCloseButton: "close-add-address-success",
  whitelistAddAddressSuccessProofOfControlSection: "success-proof-of-control-section",
  changeLanguageSelect: "change-language-selection",
  dropdownLanguages: "dropdown-languages",
  cancelChangeLanguage: "cancel-change-language",
  applyChangeLanguage: "apply-change-language",
  clientIdCopyBtn: "copy-clientId",
  clientSecretCopyBtn: "copy-secret",
  checkboxBanksRead: "checkbox-banks:read",
  checkboxBanksCreate: "checkbox-banks:create",
  oAuthFinishBtn: "oauth-finish-button",
  oAuthCreateEditForm: "oauth-create-edit-form",
  oAuthAppName: "oauth-application-name",
  oAuthAppUrl: "oauth-application-url",
  oAuthAppDescription: "oauth-application-description",
  oAuthAppRedirectUrl: "oauth-application-redirect-url",
  oAuthCancelBtn: "oauth-cancel-button",
  oAuthSubmitBtn: "oauth-submit-application",
  oAuthCreateAppLink: "oauth-create-app-link",
  oAuthLockoutCreateAppBtn: "lockout-create-oauth2-btn",
  apiKeyCreateButton: "open-create-api-key-modal",
  apiKeyEditNameInput: "edit-api-key-name-input",
  apiKeyEditDismissBtn: "dismiss-edit-api-key-modal",
  apiKeyEditSaveBtn: "save-edit-api-key-modal",
  apiKeyLockoutCreateButton: "lockout-create-api-key-btn",
  apiKeyScopeSelect: "select-api-key-for-account",
  apiKeyScopeSelectCancelBtn: "cancel-select-api-key-account",
  apiKeyScopeSelectSubmitBtn: "submit-select-api-key-account",
  apiKeyNameInput: "api-key-name-input",
  apiKeyReadOnlyInput: "api-key-readonly-input",
  apiSecretReadOnlyInput: "api-secret-readonly-input",
  apiKeyModalCancelBtn: "cancel-confirm-api-key-modal",
  apiKeyModalSubmitBtn: "submit-confirm-api-key-modal",
  recommendedBadge: "recommended-badge",
  connectApplicationButton: "connect-application-button",
};

const transferControl = {
  confirmSenderWalletOwner: "confirm-sender-wallet-owner",
  confirmSenderIndividualOwner: "confirm-sender-individual-owner",
  confirmSenderEntityOwnder: "confirm-sender-entity-owner",
  confirmSenderOwnerCountry: "confirm-sender-owner-country",
  confirmSenderName: "confirm-sender-name",
  confirmSenderFirstName: "confirm-sender-first-name",
  confirmSenderLastName: "confirm-sender-last-name",
  confirmSenderAddressLine1: "confirm-sender-address-line-1",
  confirmSenderAddressLine2: "confirm-sender-address-line-2",
  confirmSenderCity: "confirm-sender-city",
  confirmSenderPostalCode: "confirm-sender-postal-code",
  submissionError: "submission-error",
  selectSenderMyselfOption: "select-sender-myself-option",
  selectSenderSomeoneElseOption: "select-sender-someone-else-option",
  selectSenderNotRecognizedOption: "select-sender-not-recognized-option",
  confirmMessageFromSender: "confirm-message-from-sender",
  confirmMessageOwnerName: "confirm-message-owner-name",
  confirmMessageOwnerCountry: "confirm-message-owner-country",
  confirmMessageOwnerAddressLine1: "confirm-message-owner-address-line-1",
  confirmMessageOwnerAddressLine2: "confirm-message-owner-address-line-2",
  confirmMessageOwnerAddressLine3: "confirm-message-owner-address-line-3",
  confirmMessageDate: "confirm-message-date",
  viewTransfersCta: "view-transfers-cta",
  doneCta: "done-cta",
  cancelCta: "cancel-cta",
  confirmCta: "confirm-cta",
  continueCta: "continue-cta",
  contactSupportCta: "contact-support-cta",
  attestationWarning: "attestation-warning",
  attestationAlert: "attestation-alert",
  primaryCTA: "primary-cta",
  secondaryCTA: "secondary-cta",
  activeTraderCta: "active-trader-cta",
  activeTraderAttestationLearnMoreCta: "active-trader-attestation-learn-more-cta",
  attestationTitle: "attestation-title",
  attestationDescription: "attestation-description",
  selectSenderTitle: "select-senderTitle",
  completeMessage: "complete-message",
  unknownSenderWarningContainer: "unknown-sender-warning-container",
  complyAdvantageMessage: "complyAdvantageMessage",
};

const networkFeatureCarousel = {
  carousel: "carousel-container",
  carouselItem: "carousel-item",
};

const tradeModule = {
  errorSection: "trade-module-error-section",
  loadingContainer: "trade-module-loading-container",
};

const tradePage = {
  tradePageSidebar: "trade-page-side-bar",
};

export const testIds = {
  browse,
  createAlert,
  buySell,
  about,
  portfolio,
  giftingCrypto,
  rateDetails,
  openLimitOrders,
  paymentSelectorModal,
  homepage,
  chart,
  settings,
  transferControl,
  networkFeatureCarousel,
  tradeModule,
  tradePage,
};
