import { useTrackLinks } from "@gemini-ui/analytics";
import { HEADER_EVENTS, LinkName } from "@gemini-ui/components/Header/HeaderMenuLink/constants";
import { HeaderNavButtonWrap } from "@gemini-ui/components/Header/HeaderMenuLink/styles";
import { HubbleButton } from "@gemini-ui/design-system";

interface Props {
  name: string;
  href?: string;
  onClick?: () => void;
  active?: boolean;
  hideMobile?: boolean;
  linkName?: LinkName;
}

const HeaderMenuLink = ({ active = false, name, href, hideMobile, linkName, onClick }: Props) => {
  const id = `header-menu-link-${linkName}`;
  const eventName = HEADER_EVENTS[linkName];

  useTrackLinks({
    selector: `#${id}`,
    eventName,
    properties: {
      origin: window.location.pathname,
    },
  });

  return (
    <HeaderNavButtonWrap alignItems="center" hideMobile={hideMobile}>
      {active ? (
        <HubbleButton.Secondary id={id} data-testid={id} size="sm" cta={name} href={href} onClick={onClick} mr={0.5} />
      ) : (
        <HubbleButton.Tertiary id={id} data-testid={id} size="sm" cta={name} href={href} onClick={onClick} mr={0.5} />
      )}
    </HeaderNavButtonWrap>
  );
};

export default HeaderMenuLink;
