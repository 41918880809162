import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";
import axios, { AxiosRequestConfig } from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

export enum RecurringOrderExecutionType {
  InstantOrder = "InstantOrder",
}

type PayPalData = {
  deviceData: string;
};

export type RecurringOrderRouteQueryParams = {
  startOn: string;
  notional: string | number;
  tradingPair: string;
  schedule: string;
  executionType: RecurringOrderExecutionType;
  triggerHour: string;
  bankAccountId?: string;
  cardAccountType?: string;
  paymentMethodUuid?: string;
  paymentMethodType?: string;
  paymentToken?: string;
  payPalData?: PayPalData;
  appleIfv?: string;
  googleAid?: string;
  earnProviderId?: string;
};

export type RecurringOrderResult = {
  success: boolean;
  recurringOrder: number;
};

export const submitRecurringOrderData = async (
  args: RecurringOrderRouteQueryParams,
  config?: AxiosRequestConfig
): Promise<RecurringOrderResult> => {
  const url = jsRoutes.com.gemini.web.server.trading.controllers.RecurringOrdersController.add().url;
  return await axios
    .post(url, args, config)
    .then(res => res.data)
    .catch(e => {
      Sentry.captureException(e);
      throw e;
    });
};

export const useRecurringOrder = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [data, setData] = useState<RecurringOrderResult | null>(null);

  const submitRecurringOrder = useCallback(
    async (args: RecurringOrderRouteQueryParams, config?: AxiosRequestConfig) => {
      try {
        setLoading(true);
        const data = await submitRecurringOrderData(args, config);
        setData(data);
        setError(null);

        // Returning the data and setting the data to local state is redundant but may be helpful depending on how this function is called.
        return data;
      } catch (e) {
        const error = getError(e);
        setError(error);
        return error;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    data,
    submitRecurringOrder,
    loading,
    error,
  };
};
