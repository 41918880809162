import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Colors, Spacing } from "@gemini-ui/design-system";

export const ItemLink = styled("a")<{ open?: boolean; big?: boolean; active?: boolean }>`
  display: block;
  align-items: baseline;
  flex-direction: column;
  transition: color 0.4s cubic-bezier(0.05, 0.74, 0.27, 0.99);
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.white};
  padding: ${Spacing.scale[2]} ${Spacing.scale[4]} ${Spacing.scale[2]} 0;

  ${p =>
    p.big &&
    css`
      font-size: 20px;
      padding-top: ${Spacing.scale[2]};
      padding-bottom: ${Spacing.scale[2]};
    `}

  letter-spacing: 0;

  svg:first-of-type {
    margin-right: ${Spacing.scale[1]};
  }

  svg:last-of-type {
    float: right;
    width: 20px;
    transform: ${p => (p.open ? "rotate(-180deg)" : "rotate(0deg)")};
  }

  span {
    top: -3px;
    position: relative;
    font-size: 14px;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${Colors.white};
  }

  ${p =>
    p.active &&
    css`
      &:before {
        content: "";
        width: 4px;
        background: ${Colors.brandCyan};
        margin-top: ${p.big ? "-12px" : 0};
        left: ${p.big ? Spacing.scale[2] : `-${Spacing.scale[1.5]}`};
        height: ${p.big ? "40px" : "32px"};
        position: ${p.big ? "absolute" : "relative"};
      }
    `}
`;

export const StyledListItem = styled("li")<{ active?: boolean; border?: boolean; firstBorder?: boolean }>`
  margin-left: 3em;
  display: block;
  border-bottom: ${p => (p.border ? `1px solid ${Colors.gray[600]}` : "none")};
  list-style: none;

  ${p =>
    p.firstBorder &&
    css`
      border-top: 1px solid ${Colors.gray[600]};
      margin-top: ${Spacing.scale[2]};
    `}
`;
