import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { optimizelyClient } from "@gemini-ui/analytics";
import { MoneyProps } from "@gemini-ui/components/Money";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import {
  TRANSFER_MECHANISM,
  TRANSFER_MECHANISMS_WITH_FEES,
  TransferMechanism,
} from "@gemini-ui/pages/transfers/constants";
import { getMechanismName } from "@gemini-ui/pages/transfers/utils/getMechanismName";
import axios, { AxiosResponse } from "@gemini-ui/services/axios";

interface Props {
  children: ReactNode;
  defaultFees?: WithdrawFiatFees;
  defaultCurrency?: string;
}

interface State {
  fees: WithdrawFiatFees;
  loading: boolean;
  setTransferMechanisms: Dispatch<SetStateAction<TransferMechanism[]>>;
  setCurrency: Dispatch<SetStateAction<string>>;
}

export type WithdrawFiatFeeResponse = {
  flatFee?: MoneyProps;
  percentageFee?: number;
};

type FeeRequests = Partial<Record<TransferMechanism, Promise<AxiosResponse<WithdrawFiatFeeResponse>>>>;
export type WithdrawFiatFees = Partial<Record<TransferMechanism, WithdrawFiatFeeResponse>>;

const WithdrawFiatFeesContext = createContext<State | undefined>(undefined);
const featureFlagGuardedMechanisms = {
  [TRANSFER_MECHANISM.wire]: optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_WIRE_WITHDRAW_FEES),
  [TRANSFER_MECHANISM.xfers]: true,
};

function WithdrawFiatFeesProvider({ children, defaultFees = {}, defaultCurrency = "" }: Props) {
  const [fees, setFees] = useState<WithdrawFiatFees>(defaultFees);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(defaultCurrency);
  const [transferMechanisms, setTransferMechanisms] = useState<TransferMechanism[]>([]);
  const previousTransferMechanisms = usePrevious(transferMechanisms);

  useEffect(() => {
    const fetchFees = async () => {
      const promises = transferMechanisms.reduce<FeeRequests>((requests, mechanism) => {
        const hasFee = TRANSFER_MECHANISMS_WITH_FEES.includes(mechanism);
        if (featureFlagGuardedMechanisms[mechanism] && hasFee) {
          const url = `/transfer/fees/withdraw/${getMechanismName(mechanism)}/rate?currency=${currency}`;
          return {
            ...requests,
            [mechanism]: axios.get(url),
          };
        }
        return requests;
      }, {});

      const requests = Object.values(promises);

      if (requests.length) {
        setLoading(true);
        const responses = await Promise.allSettled(Object.values(promises));
        setLoading(false);

        setFees(
          Object.keys(promises).reduce((accumulator, mechanism, index) => {
            const data = (responses[index] as PromiseFulfilledResult<AxiosResponse<WithdrawFiatFeeResponse>>)?.value
              ?.data;
            if (data) {
              return {
                ...accumulator,
                [mechanism]: data || null,
              };
            }
            return accumulator;
          }, {})
        );
      }
    };

    if (transferMechanisms.length && transferMechanisms !== previousTransferMechanisms) {
      fetchFees();
    }
  }, [previousTransferMechanisms, transferMechanisms, currency]);

  const value = {
    fees,
    loading,
    setTransferMechanisms,
    setCurrency,
    transferMechanisms,
  };

  return <WithdrawFiatFeesContext.Provider value={value}>{children}</WithdrawFiatFeesContext.Provider>;
}

const useWithdrawFiatFees = () => {
  const context = useContext(WithdrawFiatFeesContext);

  if (context === undefined) {
    throw new Error("useWithdrawFiatFees must be used within a WithdrawFiatFeesProvider");
  }

  return context;
};

export { useWithdrawFiatFees, WithdrawFiatFeesProvider };
