import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { IconSettingsOutlined } from "@hubble/icons";
import { useScroll } from "react-use";
import {
  ElementRefType,
  getComponentCopy,
  NotificationPanelState,
  NotificationRequest,
  NotificationResponse,
} from "@gemini-ui/components/Header/navigation/NotificationCenter/constants";
import {
  CenteredText,
  FlexButton,
  Panel,
  ScrollContainer,
} from "@gemini-ui/components/Header/navigation/NotificationCenter/NotificationCenterPanel/styles";
import { NotificationList } from "@gemini-ui/components/Header/navigation/NotificationCenter/NotificationList";
import { Button, Flex, Text } from "@gemini-ui/design-system";
import BellDark from "@gemini-ui/images/NotificationCenter/bell-dark.svg";
import BellLight from "@gemini-ui/images/NotificationCenter/bell-light.svg";
import { areCustomLayoutsEnabled } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/utils";
import { useIntl } from "@gemini-ui/utils/intl";

const OFFSET = 95;
const SCROLL_BUFFER = 500;

interface NotificationCenterPanelProps {
  fetchNotifications: (arg: NotificationRequest) => void;
  notificationState: NotificationResponse;
  panelState: NotificationPanelState;
  scrollRef: ElementRefType;
}

export function NotificationCenterPanel({
  fetchNotifications,
  notificationState,
  panelState,
  scrollRef,
}: NotificationCenterPanelProps) {
  const { y } = useScroll(scrollRef);
  const { intl } = useIntl();
  const { continuationToken } = notificationState;
  const { listItems } = panelState;

  const [currentPosition, setCurrentPosition] = useState(OFFSET);

  const copy = getComponentCopy(intl);
  const theme = useTheme();
  const nullStateImgSrc = theme.isDark ? BellDark : BellLight;
  const customLayoutsEnabled = areCustomLayoutsEnabled();

  /**
   * Track vertical scrolling within the panel in order to fetch more notifications; the call
   * is made approximately just before the user has already seen all within the first given
   * limit of notifications (defaulted to 10)
   */
  useEffect(() => {
    if (continuationToken && y > currentPosition) {
      setCurrentPosition(y + SCROLL_BUFFER);
      fetchNotifications({ continuationToken });
    }
  }, [continuationToken, currentPosition, fetchNotifications, y]);

  return (
    <Panel customLayoutsEnabled={customLayoutsEnabled}>
      <ScrollContainer data-testid="notification-center-scroll-container" ref={scrollRef}>
        <Flex align="center" justify="space-between" pb={2} pl={2} pr={2}>
          <Text.Heading size="md">{copy.HEADING}</Text.Heading>
          <FlexButton
            aria-label={copy.SETTINGS_LABEL}
            data-testid="notification-center-settings-button"
            display="inline"
            href="/settings/notifications"
            icon={<IconSettingsOutlined />}
            size="sm"
          />
        </Flex>

        {listItems.length > 0 ? (
          <NotificationList notifications={listItems} />
        ) : (
          <Flex align="center" flexDirection="column">
            <img alt={copy.NULL_IMAGE_ALT} src={nullStateImgSrc} width="265px" />
            <CenteredText size="sm" mt={1}>
              {copy.NULL_TEXT}
            </CenteredText>
            <Flex>
              <Button.Secondary
                cta={copy.NULL_CTA}
                data-testid="notification-center-manage-settings-button"
                href="/settings/notifications"
                mt={2}
                mb={2}
                size="sm"
              />
            </Flex>
          </Flex>
        )}
      </ScrollContainer>
    </Panel>
  );
}
