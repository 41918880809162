import { Dispatch, SetStateAction, useState } from "react";
import { IconClose, IconMenu } from "@hubble/icons";
import { CountryAbbreviation } from "@gemini-common/scripts/constants/Countries";
import { MobileMenuContent } from "@gemini-ui/components/Header/navigation/MobileMenu/components/MobileMenuContent";
import { StyledMenuButton, StyledMenuContent } from "@gemini-ui/components/Header/navigation/MobileMenu/styles";
import { GroupsInfo } from "@gemini-ui/constants/templateProps/users";
import { usePageData } from "@gemini-ui/contexts";
import { HubbleMenu } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

// Types
interface Props {
  userName: string;
  institutionName: string;
  advancedTradeUIEnabled: boolean;
  showClearingLink: boolean;
  isCustodyAccount: boolean;
  moreThanOneAccount: boolean;
  groupsInfo: GroupsInfo;
  countryCode: CountryAbbreviation;
  isInstitutional: boolean;
  setMobileMenuActive: Dispatch<SetStateAction<boolean>>;
}

const MobileMenu = ({
  userName,
  institutionName,
  advancedTradeUIEnabled,
  showClearingLink,
  isCustodyAccount,
  moreThanOneAccount,
  groupsInfo,
  countryCode,
  isInstitutional,
  setMobileMenuActive,
}: Props) => {
  const { intl } = useIntl();
  const {
    pageName,
    templateProps: {
      account: { defaultFiat, supportedFiat },
      user: { subaccounts },
    },
  } = usePageData();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isPerpsTransferEligible = subaccounts.some(account => account.derivatives);

  const handleMenuChange = () => {
    setMobileMenuActive(d => !d);
    setIsMenuOpen(d => !d);
  };

  return (
    <HubbleMenu onOpenChange={handleMenuChange} modal={false}>
      <HubbleMenu.Trigger>
        <StyledMenuButton
          data-testid="mobile-menu-button"
          aria-label={intl.formatMessage({ defaultMessage: "mobile-menu-button" })}
          size="sm"
          icon={isMenuOpen ? <IconClose /> : <IconMenu />}
        />
      </HubbleMenu.Trigger>
      <StyledMenuContent align="end" width="100vw" height="100vh">
        <MobileMenuContent
          advancedTradeUIEnabled={advancedTradeUIEnabled}
          defaultFiat={defaultFiat}
          groupsInfo={groupsInfo}
          institutionName={institutionName}
          isCustodyAccount={isCustodyAccount}
          isInstitutional={isInstitutional}
          isPerpsTransferEligible={isPerpsTransferEligible}
          moreThanOneAccount={moreThanOneAccount}
          pageName={pageName}
          showCardLink={countryCode === "us" && !isInstitutional}
          showClearingLink={showClearingLink}
          supportedFiat={supportedFiat}
          userName={userName}
        />
      </StyledMenuContent>
    </HubbleMenu>
  );
};

export default MobileMenu;
