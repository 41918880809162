import { Fragment, ReactNode } from "react";
import { useTheme } from "@emotion/react";
import { CopyListItemToClipboard } from "@gemini-ui/components/CopyListItemToClipboard/CopyListItemToClipboard";
import { CbitTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { HubbleCard, HubbleListItem, List, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/transfers/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface CbitTassatDepositDetailsProps {
  firmName: string;
  walletName: string;
  walletAddress: string;
  publicDescription: string;
}

const CbitTassatDepositDetails = ({
  firmName,
  walletName,
  walletAddress,
  publicDescription,
}: CbitTassatDepositDetailsProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <Fragment>
      <Text.Body data-testid={testIds.deposit.cbtDepositDetailsText} size="md">
        {intl.formatMessage({
          defaultMessage:
            "Issue a transfer from your CBIT account to Gemini. You will first need to add Gemini’s wallet address as a recipient. If you need help, contact TassatPay customer support.",
        })}
      </Text.Body>

      <HubbleCard variant="filled" mt={2} density="md">
        <Text.Heading size="xs" mt={2} mb={2}>
          {intl.formatMessage({
            defaultMessage: "Recipient information",
          })}
        </Text.Heading>
        <List>
          <HubbleListItem
            density="lg"
            left={<Text.Body>{intl.formatMessage({ defaultMessage: "Firm name" })}</Text.Body>}
            right={<Text.Body bold>{firmName}</Text.Body>}
          ></HubbleListItem>
          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Wallet name" })}
            data={walletName}
            textToCopy={walletName}
          ></CopyListItemToClipboard>
          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Wallet address" })}
            data={walletAddress}
            textToCopy={walletAddress}
          ></CopyListItemToClipboard>
        </List>
      </HubbleCard>

      {Boolean(publicDescription) && (
        <HubbleCard data-testid={testIds.deposit.cbtDepositDetailsRefCodeCard} variant="filled" mt={2} density="md">
          <Text.Heading size="xs" mt={2} mb={2}>
            {intl.formatMessage({
              defaultMessage: "Reference code",
            })}
          </Text.Heading>
          <List>
            <CopyListItemToClipboard
              label={intl.formatMessage({ defaultMessage: "Public description" })}
              data={publicDescription}
              textToCopy={publicDescription}
            ></CopyListItemToClipboard>
          </List>
          <Text.Body color={colorScheme.content.secondary} mb={2} size="xs">
            {intl.formatMessage({
              defaultMessage:
                "Include your account reference code in the memo, instruction, or description field. This code is used to match deposits to your Gemini account. Without it, deposits may be delayed or returned.",
            })}
          </Text.Body>
        </HubbleCard>
      )}
    </Fragment>
  );
};

interface CbitCubixDepositDetailsProps {
  firmName: string;
  accountNumberLastFour: string;
  publicMemo: string;
}

const CbitCubixDepositDetails = ({ firmName, accountNumberLastFour, publicMemo }: CbitCubixDepositDetailsProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  return (
    <Fragment>
      <Text.Body data-testid={testIds.deposit.cbtDepositDetailsText} size="md">
        {intl.formatMessage(
          defineMessage({
            defaultMessage:
              "Before making a transfer, go to your Cubix address book and send an outgoing link request to <b>{firmName} ending in {lastFour}</b>. If you need help, please contact Customers Bank support.",
          }),
          {
            firmName: firmName,
            lastFour: accountNumberLastFour,
            b: (v: ReactNode) => <b>{v}</b>,
          }
        )}
      </Text.Body>
      <br />
      <Text.Body data-testid={testIds.deposit.cbtDepositDetailsText2} size="md">
        {intl.formatMessage({
          defaultMessage: "Once accounts are linked, issue a transfer using the instructions below.",
        })}
      </Text.Body>

      <HubbleCard variant="filled" mt={2} density="md">
        <Text.Heading size="xs" mt={2} mb={2}>
          {intl.formatMessage({
            defaultMessage: "Recipient information",
          })}
        </Text.Heading>
        <List>
          <HubbleListItem
            density="lg"
            left={<Text.Body>{intl.formatMessage({ defaultMessage: "Firm name" })}</Text.Body>}
            right={<Text.Body bold>{firmName}</Text.Body>}
          ></HubbleListItem>
          <HubbleListItem
            density="lg"
            left={<Text.Body>{intl.formatMessage({ defaultMessage: "Account number" })}</Text.Body>}
            right={<Text.Body bold>•••• {accountNumberLastFour}</Text.Body>}
          ></HubbleListItem>
        </List>
      </HubbleCard>

      <HubbleCard data-testid={testIds.deposit.cbtDepositDetailsRefCodeCard} variant="filled" mt={2} density="md">
        <Text.Heading size="xs" mt={2} mb={2}>
          {intl.formatMessage({
            defaultMessage: "Reference code",
          })}
        </Text.Heading>
        <List>
          <CopyListItemToClipboard
            label={intl.formatMessage({ defaultMessage: "Public transaction comment" })}
            data={publicMemo}
            textToCopy={publicMemo}
          ></CopyListItemToClipboard>
        </List>
        <Text.Body color={colorScheme.content.secondary} mb={2} size="xs">
          {intl.formatMessage({
            defaultMessage:
              "Include your account reference code in the memo, instruction, or description field. This code is used to match deposits to your Gemini account. Without it, deposits may be delayed or returned.",
          })}
        </Text.Body>
      </HubbleCard>
    </Fragment>
  );
};

export const CbitDepositDetails = ({
  firmName,
  accountName,
  publicMemo,
  walletAddress,
  accountNumberLastFour,
}: CbitTransferInfoAPIPayload) => {
  if (walletAddress) {
    return (
      <CbitTassatDepositDetails
        firmName={firmName}
        walletName={accountName}
        walletAddress={walletAddress}
        publicDescription={publicMemo}
      />
    );
  }

  return (
    <CbitCubixDepositDetails
      firmName={firmName}
      accountNumberLastFour={accountNumberLastFour}
      publicMemo={publicMemo}
    />
  );
};
