import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { defineMessage } from "@formatjs/intl";
import { IconCheck, IconStaticBrandPlaid } from "@hubble/icons";
import * as Sentry from "@sentry/browser";
import { PlaidLinkOptions, PlaidLinkStableEvent, usePlaidLink } from "react-plaid-link";
import { EVENTS, optimizelyClient, track, useTrackLinks } from "@gemini-ui/analytics";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { BankofAmericaIcon } from "@gemini-ui/components/PlaidModal/BankIcons/BankofAmericaIcon";
import { BBTOnlineBankingIcon } from "@gemini-ui/components/PlaidModal/BankIcons/BBTOnlineBankingIcon";
import { ChaseIcon } from "@gemini-ui/components/PlaidModal/BankIcons/ChaseIcon";
import { CitiIcon } from "@gemini-ui/components/PlaidModal/BankIcons/CitiIcon";
import { CitizensBankIcon } from "@gemini-ui/components/PlaidModal/BankIcons/CitizensBankIcon";
import { MerrillLynchIcon } from "@gemini-ui/components/PlaidModal/BankIcons/MerrillLynchIcon";
import { RegionsBankIcon } from "@gemini-ui/components/PlaidModal/BankIcons/RegionsBankIcon";
import { USBankIcon } from "@gemini-ui/components/PlaidModal/BankIcons/USBankIcon";
import { WellsFargoIcon } from "@gemini-ui/components/PlaidModal/BankIcons/WellsFargoIcon";
import {
  GENERIC_PLAID_ERROR_MESSAGE,
  INSTITUTION_NOT_FOUND,
  PLAID_LINK_TOKEN_STORAGE_KEY,
  PlaidErrorModalProps,
  PlaidLinkProps,
  PlaidModalProps,
  PlaidModalState,
  RuxLoadingState,
  SEARCH_INSTITUTION,
  UsePlaidLinkTokenOptions,
} from "@gemini-ui/components/PlaidModal/constants";
import { PlaidDisclaimerBulletText } from "@gemini-ui/components/PlaidModal/styles";
import { isPlaidRedirect, trackPlaidSDKEvents } from "@gemini-ui/components/PlaidModal/utils";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { BankAccountInitialDataType } from "@gemini-ui/constants/initialData/pageProps";
import { QueryParams } from "@gemini-ui/constants/queryParams";
import { User } from "@gemini-ui/constants/templateProps/users";
import { usePageRefresh } from "@gemini-ui/contexts";
import { Button, Flex, IconBadge, Modal, Spacer, Text, useToaster } from "@gemini-ui/design-system";
import { AddPaymentMethodSuccessModal } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal";
import { AddPaymentMethodType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/AddPaymentMethodSuccessModal/constants";
import { LinkPaymentType } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/constants";
import { trackLinkedFundingMethodSuccess } from "@gemini-ui/pages/settings/BankSettings/components/AddPaymentMethods/utils";
import BankBlockedModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/BankBlockedModal";
import PlaidUnsupportedModal from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/PlaidUnsupportedModal";
import { trackPaymentRegistrationFailure } from "@gemini-ui/pages/settings/BankSettings/utils/trackPaymentRegistrationFailure";
import axios, { AxiosResponse, CancelToken, CancelTokenSource, isCancel } from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { getError } from "@gemini-ui/utils/error";
import { useIntl } from "@gemini-ui/utils/intl";
import { removeQueryParam } from "@gemini-ui/utils/queryParams";
import storage from "@gemini-ui/utils/storage";

const { ADD_BANK_INFO_THROUGH_PLAID, SUBMIT_VERIFICATION, DEPOSIT_START } = EVENTS;

const trackBankNotFound = () => {
  const eventProperties = {
    [ADD_BANK_INFO_THROUGH_PLAID.properties.PLAID_SUPPORT]: false,
    [ADD_BANK_INFO_THROUGH_PLAID.properties.PLAID_ADD]: false,
  };

  track(ADD_BANK_INFO_THROUGH_PLAID.name, eventProperties, { braze: true });
};

const trackLinkBankAccountSuccess = () => {
  const APPROVAL_TYPE = "Manual";
  const user: User = initialData.templateProps.user;
  const eventProperties = {
    [ADD_BANK_INFO_THROUGH_PLAID.properties.PLAID_SUPPORT]: true,
    [ADD_BANK_INFO_THROUGH_PLAID.properties.PLAID_ADD]: true,
  };

  trackLinkedFundingMethodSuccess(LinkPaymentType.PLAID);
  track(ADD_BANK_INFO_THROUGH_PLAID.name, eventProperties, { optimizely: true, braze: true });

  if (user.isBasicPlusTier) {
    track(SUBMIT_VERIFICATION.name, { [SUBMIT_VERIFICATION.properties.APPROVAL_TYPE]: APPROVAL_TYPE });
  }
};

const handleAlertSentry = (err, metadata, message) => {
  Sentry.withScope(scope => {
    scope.setExtras({ err });
    scope.setExtras({ metadata });
    Sentry.captureMessage(message, "warning");
  });
};

export const usePlaidModal = ({
  linkToken,
  isOpen,
  onSuccess,
  isRedirect = false,
  onError,
  onClose,
  handlePlaidNotSupported,
}: PlaidLinkProps) => {
  const plaidEvents = useRef<string[]>([]);
  const config: PlaidLinkOptions = {
    ...(isRedirect ? { receivedRedirectUri: window.location.href } : {}),
    token: linkToken,
    onSuccess,
    onEvent: (event, meta) => {
      trackPlaidSDKEvents(event, meta);
      plaidEvents.current.push(event);
    },
    onExit: (error, metadata) => {
      storage.remove(PLAID_LINK_TOKEN_STORAGE_KEY);
      if (metadata.status !== INSTITUTION_NOT_FOUND && !error) {
        track(EVENTS.EXIT_PLAID.name);
      }

      // GEM-34885 - Plaid isn't returning status so added check for empty status + no institution
      if (
        (metadata.status === INSTITUTION_NOT_FOUND || (metadata.status === "" && !metadata.institution)) &&
        plaidEvents.current.includes(SEARCH_INSTITUTION)
      ) {
        handlePlaidNotSupported();
        trackBankNotFound();
        plaidEvents.current = [];
        return;
      }

      if (error) {
        if (typeof error === "string") {
          onError(error);
        }
        handleAlertSentry(error, metadata, "Plaid Link exited with an error.");
      }

      // Dont call onClose on HANDOFF event since it will be handled by onSuccess
      if (!plaidEvents.current.includes(PlaidLinkStableEvent.HANDOFF)) {
        onClose();
      }
      plaidEvents.current = [];
    },
  };

  const { open, ready, error } = usePlaidLink(config);

  useEffect(() => {
    if (ready && isOpen) {
      open();
    }
  }, [ready, open, isOpen]);

  useEffect(() => {
    if (error) {
      if (isOpen) onError();
    }
  }, [error, isOpen, onError]);
};

export const usePlaidLinkToken = (subaccountHashid: string, opts: UsePlaidLinkTokenOptions = {}) => {
  const [linkToken, setLinkToken] = useState<string | null>(() => {
    return opts.initialLinkToken || storage.get(PLAID_LINK_TOKEN_STORAGE_KEY);
  });

  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const cancelSource = useRef<CancelTokenSource | null>(null);

  const getAndSetLinkToken = useCallback(
    async (useRux = false) => {
      if (linkToken) {
        return;
      }
      setIsLoading(true);
      try {
        cancelSource.current = CancelToken.source();
        const { data } = await axios.get(
          `${jsRoutes.controllers.settings.BankSettingsController.linkToken().url}?useRux=${useRux}`,
          {
            cancelToken: cancelSource.current?.token,
            headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          }
        );
        setLinkToken(data.accessToken);
        storage.set(PLAID_LINK_TOKEN_STORAGE_KEY, data.accessToken);
      } catch (e) {
        if (isCancel(e)) {
          return;
        }
        const error = getError(e);
        trackPaymentRegistrationFailure(LinkPaymentType.PLAID, error);
        handleAlertSentry(e, {}, "Error getting Plaid link token");
        setError(error);
      } finally {
        cancelSource.current?.cancel?.();
        setIsLoading(false);
      }
    },
    [linkToken, subaccountHashid]
  );

  const reset = useCallback(() => {
    cancelSource.current?.cancel?.();
    setLinkToken(null);
    setError(null);
    storage.remove(PLAID_LINK_TOKEN_STORAGE_KEY);
  }, []);

  return {
    resetLinkToken: reset,
    linkTokenError: error,
    linkToken,
    getAndSetLinkToken,
    isLoadingLinkToken: isLoading,
  };
};

const PlaidErrorModal = ({ onClose, isOpen }: PlaidErrorModalProps) => {
  const { intl } = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ defaultMessage: "Plaid is temporarily unavailable" })}
      isOpen={isOpen}
      onClose={onClose}
      testId="plaid-link-error-modal"
    >
      <Text.Body>
        {intl.formatMessage({
          defaultMessage: "Please try again later or add another payment method.",
        })}
      </Text.Body>
      <Button.Group>
        <Button.Primary data-testid="confirm-unable-to-connect" size="lg" onClick={onClose}>
          {intl.formatMessage({ defaultMessage: "Got it" })}{" "}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

const PlaidDisclaimerModal = ({ onClose, isOpen, onContinue, onAddBankManually, isLoadingLinkToken, onBack }) => {
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const { intl } = useIntl();
  const handleClose = () => {
    track(EVENTS.DISMISS_PLAID_DISCLAIMER.name);
    onClose();
  };

  const handleContinue = () => {
    track(EVENTS.CONTINUE_WITH_PLAID_RUX_NOT_AVAILABLE.name);
    onContinue();
  };

  const handleAddBankManually = () => {
    track(EVENTS.MANUALLY_LINK_BANK_FROM_PLAID_DISCLAIMER.name);
    onAddBankManually();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} onBack={onBack}>
      <IconBadge icon={<IconStaticBrandPlaid />} size="lg" mb={1} />
      <Text.Heading size="md" mb={1}>
        {intl.formatMessage({ defaultMessage: "Instant bank verification" })}
      </Text.Heading>
      <Text.Body size="sm" mb={1}>
        {intl.formatMessage({
          defaultMessage: "Gemini uses Plaid to verify your bank information, which helps with:",
        })}
      </Text.Body>
      <Spacer as="ul" mb={8}>
        <Flex as="li">
          <IconCheck size="sm" />
          <PlaidDisclaimerBulletText size="sm" ml={1}>
            {intl.formatMessage({
              defaultMessage: "Preventing fraud",
              description: "A benefit of linking your bank with Plaid",
            })}
          </PlaidDisclaimerBulletText>
        </Flex>
        <Flex as="li">
          <IconCheck size="sm" />
          <PlaidDisclaimerBulletText size="sm" ml={1}>
            {intl.formatMessage({
              defaultMessage: "Facilitating money transfers between your account and Gemini",
              description: "A benefit of linking your bank with Plaid",
            })}
          </PlaidDisclaimerBulletText>
        </Flex>
      </Spacer>
      <Button.Group type="stacked">
        <Button.Primary
          onClick={handleContinue}
          data-testid="plaid-disclaimer-continue"
          loading={isLoadingLinkToken}
          size="lg"
        >
          {intl.formatMessage({
            defaultMessage: "Continue",
            description: "Continue to linking your bank with plaid",
          })}
        </Button.Primary>
        {!isNewAddPaymentsFlowEnabled && (
          <Button.Tertiary
            onClick={handleAddBankManually}
            data-testid="plaid-disclaimer-add-manually"
            disabled={isLoadingLinkToken}
            size="lg"
          >
            {intl.formatMessage({
              defaultMessage: "Add bank account manually",
            })}
          </Button.Tertiary>
        )}
      </Button.Group>
    </Modal>
  );
};

const PLAID_LINK_SUCCESS_MODAL = "Plaid link success modal";

const PlaidSuccessModal = ({ onClose, isOpen }) => {
  const { intl } = useIntl();

  useTrackLinks({
    selector: "#plaid-success-deposit",
    eventName: DEPOSIT_START.name,
    properties: {
      [DEPOSIT_START.properties.INITIATED_FROM]: PLAID_LINK_SUCCESS_MODAL,
    },
  });

  return (
    <Modal isOpen={isOpen} title={intl.formatMessage({ defaultMessage: "Payment method added" })}>
      <Text.Body size="sm" mb={6}>
        {intl.formatMessage({ defaultMessage: "Make a deposit first to start trading." })}
      </Text.Body>
      <Button.Group>
        <Button.Tertiary data-testid="plaid-success-modal-dismiss" onClick={onClose}>
          {intl.formatMessage({ defaultMessage: "Maybe later" })}
        </Button.Tertiary>
        <Button.Primary data-testid="plaid-success-deposit" id="plaid-success-deposit" href="/transfer/deposit">
          {intl.formatMessage({ defaultMessage: "Deposit" })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

const PlaidSupportedBanksModal = ({ onClose, onBack, isOpen, setModalState, openGiactModal }) => {
  const { intl } = useIntl();
  const theme = useTheme();
  const colorScheme = theme.colorScheme;
  const PlaidBankIcons = [
    <BankofAmericaIcon />,
    <BBTOnlineBankingIcon />,
    <ChaseIcon />,
    <CitiIcon />,
    <CitizensBankIcon />,
    <RegionsBankIcon />,
    <USBankIcon />,
    <WellsFargoIcon />,
    <MerrillLynchIcon />,
  ];

  return (
    <Modal isOpen={isOpen} onClose={onClose} onBack={onBack}>
      <Text.Heading size="md" mb={1}>
        {intl.formatMessage({ defaultMessage: "Add bank account" })}
      </Text.Heading>
      <Text.Body size="sm" mb={1}>
        {intl.formatMessage({
          defaultMessage: "This must be an individual bank account under your name.",
        })}
      </Text.Body>
      <Flex justify="center" align="center" flexWrap="wrap" mt={2} height="300px">
        {PlaidBankIcons.map((bankIcon, index) => (
          <Flex flex="1 1 auto" width="33.33%" height="33.33%" justify="center" align="center" key={index}>
            {bankIcon}
          </Flex>
        ))}
      </Flex>
      <Flex justify="center" align="center" mt={2}>
        <Text.Body size="xs" color={colorScheme.content.secondary}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "See list of <link>Plaid’s supported institutions.</link>",
            }),
            {
              link: (v: ReactNode) => (
                <Text.Link href="https://plaid.com/institutions" color={colorScheme.content.secondary}>
                  {v}
                </Text.Link>
              ),
            }
          )}
        </Text.Body>
      </Flex>
      <Button.Group>
        <Button.Tertiary
          size="md"
          onClick={() => {
            const { name } = EVENTS.GIACT_OPTION_PRESSED;
            track(name);
            openGiactModal();
          }}
        >
          {intl.formatMessage({
            defaultMessage: "Enter info manually",
          })}
        </Button.Tertiary>
        <Button.Primary
          size="md"
          onClick={() => {
            const { name } = EVENTS.PLAID_OPTION_PRESSED;
            track(name);
            setModalState();
          }}
        >
          {intl.formatMessage({
            defaultMessage: "Verify instantly",
          })}
        </Button.Primary>
      </Button.Group>
    </Modal>
  );
};

const PlaidRuxModal = ({ onClose, isOpen, onContinue, onBack }) => {
  const { intl } = useIntl();
  const [loadingState, setLoadingState] = useState<RuxLoadingState>(null);
  const handleClose = () => {
    track(EVENTS.DISMISS_PLAID_DISCLAIMER.name);
    onClose();
  };

  const handleContinue = (useRux: boolean) => {
    track(useRux ? EVENTS.CONTINUE_WITH_PLAID_SHARE_PHONE.name : EVENTS.CONTINUE_WITH_PLAID_DONT_SHARE_PHONE.name);
    setLoadingState(useRux ? RuxLoadingState.USE_RUX : RuxLoadingState.DONT_USE_RUX);
    onContinue(useRux);
  };

  return (
    <Modal testId="verify-instantly" isOpen={isOpen} onClose={handleClose} onBack={onBack}>
      <IconBadge icon={<IconStaticBrandPlaid />} size="lg" mb={1} />
      <Text.Heading size="md" mb={1}>
        {intl.formatMessage({ defaultMessage: "Simplify account linking by sharing your phone number with Plaid?" })}
      </Text.Heading>
      <Text.Body size="sm" mb={1}>
        {intl.formatMessage({
          defaultMessage:
            "Sharing your phone number allows Plaid to show your preferred banks and to prefill your username when prompted to log into your bank.",
        })}
      </Text.Body>
      <Button.Group type="stacked">
        <Button.Primary
          data-testid="confirm-rux-share-phone"
          onClick={() => handleContinue(true)}
          loading={loadingState === RuxLoadingState.USE_RUX}
          disabled={loadingState === RuxLoadingState.DONT_USE_RUX}
          size="lg"
        >
          {intl.formatMessage({
            defaultMessage: "Yes, share my phone number",
          })}
        </Button.Primary>
        <Button.Tertiary
          data-testid="continue-without-rux"
          onClick={() => handleContinue(false)}
          loading={loadingState === RuxLoadingState.DONT_USE_RUX}
          disabled={loadingState === RuxLoadingState.USE_RUX}
          size="lg"
        >
          {intl.formatMessage({
            defaultMessage: "No, continue without sharing",
            description: "Continue to Plaid without sharing phone number",
          })}
        </Button.Tertiary>
      </Button.Group>
    </Modal>
  );
};

const getInitialModalState = ({ linkToken, linkTokenError, isUS, isPlaidRuxEnabled }) => {
  if (linkToken) {
    return PlaidModalState.LINK_BANK;
  } else if (linkTokenError) {
    return PlaidModalState.LINK_ERROR;
  } else if (isUS && isPlaidRuxEnabled) {
    return PlaidModalState.PLAID_RUX_DISCLAIMER;
  } else if (!isUS) {
    return PlaidModalState.PLAID_DISCLAIMER;
  }

  return PlaidModalState.LOADING;
};
export const PlaidModal = ({
  userCountryCode,
  isPlaidRuxEnabled,
  onClose,
  handlePlaidSuccess,
  onSuccess,
  isOpen,
  onBack,
  handlePlaidUnsupportedModalSubmit,
  openGiactModal,
  isSettingsOrOnboarding,
  scope,
  openSelectPaymentMethodModal,
  subaccountHashid,
  onSuccessCallback,
}: PlaidModalProps) => {
  const isUS = userCountryCode === "us";
  const { showAlert } = useAlert();
  const { showToast } = useToaster();
  const isNewAddPaymentsFlowEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.ADD_PAYMENTS_REVAMP);
  const isGiactEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.GIACT_MANUAL_ADD_BANK_ACCOUNT);
  const { requestRefresh } = usePageRefresh();
  const { intl } = useIntl();
  const isRedirect = useMemo(isPlaidRedirect, []);
  const { linkToken, linkTokenError, getAndSetLinkToken, isLoadingLinkToken, resetLinkToken } =
    usePlaidLinkToken(subaccountHashid);
  const [modalState, setModalState] = useState<PlaidModalState>(() =>
    isNewAddPaymentsFlowEnabled && isGiactEnabled
      ? PlaidModalState.SUPPORTED_BANKS
      : getInitialModalState({ isUS, isPlaidRuxEnabled, linkTokenError, linkToken })
  );
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!isPlaidRuxEnabled && isUS && isOpen) {
      getAndSetLinkToken();
    }
  }, [isPlaidRuxEnabled, isUS, isOpen, getAndSetLinkToken]);

  useEffect(() => {
    if (!initialized && isNewAddPaymentsFlowEnabled && isGiactEnabled) {
      setModalState(PlaidModalState.SUPPORTED_BANKS);
      setInitialized(true);
    } else {
      setModalState(getInitialModalState({ linkToken, linkTokenError, isUS, isPlaidRuxEnabled }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToken, linkTokenError, isUS, isPlaidRuxEnabled, isNewAddPaymentsFlowEnabled]);

  const handleClose = () => {
    resetLinkToken();
    onClose();
  };

  const handleAddBankManually = () => {
    resetLinkToken();
    handlePlaidUnsupportedModalSubmit();
  };

  const handlePlaidNotSupported = () => {
    setModalState(PlaidModalState.UNSUPPORTED);
  };

  const handlePlaidBankBlocked = () => {
    setModalState(PlaidModalState.BANK_BLOCKED);
  };

  const handlePlaidLinkSuccess = async (publicToken, metadata) => {
    setModalState(PlaidModalState.LOADING);
    if (isRedirect) {
      // remove OAUTH STATE ID from query params
      removeQueryParam(QueryParams.OAUTH_STATE_ID);
    }
    try {
      const linkedBankAccount: AxiosResponse<{ success: boolean; bankAccount: BankAccountInitialDataType }> =
        await axios.post(
          jsRoutes.controllers.settings.BankSettingsController.linkBankPost().url,
          {
            publicToken: publicToken,
            accountId: metadata.account_id,
            institutionType: metadata.institution.institution_id,
            institutionName: metadata.institution.name,
          },
          {
            headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          }
        );
      trackLinkBankAccountSuccess();
      if (isNewAddPaymentsFlowEnabled) {
        const { name, properties } = EVENTS.PLAID_BANK_DISTRIBUTION;
        track(name, { [properties.NAME]: linkedBankAccount.data.bankAccount.institutionName });
        setModalState(PlaidModalState.LINK_PPI_BANK_SUCCESS);
      } else {
        if (onSuccess) {
          onSuccess();
        } else {
          await handlePlaidSuccess();
          if (linkedBankAccount.data?.bankAccount?.supportsPlaidPaymentInitiation) {
            setModalState(PlaidModalState.LINK_PPI_BANK_SUCCESS);
          } else {
            showToast({
              message: intl.formatMessage({ defaultMessage: "Your bank account has been linked." }),
            });
            handleClose();
          }
        }
      }
    } catch (err) {
      trackPaymentRegistrationFailure(LinkPaymentType.PLAID, String(getError(err)));
      handleAlertSentry(err, metadata, "Plaid add bank post failed");
      if (err.response?.status === 401) {
        handlePlaidBankBlocked();
      } else {
        handlePlaidNotSupported();
      }
    }
  };

  const handlePlaidLinkError = useCallback(
    (error?: string) => {
      const errorMessage = error || GENERIC_PLAID_ERROR_MESSAGE;
      trackPaymentRegistrationFailure(LinkPaymentType.PLAID, errorMessage);
      showAlert({
        message: errorMessage,
        type: AlertTypes.ERROR,
      });
    },
    [showAlert]
  );

  if (modalState === PlaidModalState.LOADING) {
    return <Modal isOpen={isOpen} loading />;
  }

  if (modalState === PlaidModalState.PLAID_DISCLAIMER) {
    return (
      <PlaidDisclaimerModal
        onBack={onBack}
        isOpen={isOpen}
        onClose={handleClose}
        onContinue={getAndSetLinkToken}
        isLoadingLinkToken={isLoadingLinkToken}
        onAddBankManually={handleAddBankManually}
      />
    );
  }

  if (modalState === PlaidModalState.PLAID_RUX_DISCLAIMER) {
    return (
      <PlaidRuxModal
        isOpen={isOpen}
        onClose={handleClose}
        onContinue={getAndSetLinkToken}
        onBack={() =>
          isNewAddPaymentsFlowEnabled && isGiactEnabled ? setModalState(PlaidModalState.SUPPORTED_BANKS) : onBack()
        }
      />
    );
  }
  if (modalState === PlaidModalState.SUPPORTED_BANKS) {
    return (
      <PlaidSupportedBanksModal
        isOpen={isOpen}
        onClose={handleClose}
        onBack={onBack}
        setModalState={() =>
          setModalState(getInitialModalState({ isUS, isPlaidRuxEnabled, linkTokenError, linkToken }))
        }
        openGiactModal={openGiactModal}
      />
    );
  }

  if (modalState === PlaidModalState.LINK_BANK) {
    return (
      <PlaidLink
        isRedirect={isRedirect}
        onSuccess={handlePlaidLinkSuccess}
        onError={handlePlaidLinkError}
        linkToken={linkToken}
        handlePlaidNotSupported={handlePlaidNotSupported}
        isOpen={isOpen}
        onClose={handleClose}
      />
    );
  }

  if (modalState === PlaidModalState.LINK_ERROR) {
    return <PlaidErrorModal isOpen={isOpen} onClose={handleClose} />;
  }

  if (modalState === PlaidModalState.UNSUPPORTED) {
    return <PlaidUnsupportedModal isOpen={isOpen} onClose={handleClose} onSubmit={handlePlaidUnsupportedModalSubmit} />;
  }

  if (modalState === PlaidModalState.BANK_BLOCKED) {
    return <BankBlockedModal isOpen={isOpen} onClose={handleClose} />;
  }

  if (modalState === PlaidModalState.LINK_PPI_BANK_SUCCESS) {
    return isNewAddPaymentsFlowEnabled ? (
      <AddPaymentMethodSuccessModal
        isOpen={isOpen}
        onClose={() => {
          handleClose();
          requestRefresh();
        }}
        paymentMethodType={isUS ? AddPaymentMethodType.PLAID : AddPaymentMethodType.PPI}
        isSettingsOrOnboarding={isSettingsOrOnboarding}
        onSuccessCallback={onSuccessCallback}
        scope={scope}
        openSelectPaymentMethodModal={openSelectPaymentMethodModal}
      />
    ) : (
      <PlaidSuccessModal isOpen={isOpen} onClose={handleClose} />
    );
  }

  return null;
};

const PlaidLink = (props: PlaidLinkProps) => {
  usePlaidModal(props);
  return null;
};
