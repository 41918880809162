import { CreditCardParams, OnfidoDocumentResponse, VerifyContext } from "@gemini-ui/pages/register/Verify/types";
import axios, { AxiosError, AxiosResponse } from "@gemini-ui/services/axios";

export const recordDocumentUploadUrl = "/upload/new/record-upload";

export const recordDocumentUpload = (documentUpload: OnfidoDocumentResponse, creditCardParams?: CreditCardParams) => {
  return axios
    .post(recordDocumentUploadUrl, documentUpload, { withCredentials: true, params: { ...creditCardParams } })
    .then((response: AxiosResponse<VerifyContext>) => {
      const { data } = response;
      return data;
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};
