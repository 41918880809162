import { Dispatch, Fragment, SetStateAction, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import { IconChevronRightSmall, IconPlus, IconProfileMultiple } from "@hubble/icons";
import {
  ExtendedSubaccountWithBalances,
  getAdvancedAccountSelectorCopy,
} from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import { ListItemWrapper } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/styles";
import { InfiniteScrollWrapper } from "@gemini-ui/components/InfiniteScrollWrapper";
import { Money } from "@gemini-ui/components/Money";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Badge, Button, Flex, List, ListItem, SearchInput, Spacing, Text } from "@gemini-ui/design-system";
import { SpinnerAnimation } from "@gemini-ui/images/animations/SpinnerAnimation";
import { SubaccountWithBalances } from "@gemini-ui/services/subaccounts";
import { useIntl } from "@gemini-ui/utils/intl";

interface AccountListProps {
  subaccounts: ExtendedSubaccountWithBalances[];
  topSubaccount: SubaccountWithBalances | null;
  editMode: boolean;
  selectedAccount: SubaccountWithBalances;
  handleSelectAccount: (e: any) => void;
  handleEditMode: () => void;
  handleEditAccount: (e: any) => void;
  onAddAccount: () => void;
  setParams: Dispatch<
    SetStateAction<{
      filter: string;
      offset: number;
    }>
  >;
  loading: boolean;
  currentPage: number;
  loadMore: () => void;
}

export const AccountList = ({
  subaccounts,
  topSubaccount,
  editMode,
  selectedAccount,
  handleSelectAccount,
  handleEditMode,
  handleEditAccount,
  onAddAccount,
  setParams,
  loading,
  currentPage,
  loadMore,
}: AccountListProps) => {
  const {
    templateProps: {
      user: { subaccounts: templateSubaccounts },
    },
  } = usePageData();

  const { colorScheme } = useTheme();
  const { intl } = useIntl();
  const copy = getAdvancedAccountSelectorCopy(intl);
  const [search, setSearch] = useState("");
  const handleSearchChange = (searchText: string) => {
    setSearch(searchText);
    setParams({ filter: searchText, offset: 0 });
  };

  const handleClearSearch = () => {
    setParams({ filter: "", offset: 0 });
    setSearch("");
  };

  const listItemProps = {
    selectedAccount,
    editMode,
    handleSelectAccount,
    handleEditAccount,
  };

  return (
    <Fragment>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p={2}
        height="64px"
        borderBottom={`${colorScheme.border.secondary} 1px solid`}
        background={colorScheme.elevated.background.modal}
        borderTopRightRadius="8px"
        borderTopLeftRadius="8px"
      >
        <Text.Body size="sm" bold>
          {!editMode ? copy.LABELS.ACCOUNTS : copy.LABELS.SELECT_ACCOUNT}
        </Text.Body>
        <Button.Secondary
          id="account-switcher-edit-button"
          cta={editMode ? copy.CTA.DONE : copy.CTA.EDIT_ACCOUNTS}
          tone="neutral"
          size="sm"
          leftElement={!editMode && <IconProfileMultiple />}
          onClick={handleEditMode}
          data-testid="edit-accounts"
        />
      </Flex>
      {templateSubaccounts.length > 10 && (
        <SearchInput
          data-testid="search-accounts"
          hideLabel
          onChange={({ target }) => handleSearchChange(target.value)}
          onClear={handleClearSearch}
          placeholder={copy.LABELS.SEARCH_ACCOUNTS}
          value={search}
          variant="outlined"
          width="100%"
          pl={2}
          pr={2}
          mt={1}
          mb={1}
        />
      )}
      <InfiniteScrollWrapper
        offset={50}
        data-testid="accounts-scroll-wrapper"
        loading={loading}
        onLoadMore={loadMore}
        resetPosition={currentPage === 1}
        height={400}
      >
        <List>
          {subaccounts.length < 1 ? (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pt={4}
              pb={4}
              data-testid="account-dropdown-empty"
            >
              <Text.Body size="sm">{copy.LABELS.NONE_FOUND}</Text.Body>
            </Flex>
          ) : null}
          <ListItemWrapper>
            {topSubaccount && !(subaccounts.length < 1) && (
              <AccountListItem {...listItemProps} subaccount={topSubaccount} />
            )}
            {subaccounts.map((subaccount, index) => (
              <AccountListItem {...listItemProps} subaccount={subaccount} key={"AccountListItem" + index} />
            ))}
          </ListItemWrapper>
          {loading && (
            <Flex flexDirection="column" align="center" mb={3}>
              <SpinnerAnimation size={Spacing.scale[4]} pt={3} pb={3} />
            </Flex>
          )}
        </List>
      </InfiniteScrollWrapper>
      {editMode && (
        <ListItem
          hasDivider={false}
          padding="sm"
          left={
            <Button.Secondary
              cta={copy.CTA.ADD_NEW_ACCOUNT}
              data-testid="add-account"
              tone="neutral"
              size="sm"
              leftElement={<IconPlus />}
              onClick={e => {
                e.preventDefault();
                onAddAccount();
                window.location.href = jsRoutes.controllers.settings.NewAccountSettingsController.get().url;
              }}
            />
          }
        />
      )}
    </Fragment>
  );
};

interface AccountListItemProps {
  selectedAccount: SubaccountWithBalances;
  subaccount: ExtendedSubaccountWithBalances;
  editMode: boolean;
  handleSelectAccount: (e: any) => void;
  handleEditAccount: (e: any) => void;
}

const AccountListItem = ({
  selectedAccount,
  editMode,
  handleEditAccount,
  handleSelectAccount,
  subaccount,
}: AccountListItemProps) => {
  const { colorScheme } = useTheme();
  const { needsActivation } = subaccount;
  const onClick = needsActivation ? null : editMode ? handleEditAccount : handleSelectAccount;
  const { intl } = useIntl();
  const { toggleModal } = useGlobalModal();
  const copy = useMemo(() => getAdvancedAccountSelectorCopy(intl), [intl]);
  const handleActivateAccount = () => toggleModal(GlobalModalType.PerpsOnboardingAccountActivateFormModal);

  const showBalance = subaccount.totals?.totalNotional && subaccount.totals.totalNotional.balance;

  return (
    <ListItem
      hasDivider={false}
      id={subaccount?.hashid}
      data-testid={`menu-item-${subaccount?.hashid}`}
      padding="sm"
      as={!needsActivation ? "button" : "div"}
      aria-selected={subaccount?.hashid === selectedAccount.hashid}
      onClick={onClick}
      className={subaccount?.hashid === selectedAccount.hashid && !editMode && "active-sub"}
      size="dense"
      left={
        <Flex.Column alignItems="left">
          <Text.Body size="sm">{subaccount?.name}</Text.Body>
          <Text.Body size="xs" color={colorScheme.content.secondary}>
            {subaccount.trading
              ? subaccount.derivatives
                ? copy.LABELS.DERIVATIVES
                : copy.LABELS.EXCHANGE
              : copy.LABELS.CUSTODY}
          </Text.Body>
          {subaccount?.crossCollateralParams && (
            <Flex pt={0.5}>
              {subaccount?.crossCollateralParams?.maxLeverage && (
                <Badge size="sm" status="neutral" mr={0.5}>
                  {copy?.BADGE?.[`LEVERAGE_${Number(subaccount?.crossCollateralParams?.maxLeverage)}X`]}
                </Badge>
              )}
              {subaccount?.crossCollateralParams?.enableCrossCollateral === "true" && (
                <Badge size="sm" status="neutral" mr={0.5}>
                  {copy?.BADGE?.CROSS_COLLATERAL}
                </Badge>
              )}
              {subaccount?.crossCollateralParams?.enableNegativeBalances === "true" && (
                <Badge size="sm" status="neutral" mr={0.5}>
                  {copy?.BADGE?.NEGATIVE_BALANCE}
                </Badge>
              )}
            </Flex>
          )}
        </Flex.Column>
      }
      right={
        needsActivation ? (
          <Button.Secondary cta={copy.CTA.ACTIVATE} size="sm" onClick={handleActivateAccount} />
        ) : editMode ? (
          <Flex alignItems="center">
            <IconChevronRightSmall color={colorScheme.content.primary} />
          </Flex>
        ) : (
          showBalance && (
            <Text.Body size="sm">
              <Money
                currency={subaccount.totals.totalNotional.balance.currency}
                value={subaccount?.totals.totalNotional.balance.value}
              />
            </Text.Body>
          )
        )
      }
    />
  );
};
