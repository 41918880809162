import { ReactElement, useEffect, useState } from "react";
import { IconDerivatives, IconLeaderboard } from "@hubble/icons";
import _ from "lodash";
import { IntlShape } from "react-intl";
import { Order } from "@gemini-ui/constants/orders";
import { PositionTransfer } from "@gemini-ui/constants/perpEvents";
import { User } from "@gemini-ui/constants/templateProps/users";
import { GlobalModalType } from "@gemini-ui/contexts/GlobalModal";
import axios, { CACHE_ID } from "@gemini-ui/services/axios/cache";
import { FeatureElectionStatus } from "@gemini-ui/utils/constants";
import { getError } from "@gemini-ui/utils/error";
import storage from "@gemini-ui/utils/storage";

export const isDerivativesEnabled = (userState: User) => {
  const subaccountHashid = _.get(userState, "subaccountHashid", "");
  const subaccounts = _.get(userState, "subaccounts", []);
  const currentAccount = subaccounts.find(acct => acct.hashid === subaccountHashid);
  return Boolean(currentAccount?.derivatives);
};

export const getDerivativeAccountDetails = (userState: User) => {
  const subaccountHashid = _.get(userState, "subaccountHashid", "");
  const subaccounts = _.get(userState, "subaccounts", []);
  const currentAccount = subaccounts.find(acct => acct.hashid === subaccountHashid);
  return currentAccount;
};

export const hasDerivativesAccount = (userState: User) => {
  const subaccounts = _.get(userState, "subaccounts", []);
  return Boolean(subaccounts.find(acct => acct.derivatives));
};

const ACCOUNT_ELIGIBILE_STORAGE_KEY = "d-account-eligible";
const ELIGIBLE = "eligible";
const INELIGIBLE = "ineligible";

type CheckPerpEligibilityTypes = {
  TradePerpEnabled: boolean;
  PerpsUiEnabled: boolean;
  user: User;
  callback?: Function;
};

type AllowPerpetualAccountResponse = {
  message: string;
  data: boolean;
};

export function useCheckPerpEligibility({
  user,
  callback,
  TradePerpEnabled,
  PerpsUiEnabled,
}: CheckPerpEligibilityTypes) {
  const [isPerpsEligible, setIsPerpsEligible] = useState(false);
  const storageKey = [ACCOUNT_ELIGIBILE_STORAGE_KEY, user?.subaccountHashid].join(".");
  const previousCheckResult = storage.get(storageKey, "sessionStorage");

  useEffect(
    function checkPerpEligibility() {
      const hasDerivativesAccount = user && user.subaccounts?.some(acct => acct.derivatives);
      const proceedWithCheck = !previousCheckResult && user && !hasDerivativesAccount;
      if (proceedWithCheck) {
        const perpEligibleUrl =
          jsRoutes.com.gemini.web.server.trading.controllers.PerpetualSwapsController.allowPerpetualAccount().url;

        if (TradePerpEnabled && PerpsUiEnabled) {
          axios
            .get<AllowPerpetualAccountResponse>(perpEligibleUrl, {
              withCredentials: true,
              id: CACHE_ID.perpEligible,
              cache: { ttl: 15000 },
            })
            .then(res => {
              const { data: allowed } = res.data;
              if (allowed) {
                storage.set(storageKey, ELIGIBLE, "sessionStorage");
                setIsPerpsEligible(true);
                callback?.();
              } else {
                storage.set(storageKey, INELIGIBLE, "sessionStorage");
              }
            })
            .catch(e => {
              storage.set(storageKey, INELIGIBLE, "sessionStorage");
            });
        }
      } else if (hasDerivativesAccount || previousCheckResult === ELIGIBLE) {
        setIsPerpsEligible(true);
        callback?.();
      }
    },
    [TradePerpEnabled, callback, user, PerpsUiEnabled, previousCheckResult, storageKey]
  );

  if (previousCheckResult === ELIGIBLE && !isPerpsEligible) {
    setIsPerpsEligible(true);
  }
  return isPerpsEligible;
}

export const PERPS_DISCLAIMER_STORAGE_KEY = "ps-disclaimer-modal";
export const LEADERBOARD_MODAL_STORAGE_KEY = "leaderboard-optin-modal";
export const LEADERBOARD_COUNTDOWN_DISPLAY = "countdownLeaderboard";
export const LEADERBOARD_MODULE_HIDDEN = "leaderboard-module-hidden";
// Leverage options
type LeverageOptions = {
  leverageValues: number[];
  defaultLeverage: number;
};

function generateLeverageOptions(options: LeverageOptions, defaultLabel: string) {
  return options.leverageValues
    .sort((a, b) => Number(a) - Number(b))
    .map(value => {
      const label = value === options.defaultLeverage ? `${value}x (${defaultLabel})` : `${value}x`;
      return {
        label,
        value,
      };
    });
}

type InitLeverageOptions = {
  copy: {
    default: string;
    errorMessage?: string;
  };
  setErrorMsg: (string) => void;
};

export function useFetchLeverageOptions({ setErrorMsg, copy }: InitLeverageOptions) {
  const [leverageOptions, setLeverageOptions] = useState<{ label: string; value: number }[]>(null);
  const [defaultLeverage, setDefaultLeverage] = useState<number>(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(
    function fetchLeverageOptions() {
      setIsLoading(true);
      const leverageUrl =
        jsRoutes.com.gemini.web.server.trading.controllers.PerpetualSwapsController.allowedUiLeverages().url;
      axios
        .get(leverageUrl)
        .then((res: { data: LeverageOptions }) => {
          const { data } = res;
          const _leverageOptions = generateLeverageOptions(data, copy.default);
          setLeverageOptions(_leverageOptions);
          setDefaultLeverage(data.defaultLeverage);
        })
        .catch(error => {
          const errorMsg = getError(error, copy.errorMessage);
          setErrorMsg(errorMsg);
          setLeverageOptions([]);
        })
        .finally(() => setIsLoading(false));
    },
    [copy.default, copy.errorMessage, setErrorMsg]
  );

  return { leverageOptions, defaultLeverage, isLoading };
}

// merge liquidation transfer events with liquidation orders, sorted by timestamp.
export function makeLiquidationData(data: Order[], liquidationTransfers: PositionTransfer[]) {
  const getTs = x => ("positionTransfer" in x ? x.positionTransfer.timestamp : x.created);
  const liquidationData = [...data, ...liquidationTransfers].sort(function sortLiquidationData(a, b) {
    return getTs(b) - getTs(a);
  });
  return liquidationData;
}

export const getDerivativesLinkCopy = (
  isInstitutional: boolean,
  isOptInFeatureFlagEnabled: boolean,
  hasAlreadyOptedInLeaderboard: boolean,
  hasADerivativesAccount: boolean,
  customLayoutsEnabled: boolean,
  intl: IntlShape
): { title: string; icon: ReactElement; label?: string } => {
  const isLeaderboardEligible = !isInstitutional && isOptInFeatureFlagEnabled;
  return hasADerivativesAccount
    ? isLeaderboardEligible
      ? hasAlreadyOptedInLeaderboard
        ? { title: intl.formatMessage({ defaultMessage: "Trade Perpetuals" }), icon: <IconDerivatives /> }
        : {
            title: customLayoutsEnabled ? "" : intl.formatMessage({ defaultMessage: "Join Derivatives Leaderboard" }),
            icon: <IconLeaderboard />,
            label: intl.formatMessage({ defaultMessage: "Join Derivatives Leaderboard" }),
          }
      : { title: intl.formatMessage({ defaultMessage: "Trade Perpetuals" }), icon: <IconDerivatives /> }
    : {
        title: intl.formatMessage({ defaultMessage: "Introducing Perpetual Swaps" }),
        icon: <IconDerivatives />,
      };
};

export const shouldShowDerivativesLink = (
  isOptInFeatureFlagEnabled: boolean,
  isDerivativeAccount: boolean,
  hasAlreadyOptedInLeaderboard: boolean,
  perpsDismissedPromoCard: boolean,
  isPerpsEligible: boolean
) => {
  const flagIsOnANdNotOptedIn = isOptInFeatureFlagEnabled && !hasAlreadyOptedInLeaderboard;
  return (flagIsOnANdNotOptedIn || (!isDerivativeAccount && !perpsDismissedPromoCard)) && isPerpsEligible;
};

export const perpsLinkCTA = (
  derivativesAccount: boolean,
  isOptInLeaderboard: boolean,
  isInstitutional: boolean,
  LeaderboardOptInEnabled: boolean,
  isPerpsEligible: boolean,
  toggleModal: (arg: GlobalModalType) => void,
  navigateToSubaccount: () => void
) => {
  if (derivativesAccount) {
    if (!isOptInLeaderboard && !isInstitutional && LeaderboardOptInEnabled) {
      toggleModal(GlobalModalType.PerpsOnboardingLeaderboardInfoModal);
    } else navigateToSubaccount();
  } else {
    if (isPerpsEligible) {
      toggleModal(GlobalModalType.PerpsOnboardingIntroductionModal);
    } else {
      toggleModal(GlobalModalType.PerpsIneligibleModal);
    }
  }
};

export const shouldDisplayTheLeaderboardModal = (
  forceLeaderboardModal: boolean,
  optInLeaderboardStatus: FeatureElectionStatus,
  leaderboardOptInSession: boolean,
  LeaderboardOptInEnabled: boolean,
  isInstitutional: boolean
) => {
  const isForced =
    forceLeaderboardModal &&
    Boolean(optInLeaderboardStatus) &&
    !(optInLeaderboardStatus === FeatureElectionStatus.OptIn);
  const meetsTheRequirements =
    LeaderboardOptInEnabled &&
    optInLeaderboardStatus === FeatureElectionStatus.NoDecision &&
    !Boolean(leaderboardOptInSession) &&
    !isInstitutional;
  return isForced || meetsTheRequirements;
};

export const isModuleDisplayable = () => !Boolean(storage.get(LEADERBOARD_MODULE_HIDDEN) === "true");

export const isCurrentAccountDerivative = (user: User) =>
  Boolean(user?.subaccounts?.find(({ hashid }) => hashid === user.subaccountHashid)?.derivatives);
