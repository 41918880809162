import { JSXElementConstructor, ReactElement, useState } from "react";
import * as React from "react";
import { NavMenuContainer } from "@gemini-ui/components/Header/navigation/IconContainer/styles";
import { HubbleButton, HubbleMenu } from "@gemini-ui/design-system";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props {
  id: string;
  placement?: "start" | "center" | "end";
  width?: number;
  renderComponent: React.ReactElement;
  icon: ReactElement<any, string | JSXElementConstructor<any>>;
  iconLabel: string;
  badge?: ReactElement;
  isIconOnly?: boolean;
}

const NavMenu = ({ id, placement, width, renderComponent, icon, iconLabel, badge, isIconOnly = false }: Props) => {
  const { intl } = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const ariaLabel = intl.formatMessage(
    defineMessage({
      defaultMessage: "{iconLabel} Header Button",
    }),
    { iconLabel }
  );

  const handleOpenChange = () => {
    if (!isOpen && renderComponent) {
      setIsOpen(!isOpen);
    } else if (isOpen) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <NavMenuContainer id={`${id}Menu`}>
      <HubbleMenu onOpenChange={handleOpenChange} modal={false}>
        <HubbleMenu.Trigger>
          {isIconOnly ? (
            <HubbleButton.Secondary
              data-id={id}
              data-testid={`${id}NavIcon`}
              aria-label={ariaLabel}
              size="sm"
              icon={!badge && icon}
              leftElement={badge && icon}
              rightElement={badge && badge}
              mr={0.5}
            />
          ) : (
            <HubbleButton.Tertiary
              data-id={id}
              data-testid={`${id}NavIcon`}
              aria-label={ariaLabel}
              size="sm"
              cta={iconLabel}
              leftElement={icon}
              rightElement={badge}
              mr={0.5}
            />
          )}
        </HubbleMenu.Trigger>
        <HubbleMenu.Content
          align={placement}
          width={width ? `${width}px` : "auto"}
          height="auto"
          style={{ top: "8px", maxHeight: "635px" }}
        >
          {renderComponent}
        </HubbleMenu.Content>
      </HubbleMenu>
    </NavMenuContainer>
  );
};

export default NavMenu;
