import { GENERIC_3DS_ERROR } from "@gemini-ui/pages/RetailTrade/AssetDetail/constants";
import { AxiosError } from "@gemini-ui/services/axios";
import { getError } from "@gemini-ui/utils/error";

export const handle3DSErrorState = (
  error: AxiosError,
  openDebitCardErrorModal: (debitCardErrorHeading: string, debitCardErrorDescription?: string) => void
) => {
  const errorMessage = getError(error, GENERIC_3DS_ERROR);
  openDebitCardErrorModal(errorMessage);
};
