const screenTestIds = {
  enterLegalName: "enterLegalNameScreen",
  enterDateOfBirth: "enterDateOfBirthScreen",
  enterAddress: "enterAddressScreen",
  enterPhone: "enterPhone",
  verifyPhone: "verifyPhone",
  // SSN, SIN
  enterNationalIdNumber: "enterNationalIdNumberScreen",
  enterSourceOfWealth: "enterSourceOfWealth",
  enterFinancialInfo: "enterFinancialInfo",
  enterFinancialProfile: "enterFinancialProfile",
  enterExchangeUsage: "enterExchangeUsage",
  fundAccount: "fundAccount",
  uploadDocument: "uploadDocument",
  uploadPassport: "uploadPassportScreen",
  uploadProofOfAddress: "uploadProofOfAddressScreen",
  documentWelcomeScreen: "documentWelcomeScreen",
  successBasicTer: "successBasicTerScreen",
  successDefault: "successDefault",
  successPendingApproval: "successPendingApproval",
  successPendingFromBasic: "successPendingFromBasic",
  verifyingIdentity: "verifyingIdentity",
  verifyIdentity: "verifyIdentity",
  manualReview: "manualReview",
  createPasskey: "createPasskey",
};

export const testIds = {
  screens: screenTestIds,
  button: {
    sendSms: "send-sms",
    signOut: "signout",
    skipForNow: "skipForNow",
    submitOptIn: "submitOptIn",
    submitAuthy: "submit-authy",
    submitLocationModal: "submitLocationModal",
    submitRegistration: "submitRegistration",
    submitAddressForm: "submitAddressForm",
    submitLoginForm: "submitLoginForm",
    submitLegalName: "submitLegalName",
    submitDateOfBirth: "submitDateOfBirth",
    submitBirthdateAndSSN: "submitBirthdateAndSSN",
    submitNationalIdNumber: "submitNationalIdNumber",
    continueLoginForm: "continueLoginForm",
    chooseDifferentIDType: "chooseDifferentIDType",
    filestackUploadFile: "file-uploader-btn",
    docWelcomeFinishAndSubmit: "doc-welcome-finish-and-submit",
    uploadDocumentMock: "uploadDocumentMock",
    uploadDocumentFailedExtractionMock: "uploadDocumentFailedExtractionMock",
    uploadDrivingLicense: "uploadDrivingLicense",
    uploadPassportOrDrivingLicenseOrNationalIdCard: "uploadPassportOrDrivingLicenseOrNationalIdCard",
    uploadNationalIdCard: "uploadNationalIdCard",
    uploadProofOfAddress: "uploadProofOfAddress",
    documentWelcomeScreen: "documentWelcomeScreenBtn",
    verificatonModalContinue: "verificationModalContinue",
    submitSourceOfWealth: "submitSourceOfWealth",
    submitFinancialInfo: "submitFinancialInfo",
    submitExchangeUsage: "submitExchangeUsage",
    closeLocationModal: "closeLocationModal",
    selectAnotherCountry: "selectAnotherCountry",
    locationEdit: "register-change-location-edit",
    submitPhoneBtn: "confirmPhone-sendCodeBtn",
    confirmPhoneBtn: "confirmPhone-authCodeBtn",
    individualAccount: "individualAccount-selection",
    businessAccount: "businessAccount-selection",
    businessLocationEdit: "business-location-edit",
    verifyingIdentityBtn: "verifyingIdentityBtn",
    fundAccountWithBank: "fundAccountWithBank",
    fundAccountWithCrypto: "fundAccountWithCrypto",
    skipFundingButton: "skip-funding-button",
    changeEmailAddress: "change-email-address",
    createPasskeyFromWarningBtn: "create-passkey-from-warning-btn",
    skipPasskeyWarningBtn: "skip-passkey-warning-btn",
  },
  input: {
    optInEmail: "optInEmailInput",
    firstName: "firstNameInput",
    middleName: "middleNameInput",
    lastName: "lastNameInput",
    emailAddress: "emailAddressInput",
    password: "passwordInput",
    dateOfBirth: "dateOfBirthInput",
    nationalIdNumber: "nationalIdNumberInput",
    addressStreet: "addressStreetInput",
    addressLocality: "addressLocalityInput",
    addressRegion: "addressRegionInput",
    addressCountry: "addressCountryInput",
    addressPostalCode: "addressPostalCodeInput",
    addressApartment: "addressApartmentInput",
    authyCode: "authyCode",
    sourceOfWealthOther: "sourceOfWealthOtherInput",
    filestackUploaderInput: "file-upload-input",
    referral: "referralInput",
    colombiaNationalId: "colombiaNationalId",
    phoneNumberInput: "phone-number-input",
    verifyPhoneNumberInput: "verify-phone-number-input",
    authCodeError: "auth-code-error",
    rememberEmailAddress: "remember-email-address",
  },
  dropdown: {
    addressStreet: "addressStreetDropdown",
    country: "countryDropdown",
    sourceOfWealth: "sourceOfWealthDropdown",
    nationality: "nationalityDropdown",
    locationCountry: "country-selection-dropdown",
    locationState: "state-selection-dropdown",
    language: "language-selection-dropdown",
    occupations: "occupations",
    industry: "industry",
    annualIncome: "annualIncome",
    netWorth: "netWorth",
    purposeOfAccount: "purposeOfAccount",
    expectedTradedAmount: "expectedTradedAmount",
    cryptoTradingExperience: "cryptoTradingExperience",
    riskAssessmentTolerance: "risk-assessment-tolerance-select",
    riskAssessmentExperience: "risk-assessment-experience-select",
  },
  tradingExperience: {
    newToCrypto: "newToCrypto",
    someCrypto: "someCrypto",
    experiencedTrader: "experiencedTrader",
  },
  link: {
    goToRegister: "goToRegister",
    goToResetPassword: "goToResetPassword",
  },
  div: {
    linkContainer: "link-container",
  },
};
