import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { HeaderNavLink } from "@gemini-ui/components/Header/HeaderMenuLink/styles";
import { BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { Colors, Spacing, Text } from "@gemini-ui/design-system";
import { ReactComponent as BackIconBase } from "@gemini-ui/images/icons/nav/back.svg";

export const Container = styled(HeaderNavLink)<{ isSingleAccount?: boolean }>`
  display: flex;
  padding: 0;

  svg {
    pointer-events: none;
  }

  ${p =>
    p.isSingleAccount &&
    css`
      margin-right: ${Spacing.scale[0.5]};
    `}
`;

export const BackIcon = styled(BackIconBase)`
  margin-right: ${Spacing.scale[2]};
`;

export const Title = styled("div")<{ isSingleAccount?: boolean }>`
  color: ${Colors.white};
  margin-right: ${Spacing.scale[1]};
  line-height: 1.6;
  user-select: none;

  @media ${BREAKPOINTS.desktop} {
    display: ${p => (p.isSingleAccount ? "none" : "block")};
  }
`;

export const HubbleTitle = styled(Text.Body)<{ isSingleAccount?: boolean }>`
  @media ${BREAKPOINTS.desktop} {
    display: ${p => (p.isSingleAccount ? "none" : "block")};
  }
`;
