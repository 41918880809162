export enum CurrentMethodState {
  Passkey = "Passkey",
  TextMessage = "TextMessage",
  Authy = "Authy",
}

export enum TwoFactorViewType {
  Dialog = "Dialog",
  TwoFactorPage = "twoFactorPage",
}
