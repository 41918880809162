import { FilterType } from "@gemini-ui/pages/ActiveTrader/Spot/constants";

export const enum PairLayoutComponent {
  NAV = "NAV",
  CHART = "CHART",
  ORDER_BOOK = "ORDER_BOOK",
  EXCHANGE_ACTIVITY = "EXCHANGE_ACTIVITY",
  ORDER_FORM = "ORDER_FORM",
  SUB_LAYOUT = "SUB_LAYOUT",
  TRADING_VIEW_CHART = "TRADING_VIEW_CHART",
  DEPTH_CHART = "DEPTH_CHART",
}

export const enum CommonLayoutComponent {
  LEADERBOARD = "LEADERBOARD",
  LEADERBOARD_MOBILE = "LEADERBOARD_MOBILE",
  ACCOUNT_DETAILS = "ACCOUNT_DETAILS",
  OPEN_ORDERS = "OPEN_ORDERS",
  ORDER_HISTORY = "ORDER_HISTORY",
  BALANCES = "BALANCES",
  // Derivatives account blotters
  POSITIONS = "POSITIONS",
  TRADE_HISTORY = "TRADE_HISTORY",
  LIQUIDATION = "LIQUIDATION",
  FUNDING = "FUNDING",
  TRANSFERS = "TRANSFERS",
  REALIZED_PNL = "REALIZED_PNL",
  BLOTTER = "BLOTTER",
}

export const BlotterTabsToFilterTypeMap = {
  [CommonLayoutComponent.OPEN_ORDERS]: FilterType.open,
  [CommonLayoutComponent.ORDER_HISTORY]: FilterType.completed,
  [CommonLayoutComponent.POSITIONS]: FilterType.positions,
  [CommonLayoutComponent.BALANCES]: FilterType.balances,
  [CommonLayoutComponent.TRADE_HISTORY]: FilterType.tradeHistory,
  [CommonLayoutComponent.LIQUIDATION]: FilterType.liquidation,
  [CommonLayoutComponent.FUNDING]: FilterType.funding,
  [CommonLayoutComponent.TRANSFERS]: FilterType.transfers,
  [CommonLayoutComponent.REALIZED_PNL]: FilterType.realizedPl,
};

export const enum TabSet {
  CHART = "TABSET-CHART",
  BLOTTERS = "TABSET-BLOTTERS",
  MARKET_AND_ORDERBOOK = "TABSET-MARKET_AND_ORDERBOOK",
  MARKET_TRADES = "TABSET-MARKET_TRADES",
  ORDERBOOK = "TABSET-ORDERBOOK",
  ORDER_FORM = "TABSET-ORDER_FORM",
  LEADERBOARD = "TABSET-LEADERBOARD",
  ACCOUNT_DETAILS = "TABSET-ACCOUNT_DETAILS",
}

export type ModelProps = {
  displayLeaderboardModule: boolean;
  tabletSmDown: boolean;
  isDerivativesAcct: boolean;
  showDerivativeBlotters: boolean;
  isDualMarket: boolean;
  showOnlyOrderForm: boolean;
};
