import { CardColorEnum, CreditAccountBalances, PaymentType, TxTypeEnum } from "@gemini-ui/client/credit";

export const TEXT_DATE_LIMIT = 3;

export const REWARDS_PERCENT_GAS = 4;
export const REWARDS_PERCENT_FOOD = 3;
export const REWARDS_PERCENT_GROCERIES = 2;
export const REWARDS_PERCENT_REST = 1;

enum All {
  All = "ALL",
}

export type CARD_COLOR_TYPE = CardColorEnum | All;
export const CARD_COLOR_ENUM = { ...CardColorEnum, ...All };

enum PaymentTypeLabel {
  Statement = "Statement balance",
  Current = "Current balance",
  Minimum = "Minimum payment",
}

export const PAYMENT_TYPE_MAP: Partial<{
  [key in PaymentType]: { label: PaymentTypeLabel; value: keyof CreditAccountBalances };
}> = {
  STATEMENT: {
    label: PaymentTypeLabel.Statement,
    value: "statementRunningBalance",
  },
  CURRENT: {
    label: PaymentTypeLabel.Current,
    value: "currentBalance",
  },
  MINIMUM: {
    label: PaymentTypeLabel.Minimum,
    value: "minimumDue",
  },
};

export enum PaymentScheduleTypeLabel {
  OneTime = "One-time",
  AutoPay = "AutoPay",
}

export const REWARD_TIMEFRAMES = {
  MONTHLY: "monthly",
  TOTAL: "total",
  ANNUAL: "annual",
};

export const CUSTOM_DROPDOWN_OPTIONS = {
  ADD_NEW: "add_new",
  CUSTOM_DATE: "custom_date",
};

export const TRANSACTION_STATUSES = {
  PENDING: {
    label: "Pending",
    tooltip:
      "Transactions that are still pending will be reflected in your available credit but not your balance. This amount may change.",
  },
  DISPUTED: {
    label: "Disputed",
    tooltip:
      "A dispute has been submitted for this transaction. If you have further questions or need more information regarding the dispute, please call us at 1-877-725-1116.",
  },
  SETTLED: {
    label: "Posted",
    tooltip: null,
  },
};

export const PAYMENT_STATUSES = {
  SCHEDULED: {
    label: "Scheduled",
    tooltip:
      "Once your payment is received, your balance will be updated immediately. Please note it could take up to a week for this to be reflected in your available credit.",
  },
  PROCESSED: {
    label: "Paid",
    tooltip:
      "Your payment has been received and your balance has been updated. Please note it could take up to a week for this to be reflected in your available credit.",
  },
  CANCELLED: {
    label: "Cancelled",
    tooltip: "This payment was cancelled.",
  },
  RETURNED: {
    label: "Returned",
    tooltip: "Your Gemini Credit Card payment was returned by your bank.",
  },
};

export const TX_TYPE_LABEL: Partial<Record<TxTypeEnum, string>> = {
  cash_advance_fee: "Cash advance fee",
  cash_advance_fee_reversal: "Cash advance fee credit",
  credit_refund: "Credit refund",
  credit_refund_reversal: "Credit refund reversal",
  fee: "Fee",
  fee_reversal: "Fee credit",
  interest: "Interest",
  interest_reversal: "Interest credit",
  payment_transaction: "Payment processed",
  payment_reversal: "Payment credit",
};

export enum PaymentModals {
  ONE_TIME = "ONE_TIME",
  AUTO_PAY = "AUTO_PAY",
  ADD = "ADD",
  DETAILS = "DETAILS",
  CANCEL = "CANCEL",
  GIACT_ERROR = "GIACT_ERROR",
  NONE = "NONE",
}

export enum PaymentAction {
  CREATE = "CREATE",
  CANCEL = "CANCEL",
  UPDATE = "UPDATE",
  NONE = "NONE",
}

export const REWARDS_ERR_MSG = "Could not load rewards information. Please try again later.";

export const LOCKED_BY_CUSTOMER = "Locked by customer.";
export const UNLOCKED_BY_CUSTOMER = "Unlocked by customer.";
