import { Fragment, useMemo, useState } from "react";
import { css, Global } from "@emotion/react";
import { IconChevronDownSmall, IconChevronUpSmall } from "@hubble/icons";
import { truncate } from "lodash";
import Media from "react-media";
import { useMedia } from "react-use";
import AdvancedAccountSelector from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/AdvancedAccountSelector";
import { FAKE_SUBBACCOUNT } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/constants";
import { Container, VerticalDivider } from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu/styles";
import { PopoverId } from "@gemini-ui/components/Header/navigation/utils";
import { BASE_ZINDEX, BREAKPOINTS } from "@gemini-ui/components/Header/styles";
import { navigateToSubaccount } from "@gemini-ui/components/Header/utils";
import Popover from "@gemini-ui/components/Popover";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { usePageDataAndCallOnRefresh } from "@gemini-ui/contexts/PageData";
import { Button, Flex, HubbleButton } from "@gemini-ui/design-system";
import { useSubaccountWithBalance } from "@gemini-ui/hooks/useSubaccountWithBalance";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  enableDropdown?: boolean;
  fancyBorder?: boolean;
  handleError?: (err: Error) => void;
  needsActivateAccount: boolean;
  mobileMenuActive?: boolean;
}

const AccountSwitcherMenu = ({
  handleError,
  needsActivateAccount,
  enableDropdown = false,
  fancyBorder = false,
  mobileMenuActive = false,
}: Props) => {
  const { intl } = useIntl();

  const [displayMenu, setDisplayMenu] = useState(false);

  const {
    data: { results, currentPage, total },
    error,
    fetchSubaccounts,
    setParams,
    loading,
  } = useSubaccountWithBalance();

  const isPhone = useMedia(BREAKPOINTS.phoneOnly);
  const isDesktop = useMedia(BREAKPOINTS.desktop);

  const refreshSubaccounts = () => {
    if (displayMenu && total <= 7) {
      // Tick total account value provided the list is not scrollable (becuase it loses scroll pos on reload)
      fetchSubaccounts({ loading: false }); // dont show that its loading
    }
  };

  const {
    pageName,
    templateProps: {
      user: { subaccountHashid, subaccounts: templateSubaccounts },
    },
  } = usePageDataAndCallOnRefresh(refreshSubaccounts);

  const subaccounts = useMemo(() => {
    return [...results, ...(needsActivateAccount ? [FAKE_SUBBACCOUNT] : [])];
  }, [results, needsActivateAccount]);

  const handleMenuClick = () => {
    displayMenu === true && fetchSubaccounts();
    setDisplayMenu(p => !p);
  };

  const onSelectAccount = ({ hashid }: Subaccount) => {
    navigateToSubaccount({ hashid, subaccounts, pageName, handleError });
  };

  const showDropdown = enableDropdown && subaccounts.length >= 1;

  const defaultSubaccount = showDropdown
    ? { ...subaccounts[0], shortName: intl.formatMessage({ defaultMessage: "Select account" }) }
    : null;

  const selectedAccount = templateSubaccounts.find(acct => acct.hashid === subaccountHashid) || defaultSubaccount;

  const loadMore = () => {
    if (total > results.length) {
      setParams(prev => ({ ...prev, offset: results.length }));
    }
  };

  return (
    <Fragment>
      <Container
        id={PopoverId.ACCOUNT_SWITCHER}
        data-testid={PopoverId.ACCOUNT_SWITCHER}
        mobileMenuActive={mobileMenuActive}
      >
        <Global
          styles={css`
            [data-testid="HeaderLeftNav"] > a {
              ${displayMenu && `z-index: ${BASE_ZINDEX}`}
            }
          `}
        />
        {error ? (
          <Button.Secondary
            ml={3}
            size="sm"
            loading={loading}
            cta={intl.formatMessage({ defaultMessage: "Retry" })}
            onClick={() => fetchSubaccounts()}
          />
        ) : (
          selectedAccount && (
            <Media query={BREAKPOINTS.desktop}>
              {matches => (
                <Popover
                  hasBackdrop={!matches}
                  onClose={handleMenuClick}
                  isOpen={displayMenu}
                  placement="left-start"
                  portalRootSelector={`#${PopoverId.ACCOUNT_SWITCHER}`}
                  css={{
                    [`@media ${BREAKPOINTS.phoneOnly}`]: {
                      width: "100%",
                      transform: "none !important",
                    },
                  }}
                  renderComponent={
                    <AdvancedAccountSelector
                      subaccounts={subaccounts}
                      selectedAccount={selectedAccount}
                      loading={loading}
                      currentPage={total > results.length ? currentPage : 0} //
                      displayMenu={displayMenu}
                      handleMenuClick={handleMenuClick}
                      onSelectAccount={onSelectAccount}
                      fetchSubaccounts={fetchSubaccounts}
                      onAddAccount={handleMenuClick}
                      setParams={setParams}
                      loadMore={loadMore}
                    />
                  }
                >
                  <HubbleButton.Filter
                    cta={truncate(selectedAccount.name, { length: isPhone ? 16 : 20 })}
                    size="sm"
                    ml={!isPhone ? 2 : 1}
                    mr={isPhone && 1}
                    onClick={handleMenuClick}
                    data-state={displayMenu ? "on" : "off"}
                    rightElement={displayMenu ? <IconChevronUpSmall /> : <IconChevronDownSmall />}
                    data-testid="AccountSwitcherButton"
                  />
                </Popover>
              )}
            </Media>
          )
        )}
      </Container>
      {isDesktop && (
        <Flex alignItems="center" ml={2} mr={2}>
          <VerticalDivider />
        </Flex>
      )}
    </Fragment>
  );
};

export default AccountSwitcherMenu;
