import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { Button, SectionMessage } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

type Props = {
  "data-testid"?: string;
  refetchData: () => void;
  errorMessage?: string;
  loading?: boolean;
  pageName?: PageName;
};

export const LoadingErrorSection = ({
  refetchData,
  errorMessage,
  "data-testid": dataTestId,
  loading,
  pageName,
}: Props) => {
  const { intl } = useIntl();

  return (
    <SectionMessage
      data-testid={dataTestId || "section-message-loading-error"}
      statusType="alert"
      heading={intl.formatMessage({ defaultMessage: "Error" })}
      mb={pageName === PageName.AssetDetail ? 3 : 0}
      renderButton={
        <Button.Primary
          loading={loading}
          size="sm"
          cta={intl.formatMessage({ defaultMessage: "Try again" })}
          onClick={refetchData}
        />
      }
    >
      {errorMessage ||
        intl.formatMessage({
          defaultMessage: "An error occurred. Please try again and or contact customer support.",
        })}
    </SectionMessage>
  );
};
