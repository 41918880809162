import { useState } from "react";
import { useMedia } from "react-use";
import { TwoFactorAuthAnimation } from "@gemini-ui/components/TwoFactorAuth/TwoFactorAuthAnimation";
import { TwoFactorForm } from "@gemini-ui/components/TwoFactorAuth/TwoFactorForm";
import { CurrentMethodState, TwoFactorViewType } from "@gemini-ui/components/TwoFactorAuth/types";
import {
  getCurrentMethod,
  getFallbackType,
  getSubTitleCopy,
  getTitleCopy,
} from "@gemini-ui/components/TwoFactorAuth/utils";
import { Dialog, Flex, mediaQuery, Text } from "@gemini-ui/design-system";
import { AuthyModalProps, PageProps } from "@gemini-ui/pages/Authy/constants";
import OnboardingLayout from "@gemini-ui/pages/register/OnboardingLayout";
import { useIntl } from "@gemini-ui/utils/intl";

type PageLayoutProps = {
  type: "page";
} & PageProps;
type ModalLayoutProps = {
  type: "modal";
} & AuthyModalProps;

// remove page props once api is built for 2fa
export const TwoFactorAuth = (props: PageLayoutProps | ModalLayoutProps) => {
  const { intl } = useIntl();
  const isMobile = useMedia(mediaQuery.mobileXsDown);
  const {
    allowSmsFallback,
    allowsAuthyFallback,
    authRequest,
    sentSms,
    type,
    blankedPhone,
    rememberDuration,
    email,
    redirect,
  } = props;

  const hasCredentials = authRequest.assertion.assertion.publicKeyCredentialRequestOptions.allowCredentials.length > 0;

  const allowsFallback = allowsAuthyFallback || allowSmsFallback;
  const isSMS = allowSmsFallback || sentSms;
  const [currentMethod, setCurrentMethod] = useState<CurrentMethodState>(getCurrentMethod(hasCredentials, isSMS));

  const title = getTitleCopy(intl, currentMethod, blankedPhone);
  const subTitle = getSubTitleCopy(intl, currentMethod, type);

  if (type === "modal") {
    const { onSuccess, close } = props;

    return (
      <Dialog open onClose={close}>
        <Dialog.Container>
          <Dialog.Header>
            <Flex justifyContent="center" width="112%">
              {currentMethod === CurrentMethodState.Passkey && <TwoFactorAuthAnimation />}
            </Flex>
            <Dialog.Title>{title}</Dialog.Title>
            <Dialog.Description>{subTitle}</Dialog.Description>
          </Dialog.Header>
          <Dialog.Content>
            <TwoFactorForm
              authRequest={authRequest}
              allowsFallback={allowsFallback}
              onFallback={() => setCurrentMethod(getFallbackType(isSMS))}
              currentMethod={currentMethod}
              rememberDuration={rememberDuration}
              email={email}
              redirect={redirect}
              onSuccess={onSuccess}
              parentType={TwoFactorViewType.Dialog}
            />
          </Dialog.Content>
        </Dialog.Container>
      </Dialog>
    );
  }
  return (
    <OnboardingLayout customTitle="" showBackBtn>
      {currentMethod === CurrentMethodState.Passkey && !isMobile && <TwoFactorAuthAnimation width="75%" />}
      <Text.Heading size="lg" mt={1} data-testid="two-factor-auth-title" mb={1.5}>
        {title}
      </Text.Heading>
      <Text.Body size="sm" mt={1} mb={isMobile ? 4 : 0} data-testid="two-factor-auth">
        {subTitle}
      </Text.Body>
      {currentMethod === CurrentMethodState.Passkey && isMobile && <TwoFactorAuthAnimation />}
      <TwoFactorForm
        authRequest={authRequest}
        allowsFallback={allowsFallback}
        onFallback={() => setCurrentMethod(getFallbackType(isSMS))}
        currentMethod={currentMethod}
        rememberDuration={rememberDuration}
        email={email}
        redirect={redirect}
        parentType={TwoFactorViewType.TwoFactorPage}
      />
    </OnboardingLayout>
  );
};
