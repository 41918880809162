import { Component, ComponentType } from "react";
// eslint-disable-next-line no-restricted-imports
import axios from "axios";
import { Refresher } from "@gemini-ui/constants/refresher";
import { TemplateProps } from "@gemini-ui/constants/templateProps";
import { PageDataProvider, RefresherProvider } from "@gemini-ui/contexts";
import { GlobalModalProvider } from "@gemini-ui/contexts/GlobalModal";
import { InitialDataType } from "@gemini-ui/contexts/PageData";

interface ElementTypeProps {
  refresher: Refresher;
  templateProps: TemplateProps;
  [key: string]: any; // To allow spreading pageProps
}

interface AutoRefreshProps {
  ElementType: ComponentType<ElementTypeProps>;
  initialData: InitialDataType<unknown>;
}

interface AutoRefreshState {
  pageData: InitialDataType<{}>;
  refreshCount: number;
}

const STATUS = {
  manual: "manual",
  loading: "loading",
  error: "error",
} as const;

let status: keyof typeof STATUS = STATUS.manual;

class AutoRefresh extends Component<AutoRefreshProps, AutoRefreshState> {
  state: AutoRefreshState = {
    pageData: this.props.initialData,
    refreshCount: 0,
  };

  handleRequest = (fetch = true, callback) => {
    if (fetch && status !== STATUS.loading) {
      return this.refresh(callback);
    } else {
      return this.setState(state => ({ refreshCount: state.refreshCount + 1 }), callback);
    }
  };

  refresh = callback => {
    status = STATUS.loading;
    return axios
      .get(window.location.href, {
        timeout: 10000,
        headers: {
          Accept: "application/json",
          "Csrf-Token": "nocheck",
          "Refresh-Only": "refresh",
        },
      })
      .then(res => {
        status = STATUS.manual;
        return this.setState(state => ({ pageData: res.data, refreshCount: state.refreshCount + 1 }), callback);
      })
      .catch(_err => {
        status = STATUS.error;
        return this.forceUpdate();
      });
  };

  render() {
    const { pageData, refreshCount } = this.state;
    const { ElementType } = this.props;

    const refresher: Refresher = {
      requestRefresh: this.handleRequest,
    };

    return (
      <PageDataProvider pageData={pageData}>
        <RefresherProvider requestRefresh={this.handleRequest} refreshCount={refreshCount}>
          <GlobalModalProvider>
            <ElementType refresher={refresher} templateProps={pageData.templateProps} {...pageData.pageProps} />
          </GlobalModalProvider>
        </RefresherProvider>
      </PageDataProvider>
    );
  }
}

export default AutoRefresh;
