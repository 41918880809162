import { useEffect, useRef, useState } from "react";
import { Flex } from "@gemini-ui/design-system/Flex";
import { Text } from "@gemini-ui/design-system/Text";
import Tooltip from "@gemini-ui/design-system/Tooltip";
import { useWindowSize } from "@gemini-ui/design-system/utils/hooks";
import { LimitTextLengthProps } from "@gemini-ui/design-system/utils/LimitTextLength/constants";
import {
  FullHiddenText,
  LimitTextLengthWrapper,
  TrimmedWrapper,
} from "@gemini-ui/design-system/utils/LimitTextLength/styles";

export const LimitTextLength = ({
  text,
  maxWidth,
  finalTextLength,
  addEllipsis = true,
  addParentheses = true,
  hasTooltip = false,
  tooltipPlacement = "top",
  tooltipTestId = "limit-text-tooltip",
  middleTrim = false,
  lowercase = false,
  size = "xs",
}: LimitTextLengthProps) => {
  const hiddenRef = useRef(null);
  const windowSize = useWindowSize();
  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    if (hiddenRef.current) {
      setIsOverflowing(hiddenRef.current.scrollWidth > hiddenRef.current.clientWidth);
    }
  }, [windowSize, text]);
  const amountTrimmed = Math.floor(Math.min(15, text.length / 2));
  const trimmedText = isOverflowing ? text.slice(-amountTrimmed) : "";
  const needsToBeTrimmed = Boolean(finalTextLength) && finalTextLength < text.length;

  const getLengthLimitedText = () => {
    const slicedText = needsToBeTrimmed ? text.slice(0, finalTextLength) : text;
    if (!isOverflowing && !needsToBeTrimmed) return slicedText;
    const lengthLimitedText = slicedText + (addEllipsis ? "..." : "");
    const formattedText = addParentheses ? `(${lengthLimitedText})` : lengthLimitedText;

    return lowercase ? formattedText.toLowerCase() : formattedText;
  };

  const needsTooltip = hasTooltip && (isOverflowing || needsToBeTrimmed);

  return (
    <LimitTextLengthWrapper
      isOverflowing={isOverflowing}
      withFixedChars={Boolean(finalTextLength)}
      data-testid="LimitTextLengthWrapper"
    >
      <Tooltip
        maxWidth="unset"
        textAlign="center"
        data-testid={tooltipTestId}
        disabled={!needsTooltip}
        overlay={text}
        placement={tooltipPlacement}
        trigger={["hover"]}
      >
        <TrimmedWrapper maxWidth={maxWidth}>
          <FullHiddenText ref={hiddenRef}>{getLengthLimitedText()}</FullHiddenText>
          <Flex>
            <Text.Body style={{ textOverflow: addEllipsis ? "ellipsis" : "inherit" }} size={size}>
              {getLengthLimitedText()}
            </Text.Body>
            <Flex flex="1">{`${middleTrim ? trimmedText : ""}${addParentheses && isOverflowing ? ")" : ""}`}</Flex>
          </Flex>
        </TrimmedWrapper>
      </Tooltip>
    </LimitTextLengthWrapper>
  );
};
