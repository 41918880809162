import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Placement } from "popper.js";
import Popover from "@gemini-ui/components/Popover";
import { Colors, Spacer, Spacing } from "@gemini-ui/design-system";

const BASE_ZINDEX = 9990;

export const NavMenuContainer = styled("div")`
  position: relative;

  a:hover {
    color: ${({ theme }) => theme.colorScheme.content.primary};
  }
`;

export const IconPopover = styled(Popover)<{ placement?: Placement }>`
  top: ${Spacing.scale[2]} !important;
  ${p =>
    p.placement === "bottom-end" &&
    css`
      left: -${Spacing.scale[1]} !important;
    `}
`;

export const IconDropdownContainer = styled("ul")`
  min-width: 208px;
  background: ${Colors.black};
  z-index: ${BASE_ZINDEX + 1};
  box-shadow: 2px 2px 4px rgba(33, 40, 48, 0.5);
  border-radius: ${Spacing.scale[1]};
  overflow: hidden;
`;

export const DropdownItem = styled("li")<{ small?: boolean; copyright?: boolean; dropdown?: boolean }>`
  padding: ${Spacing.scale[1]} ${Spacing.scale[2]};
  color: ${Colors.gray[100]};
  font-size: ${p => (p.small ? "11px" : "12px")};
  font-weight: 600;
  cursor: ${p => (p.small ? "text" : "pointer")};
  user-select: none;

  &:first-of-type {
    border-bottom: 1px solid ${Colors.gray[800]};
    padding: ${Spacing.scale[2]} 0 ${Spacing.scale[1]};
    margin: 0 ${Spacing.scale[2]};
  }

  &:last-of-type {
    padding-bottom: ${Spacing.scale[2]};
  }

  &:hover {
    ${p =>
      !p.small &&
      !p.dropdown &&
      css`
        color: ${Colors.white};
        background: ${Colors.gray[900]};
      `}
  }

  ${p =>
    p.copyright &&
    `
    font-size: 8px;
    color: ${Colors.gray[600]};
    cursor: text;
    a {
      color: ${Colors.gray[200]};
    }
    `}
`;

export const ItemLink = styled("a")<{ active?: boolean }>`
  display: block;
  color: ${p => (p.active ? Colors.white : Colors.gray[100])};

  &:hover,
  &:focus,
  &:active {
    color: ${Colors.white};
  }
`;

export const ActiveBar = styled("div")`
  position: absolute;
  width: ${Spacing.scale[0.5]};
  margin-left: -15px;
  margin-top: -4px;
  height: ${Spacing.scale[3]};
  z-index: ${BASE_ZINDEX + 2};
  background: ${Colors.brandCyan};
`;

export const VerticalDivider = styled(Spacer)`
  border-right: 1px solid ${({ theme }) => theme.colorScheme.border.secondary};
  height: 24px;
`;
