// Value used for determining when the app should break out into
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameFiat } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { CurrencyBalances } from "@gemini-ui/constants/balances";
import { Subaccount } from "@gemini-ui/constants/templateProps/account";
import { Totals } from "@gemini-ui/pages/RetailTrade/constants";
import axios, { AxiosPromise, AxiosRequestConfig, ErrorCode, getDedupedRequest } from "@gemini-ui/services/axios";
import { handleNeedsRedirect } from "@gemini-ui/utils/error";

// number of accounts at which we change the UX to support pagination, virtual
// scroll etc
const MAX_SUBACCOUNTS = 10;

export function isMaxSubaccounts<T extends Subaccount>(accounts: T[]) {
  return accounts?.length >= MAX_SUBACCOUNTS;
}

export const DEBOUNCE_INTERVAL_MS = 250;

export interface SubaccountSearchParams {
  offset: number;
  limit: number;
  name?: string;
  sortAsc?: boolean;
  withBalance?: boolean;
  onlyTradingAccounts?: boolean;
  sortBy?: string;
}

export interface SubaccountSearchResponse<T extends Subaccount> {
  currentPage: number;
  limit: number;
  offset: number;
  sortAsc: boolean;
  total: number;
  totalPages: number;
  results: T[];
}

export interface CrossCollateralParams {
  maxLeverage: string;
  enableCrossCollateral: string;
  enableNegativeBalances: string;
}

export interface SubaccountWithBalances extends Subaccount {
  type?: string;
  balances?: CurrencyBalances;
  notional?: MoneyProps<CurrencyShortNameFiat>;
  totals?: Totals;
  crossCollateralParams?: CrossCollateralParams;
}

type GetRequestType =
  | ((url: string, config: AxiosRequestConfig) => AxiosPromise)
  | ((url: string, config?: AxiosRequestConfig) => AxiosPromise);

const dedupedGet = getDedupedRequest("GET");

export function searchSubaccounts<T extends Subaccount>(
  params: SubaccountSearchParams,
  axiosParams?: AxiosRequestConfig,
  hasDedupedGet?: boolean
): Promise<SubaccountSearchResponse<T>> {
  const getRequest: GetRequestType = hasDedupedGet ? dedupedGet : axios.get;
  return getRequest(`/user/sub-accounts`, { params, withCredentials: true, ...axiosParams }).then(resp => resp.data);
}

export const fetchSubaccountsWithBalances = (
  optionalParams?: Partial<SubaccountSearchParams>,
  axiosParams?: AxiosRequestConfig,
  limit?: number,
  hasDedupedGet?: boolean
): Promise<SubaccountSearchResponse<SubaccountWithBalances>> => {
  return searchSubaccounts<SubaccountWithBalances>(
    {
      limit: limit || 10,
      offset: 0,
      withBalance: true,
      ...optionalParams,
    },
    axiosParams,
    hasDedupedGet
  )
    .then(response => response)
    .catch(error => {
      if (error?.code !== ErrorCode.CANCEL_ERROR) {
        Sentry.captureException(error);
      }
      handleNeedsRedirect(error);
      throw error;
    });
};
