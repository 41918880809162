import styled from "@emotion/styled";
import { BASE_ZINDEX } from "@gemini-ui/components/Header/styles";
import { border, Button, mediaQuery, Spacing, Text } from "@gemini-ui/design-system";

export const CenteredText = styled(Text.Body)`
  text-align: center;
  color: ${({ theme }) => theme.colorScheme.content.secondary};
`;

export const Panel = styled.div<{ customLayoutsEnabled: boolean }>`
  background: ${({ theme }) => theme.colorScheme.elevated.background.modal};
  border-radius: ${border.radius.lg};
  border: 1px solid ${({ theme }) => theme.colorScheme.elevated.background.menu};
  bottom: 16px;
  box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  max-height: 705px;
  position: fixed;
  right: ${Spacing.scale[2]};
  top: 56px;
  width: 375px;
  z-index: ${BASE_ZINDEX + 1};
  overflow: hidden;

  @media ${mediaQuery.tabletMdUp} {
    right: ${({ customLayoutsEnabled }) => (customLayoutsEnabled ? "210px" : Spacing.scale[12])};
  }

  @media ${mediaQuery.tabletMdDown} {
    width: 346px;
  }
`;

export const ScrollContainer = styled.div`
  padding-top: ${Spacing.scale[3]};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FlexButton = styled(Button.Secondary)`
  display: flex;
`;
