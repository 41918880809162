import { IconArrowsHorizontal } from "@hubble/icons";
import {
  TRANSFER_BUTTON_ID,
  TransferButtonProps,
} from "@gemini-ui/components/Header/navigation/TransferButton/constants";
import { HubbleButton } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

const TransferButton = ({ onClick }: TransferButtonProps) => {
  const { intl } = useIntl();

  return (
    <HubbleButton.Tertiary
      data-id={TRANSFER_BUTTON_ID}
      data-testid={TRANSFER_BUTTON_ID}
      aria-label={intl.formatMessage({ defaultMessage: "Transfer header button" })}
      size="sm"
      cta={intl.formatMessage({ defaultMessage: "Transfer" })}
      leftElement={<IconArrowsHorizontal />}
      mr={0.5}
      onClick={onClick}
    />
  );
};

export default TransferButton;
