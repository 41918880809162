import { IconProps } from "@hubble/icons/web";
import { ShorthandSpacingCssProps } from "@gemini-ui/design-system/primitives/Spacer";
import { AxiosRequestConfig } from "@gemini-ui/services/axios";

export interface FileUploaderProps extends ShorthandSpacingCssProps {
  accept?: string;
  className?: string;
  "data-testid": string;
  disabled?: boolean;
  error?: React.ReactNode;
  id?: string;
  isMulti?: boolean;
  label?: React.ReactNode;
  // used as aria-label for a11y
  labelDescription: string;
  maxFiles?: number;
  maxSize?: number;
  maxPolicySize: number;
  message?: React.ReactNode;
  onFileChange?: (value: StoredFile | StoredFile[]) => void;
  onRemove?: (value: StoredFile) => void;
  readOnly?: boolean;
  uploadFile: (file: File, axiosConfig?: AxiosRequestConfig) => Promise<StoredFile>;
  value?: StoredFile | StoredFile[];
}

export type StoredFile = {
  container: string;
  url: string;
  size: number;
  type: string;
  name: string;
  key: string;
  handle: string;
};

export type FileUploaderFile<T> = {
  file: T;
  error?: string;
  id: string;
};

export interface FileProps extends ShorthandSpacingCssProps {
  "data-testid": string;
  error?: React.ReactNode;
  file: File | StoredFile;
  maxSize?: number;
  message?: string;
  onUpload?: (file: StoredFile) => void;
  onRemove?: () => void;
  readOnly?: boolean;
  uploadFile: (file: File, axiosConfig?: AxiosRequestConfig) => Promise<StoredFile>;
}

export interface DropzoneProps {
  disabled?: boolean;
  error?: React.ReactNode;
  onClick: () => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  "data-testid": string;
}

export type CircleVariation = "base" | "success" | "loading";

export interface CircleProps {
  size: number;
  variation: CircleVariation;
  percentage?: number;
}

export interface LoaderProps {
  size: number;
  percentage: number;
}

export interface CircularProgressProps {
  size?: number;
  percentage: number;
}

export interface CheckProps {
  percentage: number;
}

export interface FileIconProps {
  extension: string;
}

export interface IconInfo {
  icon: React.ReactElement<IconProps>;
  color: string;
  extensions?: string[];
}

export const AUDIO_EXTENSIONS: string[] = [
  "3gp",
  "aa",
  "aac",
  "aax",
  "act",
  "aiff",
  "alac",
  "ape",
  "au",
  "awb",
  "dss",
  "dvf",
  "flac",
  "gsm",
  "iklax",
  "ivs",
  "m4a",
  "m4b",
  "m4b",
  "mmf",
  "mp3",
  "msv",
  "mpc",
  "nmf",
  "oog",
  "oga",
  "opus",
  "ra",
  "rm",
  "rf64",
  "sln",
  "tta",
  "voc",
  "vox",
  "wav",
  "wma",
  "wv",
  "webm",
  "8svx",
  "cda",
];

export const IMAGE_EXTENSIONS: string[] = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "webp",
  "tiff",
  "psd",
  "raw",
  "bmp",
  "heif",
  "bmp",
  "psd",
  "indd",
  "svg",
  "ai",
  "eps",
];

export const VIDEO_EXTENSIONS: string[] = [
  "mp4",
  "mov",
  "wmv",
  "avi",
  "avchd",
  "flv",
  "f4v",
  "swf",
  "mkv",
  "webm",
  "mpg",
  "mpeg",
  "m2v",
  "vob",
  "gifv",
  "qt",
  "amv",
];
export const PRESENTATION_EXTENSIONS: string[] = [
  "pptx",
  "pptm",
  "ppt",
  "potx",
  "potm",
  "pot",
  "thmx",
  "ppsx",
  "ppsm",
  "pps",
];
export const STYLESHEET_EXTENSIONS: string[] = ["xls", "xlsx", "ods", "sxc", "dif", "dat", "csv", "tsv", "numbers"];
export const PDF_EXTENSIONS: string[] = ["pdf"];
export const DOCUMENT_EXTENSIONS: string[] = ["doc", "docx", "dot", "dotm", "dotx"];
