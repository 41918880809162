import styled from "@emotion/styled";
import { FormElementSize } from "@gemini-ui/design-system/forms/shared/constants";
import {
  FORM_ELEMENT_TRANSITION_VALUE,
  getFontSize,
  getNumericLetterSpacing,
  getSize,
} from "@gemini-ui/design-system/forms/shared/utils";
import { border, Spacing } from "@gemini-ui/design-system/primitives";

export const InputWrapper = styled.div<{
  hasError?: boolean;
  inputSize: FormElementSize;
  isDisabled?: boolean;
  isReadOnly?: boolean;
}>`
  align-items: center;
  border-radius: ${border.radius.md};
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  display: grid;
  flex: 1;
  font-size: ${({ inputSize }) => getFontSize(inputSize)};
  grid-template-areas: "left-content input right-content";
  grid-template-columns: min-content 1fr min-content;
  height: ${({ inputSize }) => getSize(inputSize)};
  outline: none;
  padding: 0 ${Spacing.scale[2]};
  transition: ${FORM_ELEMENT_TRANSITION_VALUE};

  background-color: ${({ hasError, isReadOnly, isDisabled, theme }) => {
    if (isDisabled || isReadOnly) return theme.colorScheme.input.background.disabled;
    if (hasError) return theme.colorScheme.status.default.background.negative;

    return theme.colorScheme.input.background.enabled;
  }};

  border-color: ${({ hasError, isReadOnly, isDisabled, theme }) => {
    if (isDisabled || isReadOnly) return theme.colorScheme.input.border.disabled;
    if (hasError) return theme.colorScheme.status.default.border.negative;

    return theme.colorScheme.input.border.enabled;
  }};

  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.colorScheme.content.disabled : theme.colorScheme.content.primary};

  :hover {
    border-color: ${({ isDisabled, isReadOnly, hasError, theme }) =>
      !isDisabled && !isReadOnly && !hasError ? theme.colorScheme.input.border.hover : undefined};
  }

  :focus-within {
    border-color: ${({ hasError, theme }) => (!hasError ? theme.colorScheme.input.border.focus : undefined)};
    box-shadow: 0 0 0 1px
      ${({ hasError, theme }) =>
        hasError ? theme.colorScheme.status.default.border.negative : theme.colorScheme.input.border.focus};
  }
`;

export const LeftElementWrapper = styled.span`
  align-items: center;
  display: flex;
  grid-area: left-content;
  padding-right: ${Spacing.scale[1]};
  height: 100%;
`;

export const RightElementWrapper = styled.span`
  align-items: center;
  display: flex;
  grid-area: right-content;
  padding-left: ${Spacing.scale[1]};
  height: 100%;
`;

export const StyledInput = styled.input<{ inputSize?: FormElementSize; numeric?: boolean }>`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-size: ${({ inputSize }) => getFontSize(inputSize)};
  grid-area: input;
  height: 100%;
  padding: 0;
  width: 100%;
  outline: none;
  font-variant-ligatures: none;

  ${({ numeric, inputSize }) =>
    numeric &&
    `
    font-feature-settings: "tnum" on, "lnum" on;
    letter-spacing: ${getNumericLetterSpacing(inputSize)};
  `}

  ::placeholder {
    color: ${({ theme }) => theme.colorScheme.input.content.placeholder};
  }

  :disabled::placeholder {
    color: ${({ theme }) => theme.colorScheme.content.disabled};
  }
`;
