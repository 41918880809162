import {
  CREDIT_CARD_ACTIVATE_CARD_EVENTS,
  CREDIT_CARD_DASHBOARD_CARD_TILE_V2,
  CREDIT_CARD_DISPUTES_EVENTS,
  CREDIT_CARD_EVENTS,
  CREDIT_CARD_EVENTS_V2,
  CREDIT_CARD_HOMEPAGE_EVENTS,
  CREDIT_CARD_INTRO_MODAL_EVENTS,
  CREDIT_CARD_PAYMENT_EVENTS,
  CREDIT_CARD_REWARD_DISPUTES_EVENTS,
  CREDIT_CARD_WARM_WELCOME_EVENTS,
} from "@gemini-ui/analytics/constants/creditCard";
import { PEOPLE_PROPERTIES } from "@gemini-ui/analytics/constants/trade";

export const PAGE_NAME = {
  BANK_SETTINGS: "Bank Settings",
  RETAIL_BUY: "Retail Buy",
  EARN_DEPOSIT: "Earn Deposit",
  DASHBOARD: "Dashboard",
  CASH_DEPOSIT: "Cash deposit",
  PORTFOLIO_PAGE: "Portfolio Page",
  CASH_WITHDRAW: "Cash withdraw",
} as const;

export const PEOPLE = {
  UNIQUE_ID: "Unique Identifier",
  CREATION_DATE: "Account Creation Date",
  ACCOUNT_TYPE: "Account Type",
  COUNTRY: "Country",
  STATE: "State",
  EMAIL_CONFIRMED: "Email Confirmed?",
  PHONE_CONFIRMED: "Phone Number Confirmed?",
  AUTHY_ENABLED: "Authy 2FA enabled?",
  REGISTRATION_DATE: "Registration Date",
  BANK_ADDED: "Bank Account Added?",
  BANK_ADDED_THROUGH_PLAID: "Bank Added through Plaid?",
  LAST_WITHDRAWAL_DATE: "Last Withdrawal Date",
  ACTIVE_TRADER_ENABLED: "ActiveTrader Enabled",
  BRAZE_EXTERNAL_ID: "$braze_external_id",
  EXCHANGE_INTERFACE: "Exchange Interface",
  NUMBER_OF_ALERTS: "# of Alerts",
  REGISTERED_FROM_CREDIT: "Registered from Credit",
  ...PEOPLE_PROPERTIES,
} as const;
type PeoplePropertyType = typeof PEOPLE;
type PeopleKeys = keyof PeoplePropertyType;
export type PeoplePropertyName = PeoplePropertyType[PeopleKeys];
export type PeopleProperty = {
  [peoplePropertyName in PeoplePropertyName]?: AnalyticsPropertyValue;
};

export const UTM_PROPERTIES = {
  CONTENT_LAST_TOUCH: "UTM Content [Last Touch]",
  TERM_LAST_TOUCH: "UTM Term [Last Touch]",
  CAMPAIGN_FIRST_TOUCH: "UTM Campaign [First Touch]",
  CAMPAIGN_LAST_TOUCH: "UTM Campaign [Last Touch]",
  MEDIUM_FIRST_TOUCH: "UTM Medium [First Touch]",
  MEDIUM_LAST_TOUCH: "UTM Medium [Last Touch]",
  SOURCE_FIRST_TOUCH: "UTM Source [First Touch]",
  SOURCE_LAST_TOUCH: "UTM Source [Last Touch]",
  AD_GROUP_FIRST_TOUCH: "UTM Ad Group [First Touch]",
  AD_GROUP_LAST_TOUCH: "UTM Ad Group [Last Touch]",
  AD_GROUP_ID_FIRST_TOUCH: "UTM Ad Group ID [First Touch]",
  AD_GROUP_ID_LAST_TOUCH: "UTM Ad Group ID [Last Touch]",
  CAMPAIGN_ID_FIRST_TOUCH: "UTM Campaign ID [First Touch]",
  CAMPAIGN_ID_LAST_TOUCH: "UTM Campaign ID [Last Touch]",
  COUNTRY_FIRST_TOUCH: "UTM Country [First Touch]",
  COUNTRY_LAST_TOUCH: "UTM Country [Last Touch]",
  CREATIVE_FIRST_TOUCH: "UTM Creative [First Touch]",
  CREATIVE_LAST_TOUCH: "UTM Creative [Last Touch]",
  CREATIVE_ID_FIRST_TOUCH: "UTM Creative ID [First Touch]",
  CREATIVE_ID_LAST_TOUCH: "UTM Creative ID [Last Touch]",
} as const;

export const UTM_PARAMETERS = {
  UTM_SOURCE: "utm_source",
  UTM_MEDIUM: "utm_medium",
  UTM_CAMPAIGN: "utm_campaign",
  UTM_TERM: "utm_term",
  UTM_CONTENT: "utm_content",
  UTM_ADGROUP: "utm_adgroup",
  UTM_ADGROUP_ID: "utm_adgroupID",
  UTM_CAMPAIGN_ID: "utm_campaignID",
  UTM_COUNTRY: "utm_country",
  UTM_CREATIVE: "utm_creative",
  UTM_CREATIVE_ID: "utm_creativeID",
} as const;

// Leaving this here so we have the boilerplate to add super properties in later
export const SUPER_PROPERTIES = {
  EXPERIMENT_NAME: "Experiment Name",
  VARIANT_NAME: "Variant Name",
  NUMBER_OF_TRADES_PREVIOUSLY_PLACED: "# of Trades Previously Placed",
  EXCHANGE_INTERFACE: "Exchange Interface",
} as const;
type SuperPropertyType = typeof SUPER_PROPERTIES;
export type SuperPropertyName = SuperPropertyType[keyof SuperPropertyType];
export type SuperProperty = {
  [superPropertyName in SuperPropertyName]?: AnalyticsPropertyValue;
};

const DOCUMENT_PROPERTIES = {
  FILE_SIZE: "File Size",
  FILE_TYPE: "File Type",
  WEBCAM: "Webcam?",
  DOCUMENT_TYPE: "Document Type",
} as const;

const PLAID_METADATA = {
  LINK_SESSION_ID: "Link Session ID",
  MFA_TYPE: "MFA Type",
  REQUEST_ID: "Request ID",
  VIEW_NAME: "View Name",
  ERROR_CODE: "Error Code",
  ERROR_MESSAGE: "Error Message",
  ERROR_TYPE: "Error Type",
  EXIT_STATUS: "Exit Status",
  INSTITUTION_ID: "Institution ID",
  INSTITUTION_NAME: "Institution Name",
  INSTITUTION_SEARCH_QUERY: "Institution Search Query",
  TIMESTAMP: "Timestamp",
} as const;

// New events for payments team data-cleanup
const FIAT_TRANSFER_EVENTS = {
  TRANSFER_BETWEEN_START: {
    name: "Transfer Between Accounts - Start",
    properties: {
      INITIATED_FROM: "Initiated from",
    },
  },
  WITHDRAW_START: {
    name: "Withdraw - Start",
    properties: {
      INITIATED_FROM: "Initiated from",
    },
  },
  DEPOSIT_START: {
    name: "Deposit - Start",
    properties: {
      INITIATED_FROM: "Initiated from",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_CONFIRM: {
    name: "Deposit - Confirm",
    properties: {
      DEPOSIT_TYPE: "Deposit type",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_SUCCESSFUL: {
    name: "Deposit - Successful",
    properties: {
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_FAILURE: {
    name: "Deposit - Failure",
    properties: {
      FAILURE_TYPE: "Failure type",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_SELECT_CURRENCY: {
    name: "Deposit - Select currency",
    properties: {
      CURRENCY: "Currency",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_SELECT_PAYMENT_METHOD: {
    name: "Deposit - Select payment method",
    properties: {
      TRANSFER_TYPE: "Transfer type",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_ENTER_AMOUNT: {
    name: "Deposit - Enter amount",
    properties: {
      DEPOSIT_AMOUNT: "Deposit amount",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_SET_AMOUNT: {
    name: "Deposit - Set amount",
    properties: {
      DEPOSIT_SET_AMOUNT: "Set amount",
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_VIEW_WIRE_INSTRUCTIONS: {
    name: "Deposit - View wire instructions",
    properties: {
      JOURNEY_TYPE: "Journey Type",
    },
  },
  DEPOSIT_VIEW_CBIT_INSTRUCTIONS: {
    name: "Deposit - View cbit instructions",
  },
  DEPOSIT_EDIT: {
    name: "Deposit - Edit",
    properties: {
      STEP: "Step",
    },
  },
  WITHDRAW_EDIT: {
    name: "Withdraw - Edit",
    properties: {
      STEP: "Step",
    },
  },
  WITHDRAW_FIAT_SELECT_CURRENCY: {
    name: "Withdraw – Fiat select currency", // user selects currency and clicks continue button to next step
    properties: {
      CURRENCY: "Currency",
    },
  },
  WITHDRAW_AMOUNT_VALIDATION_ERROR: {
    name: "Withdraw - Amount Validation error",
    properties: {
      ERROR: "error",
    },
  },
  WITHDRAW_FIAT_SELECT_DESTINATION: {
    name: "Withdraw - Fiat select destination", // User has Fiat destination and clicks continue button to next step
    properties: {
      TRANSFER_MECHANISM: "Transfer mechanism",
    },
  },
  WITHDRAW_FIAT_AMOUNT_CONFIRMED: {
    name: "Withdraw - Fiat confirmed amount", // User has entered amount successfully and clicks continue button to next step
    properties: {
      AMOUNT: "Amount",
    },
  },
  WITHDRAW_FIAT_SUCCESS: {
    name: "Withdraw - Fiat withdrawal success", // Withdraw success returned from API
  },
  WITHDRAW_FIAT_ERROR: {
    name: "Withdraw - Fiat withdrawal error", // Withdraw error returned from API
    properties: {
      ERROR: "Error",
    },
  },
  TRANSFER_BETWEEN_SUCCESS: {
    name: "Transfer Between Accounts - Success", // Transfer between accounts complete
    properties: {
      CURRENCY: "Currency",
    },
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_START: {
    name: "Derivative Debit Card Funding - Start",
    properties: {
      INITIATED_FROM: "Initiated from",
    },
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_SELECT_PAYMENT_METHOD: {
    name: "Derivative Debit Card Funding - Select payment method",
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_ADD_PAYMENT_METHOD: {
    name: "Derivative Debit Card Funding - Add payment method",
    properties: {
      INITATED_FROM: "Initiated from",
    },
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_SELECT_CURRENCY: {
    name: "Derivative Debit Card Funding - Select currency",
    properties: {
      CURRENCY: "Currency",
    },
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_CONFIRM: {
    name: "Derivative Debit Card Funding - Confirm",
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_CONVERSION_FAILURE: {
    name: "Derivative Debit Card Funding - Conversion Failure",
    properties: {
      ERROR: "Error",
    },
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_TRANSFER_FAILURE: {
    name: "Derivative Debit Card Funding - Transfer Failure",
    properties: {
      ERROR: "Error",
    },
  },
  DERIVATIVE_DEBIT_CARD_FUNDING_SUCCESS: {
    name: "Derivative Debit Card Funding - Success",
  },
  PERPS_TRANSFER_START: {
    name: "Perps Transfer - Start",
    properties: {
      INITIATED_FROM: "Initiated from",
    },
  },
  PERPS_TRANSFER_SELECT_CURRENCY: {
    name: "Perps Transfer - Select currency",
    properties: {
      CURRENCY: "Currency",
    },
  },
  PERPS_TRANSFER_SUCCESS: {
    name: "Perps Transfer - Success",
  },
  PERPS_TRANSFER_FAIL: {
    name: "Perps Transfer - Fail",
  },
  PERPS_TRANSFER_CONVERSION_FAIL: {
    name: "Perps Transfer - Conversion Fail",
  },
  PERPS_TRANSFER_DEVICE_AUTH_ERROR: {
    name: "Perps Transfer - Device Auth Error",
  },
  PERPS_TRANSFER_LIQUIDITY_ERROR: {
    name: "Perps Transfer - Liquidity Error",
    properties: {
      ERROR: "Error",
    },
  },
};

const CRYPTO_TRANSFER_EVENTS = {
  WITHDRAW_CRYPTO_START: {
    name: "Withdraw - Crypto start withdrawal", // Send crypto is clicked in header or elsewhere
    properties: {
      INITIATED_FROM: "Initiated from",
    },
  },
  WITHDRAW_CRYPTO_SELECT_CURRENCY: {
    name: "Withdraw – Crypto select currency", // User selects currency and clicks continue button to next step
    properties: {
      CURRENCY: "Currency",
    },
  },
  WITHDRAW_CRYPTO_SELECT_DESTINATION: {
    name: "Withdraw - Crypto select destination", // User has crypto destination and clicks continue button to next step
    properties: {
      TRANSFER_MECHANISM: "Transfer mechanism",
    },
  },
  WITHDRAW_CRYPTO_AMOUNT_CONFIRMED: {
    name: "Withdraw - Crypto confirmed amount", // User has entered amount successfully and clicks continue button to next step
    properties: {
      AMOUNT: "Amount",
    },
  },
  WITHDRAW_CRYPTO_SUBMIT: {
    name: "Withdraw - Crypto submit withdrawal", // Submit withdraw button clicked.
    properties: {
      AMOUNT: "Amount",
      CURRENCY: "Currency",
    },
  },
  WITHDRAW_CRYPTO_SUCCESS: {
    name: "Withdraw - Crypto withdrawal success", // Withdraw success returned from API
  },
  WITHDRAW_CRYPTO_ERROR: {
    name: "Withdraw - Crypto withdrawal error", // Withdraw error returned from API
    properties: {
      ERROR: "Error",
    },
  },
  WITHDRAW_UK_REQUIRES_ATTESTATION: {
    name: "Withdraw - Crypto UK requires attestation", // UK user enters a destination address that requires an attestation
    properties: {
      CHAIN_NETWORK: "Chain network",
      CHAIN_ADDRESS: "Chain address",
    },
  },
  WITHDRAW_UK_ATTESTATION_COMPLETED: {
    name: "Withdraw - Crypto UK attestation submitted successfully", // UK user completed an attestation
    properties: {
      CHAIN_NETWORK: "Chain network",
      CHAIN_ADDRESS: "Chain address",
    },
  },
  WITHDRAW_UK_ATTESTATION_FAILED: {
    name: "Withdraw - Crypto UK attestation failed to submit", // UK user wasn't able to complete an attestation
    properties: {
      CHAIN_NETWORK: "Chain network",
      CHAIN_ADDRESS: "Chain address",
    },
  },
  WITHDRAW_REQUIRES_ATTESTATION: {
    name: "Withdraw - Crypto Jurisdiction requires attestation", // Jurisdiction user enters a destination address that requires an attestation
    properties: {
      CHAIN_NETWORK: "Chain network",
      CHAIN_ADDRESS: "Chain address",
      JURISDICTION: "Jurisdiction",
    },
  },
  WITHDRAW_ATTESTATION_COMPLETED: {
    name: "Withdraw - Crypto Jurisdiction attestation submitted successfully", // Jurisdiction user completed an attestation
    properties: {
      CHAIN_NETWORK: "Chain network",
      CHAIN_ADDRESS: "Chain address",
      JURISDICTION: "Jurisdiction",
    },
  },
  WITHDRAW_ATTESTATION_FAILED: {
    name: "Withdraw - Crypto Jurisdiction attestation failed to submit", // Jurisdiction user wasn't able to complete an attestation
    properties: {
      CHAIN_NETWORK: "Chain network",
      CHAIN_ADDRESS: "Chain address",
      JURISDICTION: "Jurisdiction",
    },
  },
  WITHDRAW_CRYPTO_STEPUP_CHALLENGE: {
    name: "Withdraw - Crypto stepup challenge initiated", // Stepup challenge invoked on 401 error when submitting withdrawal
    properties: {
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_SELFIE_SUBMITTED: {
    name: "Withdraw - Crypto selfie submitted", // Selfie submitted when challenged on crypto withdrawal
    properties: { CHALLENGE_ID: "Challenge ID" },
  },
  WITHDRAW_CRYPTO_CHALLENGE_SUCCESS: {
    name: "Withdraw - Crypto stepup challenge success", // Stepup challenge success after submitting selfie on crypto withdrawal
    properties: { CHALLENGE_ID: "Challenge ID" },
  },
  WITHDRAW_CRYPTO_CHALLENGE_ERROR: {
    name: "Withdraw - Crypto stepup challenge error", // Stepup challenge error after submitting selfie on crypto withdrawal
    properties: {
      STATUS: "Status",
      MESSAGE: "Message",
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_CHALLENGE_RESET: {
    name: "Withdraw - Crypto stepup challenge reset", // Stepup challenge reset after submitting selfie on crypto withdrawal
    properties: {
      REASON: "Reason",
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_CHALLENGE_TIME_LIMIT_EXCEEDED: {
    name: "Withdraw - Crypto Get Status Time Limit Exceeded", // Stepup challenge polling did not return with Ok or Error within the time limit. Indicates that the challenge remained in Processing status without an update
    properties: {
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_FLOW_INITIATED: {
    name: "Withdraw - Crypto permanent flow cwb initiated",
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_STEPUP_CHALLENGE: {
    name: "Withdraw - Crypto permanent cwb stepup challenge initiated", // Stepup challenge invoked on 401 error when submitting withdrawal in permanent cwb
    properties: {
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_SELFIE_SUBMITTED: {
    name: "Withdraw - Crypto permanent cwb selfie submitted", // Selfie submitted when challenged on crypto withdrawal in permanent cwb
    properties: { CHALLENGE_ID: "Challenge ID" },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_STEPUP_SUCCESS: {
    name: "Withdraw - Crypto permanent cwb stepup challenge success", // Stepup challenge success after submitting selfie on crypto withdrawal in permanent cwb
    properties: { CHALLENGE_ID: "Challenge ID" },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_STEPUP_ERROR: {
    name: "Withdraw - Crypto permanent cwb stepup challenge error", // Stepup challenge error after submitting selfie on crypto withdrawal in permanent cwb
    properties: {
      STATUS: "Status",
      MESSAGE: "Message",
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_STEPUP_RESET: {
    name: "Withdraw - Crypto permanent cwb stepup challenge reset", // Stepup challenge reset after submitting selfie on crypto withdrawal in permanent cwb
    properties: {
      REASON: "Reason",
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_IDENTIFIED: {
    name: "Withdraw - Crypto permanent cwb identified",
    properties: {
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_INITIATED: {
    name: "Withdraw - Crypto permanent cwb initiated",
    properties: {
      CHALLENGE_ID: "Challenge ID",
    },
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_UNDER_REVIEW: {
    name: "Withdraw - Crypto permanent cwb under review",
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_FORM_INITIATED: {
    name: "Withdraw - Crypto permanent cwb form initiated",
  },
  WITHDRAW_CRYPTO_PERMANENT_CWB_FORM_SUBMITTED: {
    name: "Withdraw - Crypto permanent cwb form submitted",
  },
  DEPOSIT_CRYPTO_START: {
    name: "Withdraw - Crypto start deposit", // Receive crypto is clicked in header or elsewhere
    properties: {
      INITIATED_FROM: "Initiated from",
    },
  },
  DEPOSIT_VIEW_DEPOSIT_ADDRESS: {
    name: "Deposit - View deposit address", // Deposit address is shown
  },
};

const WITHDRAWAL_PROPERTIES = {
  CURRENCY: "Currency",
  AMOUNT: "Amount",
} as const;

const REWARD_HUB_EVENTS = {
  REWARD_PAYOUT_TOKEN_CHANGED: {
    name: "Rewards Payout Token Changed",
    properties: {
      CURRENCY: "Currency",
    },
  },
  REWARD_ENTRY_POINT: {
    name: "Rewards Hub Entrypoint",
    properties: {
      INITIATED_FROM: "Initiated from", // Header, Mobile web header, Homepage referral card
    },
  },
  REFERRAL_DIRECT_INVITE_PRESSED: {
    name: "Referral Direct Invite Pressed",
    properties: {
      INITIATED_FROM: "Initiated from", // Latest rewards, Email, Homepage referral card, Rewards hub referral card, Reward club informational modal
      CURRENT_MODAL_LEVEL: "Current modal level", // when clicked from Reward club informational modal
    },
  },
  REFERRAL_INVITE_SENT: {
    name: "Referral invite sent",
  },
  REFERRAL_LINK_COPIED: {
    name: "Referral link copied",
    properties: {
      INITIATED_FROM: "Initiated from", // Share screen, Homepage referral card, Rewards hub referral card
    },
  },
  REFEREE_DETAILS_VIEWED: {
    name: "Referee details viewed",
    properties: {
      INITIATED_FROM: "Initiated from", // pending table, referral club table
    },
  },
  REWARD_HUB_OPEN_CREDIT_CARD: {
    name: "Reward Hub Open Credit Card",
  },
  REWARD_HUB_FINISH_VERIFICATION: {
    name: "Reward Hub Finish Verification",
  },
  REWARD_HUB_DISMISS_FINISH_VERIFICATION: {
    name: "Reward Hub Dismiss Finish Verification",
  },
  REWARD_HUB_DISMISS_PENDING_VERIFICATION: {
    name: "Reward Hub Dismiss Pending Verification",
  },
  REFERRAL_STATUS_CHANGED: {
    name: "Referral Status Changed",
    properties: {
      PREVIOUS_STATUS: "Previous Status",
      NEW_STATUS: "New Status",
    },
  },
  OPEN_REFERRAL_CLUB_INFORMATIONAL_MODAL: {
    name: "Opened referral club informational modal",
    properties: {
      INITIATED_FROM: "Initiated from", // Homepage referral card, Rewards hub referral card
    },
  },
  CLOSE_REFERRAL_CLUB_INFORMATIONAL_MODAL: {
    name: "Closed referral club informational modal",
    properties: {
      CURRENT_MODAL_LEVEL: "Current modal level",
    },
  },
  REFERRAL_PROGRESS_BAR_CTA: {
    name: "Clicked referee progress bar",
    properties: {
      CURRENT_CLUB_LEVEL: "Current club level",
    },
  },
  REFERRAL_CLUB_TRADE_CTA: {
    name: "Clicked trade from the referee progress bar modal",
    properties: {
      CURRENT_MODAL_LEVEL: "Current modal level",
    },
  },
  REFERRAL_CLUB_INFO_MODAL_STEP_CHANGED: {
    name: "Navigated to another club level step in the referral club modal",
    properties: {
      CURRENT_MODAL_LEVEL: "Current modal level",
    },
  },
};
// Could eventually split these out into their own files
const ONBOARDING_EVENTS = {
  START_REGISTRATION: {
    name: "Start Registration",
  },
  CHECK_LOCATION: {
    name: "Check Location",
    properties: {
      LOCATION_STATUS: "Location Operational Status",
      OPT_IN: "Opt in for Follow-up Email?",
      COUNTRY: "Country",
      STATE: "State",
    },
  },
  CHANGE_LOCATION: {
    name: "Change Location",
    properties: {
      LOCATION_STATUS: "Location Operational Status",
      OPT_IN: "Opt in for Follow-up Email?",
      GUESSED_LOCATION: "Detected Country Location",
      CHANGED_LOCATION: "Changed Country Location",
      STATE: "State",
    },
  },
  CONFIRM_EMAIL: "Confirm Email",
  REGISTER_EMAIL: {
    name: "Register Email",
    properties: {
      CREATION_DATE: "Account Creation Date",
    },
  },
  REGISTER_CONFIRM_EMAIL_FROM_REGISTER: {
    name: "Register Confirm Email From Register",
  },
  SUBMIT_KYC_REFRESH_SUCCESS: {
    name: "Submit KYC Refresh Success",
  },
  CONFIRM_PHONE: "Confirm Phone",
  CONTINUE_VERIFICATION: {
    name: "Continue to Verification",
    properties: {
      SKIP_VERIFICATION: "Skip Verification?",
    },
  },
  ACCOUNT_VERIFIED: {
    name: "Account Verified",
  },
  ACCEPT_DOCUMENT_WELCOME: {
    name: "Accept Document Welcome",
    properties: {
      VARIATION: "Variation",
      OPEN_IN_MODAL: "Open in Modal",
    },
  },
  DUPLICATE_PHONE: {
    name: "Duplicate Phone",
    properties: {
      MatchType: "MatchType",
    },
  },
  PREFILL: {
    ENTER_KYC_INFO_MANUALLY: {
      name: "Enter KYC Info Manually",
      properties: {
        ErrorDuringPrefill: "Error During Pre-fill",
      },
    },
    UPLOAD_PREFILL_INFO: {
      name: "Upload Pre-fill Info",
    },
    // experiment
    PREFILL_DATA_FOUND: { name: "Prefill Data Found" },
    CONFIRM_LAST_FOUR: {
      name: "Prefill Data Confirm",
      properties: {
        Action: "Action",
      },
    },
  },
  UPLOAD_DOCUMENT: {
    name: "Upload Document",
    properties: DOCUMENT_PROPERTIES,
  },
  UPLOAD_SELFIE: {
    name: "Upload Selfie",
    properties: {
      SELFIE_TYPE: "Selfie Type",
      DOCUMENT_TYPE: "Document Type",
    },
  },
  REJECT_DOCUMENT_UPLOAD: {
    name: "Reject Document Upload",
    properties: DOCUMENT_PROPERTIES,
  },
  LEGAL_ID_ENTRY: {
    name: "Legal ID Entry",
    properties: {
      ID_TYPE: "Legal ID Type",
    },
  },
  SUBMIT_VERIFICATION: {
    name: "Submit for Verification",
    properties: {
      APPROVAL_TYPE: "Approval Type",
    },
  },
  ON_FIDO: {
    name: "OnFido",
    properties: {
      EVENT_NAME: "Event Name",
      ERROR_TYPE: "Error Type",
      ERROR_MESSAGE: "Error Message",
    },
  },
  OPEN_GIACT: {
    name: "Open Giact",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  OPEN_RTP: {
    name: "Open RTP",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  OPEN_PAYPAL: {
    name: "Open PayPal",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  OPEN_BANK_ACCOUNT: {
    name: "Open Bank Account",
  },
  OPEN_TRANSFER_TIMING_AND_LIMIT_MODAL: {
    name: "Open Transfer Timing and Limit Modal",
  },
  OPEN_TRANSFER_FEE_SCHEDULE_LINK: {
    name: "Open transfer fee schedule link",
  },
  OPEN_DESKTOP_FEE_SCHEDULE_LINK: {
    name: "Open transfer fee schedule link",
  },
  PLAID_OPTION_PRESSED: {
    name: "Plaid option pressed",
  },
  GIACT_OPTION_PRESSED: {
    name: "Giact option pressed",
  },
  GIACT_BANK_ACCOUNT_TYPE: {
    name: "Giact bank account type",
    properties: {
      TYPE: "Type",
    },
  },
  WIRE_BANK_ACCOUNT_TYPE: {
    name: "Wire bank account type",
    properties: {
      TYPE: "Type",
    },
  },
  ADD_PAYMENT_METHOD_CURRENCY_CHANGE: {
    name: "Add payment method currency change",
  },
  PLAID_BANK_DISTRIBUTION: {
    name: "Plaid bank distribution",
    properties: {
      NAME: "Name",
    },
  },
  PAYPAL_DEVICE_DATA_FAILURE: {
    name: "PayPal deviceData capture failure",
    properties: {
      ERROR: "Error",
    },
  },
  EXIT_PAYPAL: {
    name: "Exit PayPal Registration Before Complete",
  },
  OPEN_PLAID: {
    name: "Open Plaid",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  OPEN_WIRE: {
    name: "Open Wire",
  },
  UNLINKED_PLAID: {
    name: "Unlinked",
    properties: {
      EVENT_NAME: "Unlinked",
    },
  },
  PLAID_EVENT: {
    name: "Plaid Event",
    properties: {
      EVENT_NAME: "Event Name",
      ...PLAID_METADATA,
    },
  },
  SELECTED_PLAID_BANK: {
    name: "Selected Plaid bank successfully",
  },
  EXIT_PLAID: {
    name: "Exit Plaid Registration Before Complete",
  },
  ADD_BANK_INFO_THROUGH_PLAID: {
    name: "Add bank information through Plaid",
    properties: {
      PLAID_SUPPORT: "Bank Supported through Plaid?",
      PLAID_ADD: "Bank Added through Plaid?",
    },
  },
  LINK_PAYPAL_ACCOUNT: {
    name: "Link Paypal account",
  },
  ENTER_LEGAL_NAME: {
    name: "Enter Legal Name",
  },
  ENTER_DATE_OF_BIRTH: {
    name: "Enter Date of Birth",
  },
  ENTER_ADDRESS: {
    name: "Enter Address",
  },
  ENTER_TAX_ID_NUMBER: {
    name: "Enter Tax Id Number",
    properties: {
      ID_TYPE: "Id Type",
    },
  },
  ENTER_LAST_FOUR_SSN: {
    name: "Enter Last Four SSN",
  },
  ENTER_ONBOARDING_STATE: {
    name: "Enter Onboarding State",
    properties: {
      NAME: "Name",
    },
  },
  SIGN_IN_ATTEMPT: {
    name: "Sign In Attempt",
    properties: {
      USER_ID: "User ID",
    },
  },
  CLICK_SIGN_IN: {
    name: "Click Sign In",
  },
  UNABLE_TO_LOAD_RECAPTCHA: {
    name: "Unable to load recaptcha script",
  },
  CLICK_CREATE_BUSINESS_ACCOUNT: {
    name: "Click Create Business Account",
  },
  OPEN_FORGOT_PASSWORD_LINK: {
    name: "Open Forgot Password Link",
  },
  ACCEPT_USER_AGREEMENT: {
    name: "Accept User Agreement",
  },
  OPEN_POLICY_LINK: {
    name: "Open Policy Link",
    properties: {
      POLICY_URL: "Policy URL",
    },
  },
  OPEN_SUPPORT_LINK: {
    name: "Open Support Link",
    properties: {
      SUPPORT_URL: "Support URL",
    },
  },
  ENTER_FINANCIAL_INFO: {
    name: "Enter Financial Info",
  },
  CHANGE_LANGUAGE_ONBOARDING: {
    name: "Change Language during Onboarding",
    properties: {
      PREV_LANGUAGE: "Previous language",
      NEW_LANGUAGE: "New language",
    },
  },
  VERIFYING_IDENTITY_DIALOG: {
    name: "Verifying identity dialog opened",
    properties: {
      actionType: "actionType",
      isPendingVerification: "isPendingVerification",
    },
  },
  VERIFYING_IDENTITY_DIALOG_BACK_TO_ONBOARDING: {
    name: "Navigate back to onboarding from verifying identity dialog",
  },
} as const;

const INSTITUTIONAL_ONBOARDING_EVENTS = {
  ENTER_INST_ONB_STATE: {
    name: "Enter Institutional Onboarding State",
    properties: {
      STATE: "state",
    },
  },
  APPLICATION_SUBMITTED: {
    name: "Application submitted",
  },
} as const;
const CLEARING_EVENTS = {
  EDIT_COUNTER_PARTY_ALIAS: {
    name: "Edit Counter Parties",
    properties: {
      ALIAS: "Counter Party Alias",
      AUTOCONFIRM: "Autoconfirm",
    },
  },
  ADD_COUNTER_PARTY_ALIAS: {
    name: "Add Counter Party Alias",
    properties: {
      ID: "Counter Party ID",
      AUTOCONFIRM: "Autoconfirm",
      ALIAS: "Counter Party Alias",
    },
  },
  REMOVE_COUNTER_PARTY_ALIAS: {
    name: "Remove Counter Party Alias",
    properties: {
      ALIAS: "Counter Party Alias",
    },
  },
} as const;

const SETTLEMENT_EVENTS = {
  EDIT_SETTLEMENT_SETTINGS_ALIAS: {
    name: "Edit Alias",
    properties: {
      ALIAS: "Alias",
      AUTOCONFIRM: "Autoconfirm",
    },
  },
  ADD_SETTLEMENT_SETTINGS_ALIAS: {
    name: "Add Alias",
    properties: {
      ALIAS: "Alias",
      COUNTERPARTYID: "Counterparty Id",
      AUTOCONFIRM: "Autoconfirm",
    },
  },
  REMOVE_SETTLEMENT_SETTINGS_ALIAS: {
    name: "Remove Alias",
    properties: {
      ALIAS: "Alias",
    },
  },
} as const;

const BANK_EVENTS = {
  DISMISS_PLAID_DISCLAIMER: {
    name: "Dismiss Plaid Disclaimer",
  },
  CONTINUE_WITH_PLAID_RUX_NOT_AVAILABLE: {
    name: "Continue with Plaid - RUX Not Available",
  },
  CONTINUE_WITH_PLAID_DONT_SHARE_PHONE: {
    name: "Continue with Plaid - Don't Share Phone",
  },
  CONTINUE_WITH_PLAID_SHARE_PHONE: {
    name: "Continue with Plaid - Share Phone",
  },
  MANUALLY_LINK_BANK_FROM_PLAID_DISCLAIMER: {
    name: "Manually link bank from Plaid Disclaimer",
  },
  START_MANUAL_BANK_INFO_ENTRY: {
    name: "Start manual bank info entry",
  },
  FINISH_MANUAL_BANK_INFO_ENTRY: {
    name: "Finish manual bank info entry",
  },
  FINISH_MANUAL_BANK_INFO_ENTRY_GIACT: {
    name: "Finish manual bank info entry giact",
  },
  WIRE_MONEY: {
    name: "Wire Money",
    properties: {
      CANCEL_NEXT: "Canceled Wire Form Input?",
      CONFIRM_SUBMISSION: "Submitted Wire Information?",
      CANCEL_CONFIRM: "Canceled Wire Submission?",
    },
  },
  DEPOSIT_FUNDS: {
    name: "Deposit Funds",
    properties: {
      AMOUNT: "Amount",
      CURRENCY: "Currency",
    },
  },
  ENABLE_ACH_DEPOSITS: {
    name: "Enable ACH Deposits",
  },
  EDIT_BANK_ACCOUNT_NAME: {
    name: "Edit Bank Account Name",
    properties: {
      NAME: "Bank Account Name",
    },
  },
  REMOVE_BANK_ACCOUNT: {
    name: "Remove Bank Account",
    properties: {
      NAME: "Bank Account Name",
    },
  },
  MAKE_BANK_DEFAULT: {
    name: "Make Bank Default",
  },

  REMOVE_CBIT_ACCOUNT: {
    name: "Remove CBIT Account",
    properties: {
      NAME: "CBIT Account Name",
    },
  },
} as const;

const WITHDRAW_EVENTS = {
  INITIATE_FIAT_WITHDRAWAL: {
    name: "Initiate Fiat Withdrawal",
    properties: {
      WITHDRAWAL_TYPE: "Withdrawal Type",
      ...WITHDRAWAL_PROPERTIES,
    },
  },
  CONFIRM_FIAT_WITHDRAWAL: {
    name: "Confirm Fiat Withdrawal",
    properties: {
      WITHDRAWAL_TYPE: "Withdrawal Type",
      ...WITHDRAWAL_PROPERTIES,
    },
  },
  CONFIRMATION_2FA_APPROVAL: {
    name: "Confirmation 2FA Approval",
    properties: {
      TWO_FA_APPROVAL: "2FA Approval?",
    },
  },
  VIEW_BLOCK_EXPLORER: {
    name: "View Block Explorer",
    properties: {
      TRIGGER: "Trigger",
    },
  },
} as const;

const DEBIT_CARD_EVENTS = {
  GET_STARTED_DEBIT_CARD: {
    name: "Press add debit card button",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  SELECT_EXCHANGE_ACCOUNT: {
    name: "Select exchange account",
  },
  ADD_BILLING_ADDRESS: {
    name: "Add Billing Address",
  },
  START_DEBIT_CARD_VERIFICATION: {
    name: "Start debit card verification",
  },
  ADD_DEBIT_CARD_INFO: {
    name: "Add debit card info",
    properties: {
      SUBMITTED_SUCCESSFULLY: "Submitted Successfully",
      STATUS: "Status",
    },
  },
  SKIP_MICRO_DEPOSIT: {
    name: "Skip Micro-Deposit",
  },
  COMPLETE_MICRO_DEPOSIT: {
    name: "Complete micro-deposit",
    properties: {
      SUBMITTED_SUCCESSFULLY: "Submitted Successfully",
    },
  },
  ADD_DEBIT_CARD_ADDRESS: {
    name: "Add debit card address",
    properties: {
      MODE: "Mode",
      STATUS: "Status",
      IS_DEFAULT_ADDRESS_CHECKED: "Is default address checked",
    },
  },
  VERIFY_CARD_STEP_ONE: {
    name: "Verify card step one",
    properties: {
      STATUS: "Status",
    },
  },
  VERIFY_CARD_STEP_TWO: {
    name: "Verify card step two",
    properties: {
      STATUS: "Status",
    },
  },
  TRANSACTIONS_SENT_MODAL: {
    name: "Transactions sent modal",
    properties: {
      STATUS: "Status",
    },
  },
  CARD_VERIFIED_SUCCESSFULLY: {
    name: "Card verified successfully",
  },
  ADD_PAYMENT_3DS_STARTED: {
    name: "Add Payment - 3DS Started",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_DEVICE_DATA_SUCCESS: {
    name: "Add Payment - 3DS DeviceData Success",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_DEVICE_DATA_FAILED: {
    name: "Add Payment - 3DS DeviceData Failed",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_AUTHENTICATION_SUCCESS: {
    name: "Add Payment - 3DS Authentication Success",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_AUTHENTICATION_FAILED: {
    name: "Add Payment - 3DS Authentication Failed",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_VERIFICATION_SUCCESS: {
    name: "Add Payment - 3DS Verification Success",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_VERIFICATION_FAILED: {
    name: "Add Payment - 3DS Verification Failed",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_RETURN_URL_MISMATCH: {
    name: "Add Payment - 3DS ReturnUrl Mismatch",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_CARD_ADDITION_SUCCESS: {
    name: "Add Payment - 3DS Card Addition Success",
    properties: {
      METHOD: "method",
    },
  },
  ADD_PAYMENT_3DS_CARD_ADDITION_FAILED: {
    name: "Add Payment - 3DS Card Addition Failed",
    properties: {
      METHOD: "method",
    },
  },
} as const;

const XFERS_EVENTS = {
  SUBMIT_PHONE: {
    name: "Submit Xfers phone number",
    properties: {
      SUBMITTED_SUCCESSFULLY: "Submitted Successfully",
    },
  },
  SUBMIT_OTP: {
    name: "Submit Xfers OTP",
    properties: {
      SUBMITTED_SUCCESSFULLY: "Submitted Successfully",
    },
  },
  SUBMIT_BANK_INFO: {
    name: "Submit Xfers bank info",
    properties: {
      SUBMITTED_SUCCESSFULLY: "Submitted Successfully",
    },
  },
  FINAL_STEP_DEPOSIT_PAGE: {
    name: "Final Step Go To Deposit Page",
    properties: {
      CURRENCY: "Currency",
    },
  },
};

export const CUSTODY_EVENTS = {
  GET_STARTED_CUSTODY: {
    name: "Press Custody Intro Button",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  CREATE_CUSTODY_ACCOUNT: {
    name: "Create Custody Account",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  CREATE_MPC_CUSTODY_ACCOUNT: {
    name: "Create MPC Custody Account",
    properties: {
      TRIGGER: "Trigger",
    },
  },
  GENERATE_CUSTODY_ADDRESS: {
    name: "Generate Custody Address",
    properties: {
      CURRENCY: "Currency",
      TRIGGER: "Trigger",
    },
  },
} as const;

export const FCA_EVENTS = {
  ACCEPT_FCA_AGREEMENT: {
    name: "Accept UK Agreement",
  },
  DECLINE_FCA_AGREEMENT: {
    name: "Decline UK Agreement",
  },
  OPEN_FCA_AGREEMENT: {
    name: "Open UK Agreement Modal",
  },
} as const;

const GROW_EVENTS = {
  SELECT_GROW_FILTER: {
    name: "Select Grow Page Filter",
    properties: {
      FILTER: "Filter",
    },
  },
  GROW_LEARN_MORE_CARD_CLICK: {
    name: "Click Learn More Card",
    properties: {
      ELIGIBLE_FOR_STAKING: "Eligible for staking",
      ELIGIBLE_FOR_STAKING_PRO: "Eligible for staking pro",
    },
  },
  GROW_ASSET_CARD_DETAILS_CLICK: {
    name: "Click Asset Details From Asset Card",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_ASSET_ROW_CLICK: {
    name: "Click Asset Row",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_ASSET_CARD_STAKE_CLICK: {
    name: "Click Stake From Asset Card",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_ROUTE_HEADER_STAKE_CLICK: {
    name: "Click Stake From Currency Route Header",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_ROUTE_HEADER_UNSTAKE_CLICK: {
    name: "Click Unstake From Currency Route Header",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_PENDING_UNSTAKES_CARD_CLICK: {
    name: "Click Pending Unstakes Card",
    properties: {
      HAS_MULTIPLE: "Has Multiple unstakes",
    },
  },
  GROW_PENDING_UNSTAKE_ROW_CLICK: {
    name: "Click Pending Unstake Row",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_LEARN_ABOUT_CURRENCY_CLICK: {
    name: "Click Learn More From Grow Currency Route",
    properties: {
      CURRENCY: "Currency",
    },
  },
  GROW_CURRENCY_BALANCE_TYPE_CLICK: {
    name: "Select Currency Staking Balance",
    properties: {
      CURRENCY: "Currency",
      BALANCE_TYPE: "Balance Type",
    },
  },
  GROW_VALIDATOR_ROW_CLICK: {
    name: "Click Validator Row",
    properties: {
      CURRENCY: "Currency",
    },
  },
} as const;

const STAKING_EVENTS = {
  INSTANTIATE_STAKE_UNSTAKE_MODAL: {
    name: "Instantiate stake/unstake modal",
    properties: {
      CURRENCY: "Currency",
      DIRECTION: "Direction",
      ELIGIBLE_FOR_STAKING: "Eligible for staking",
      ELIGIBLE_FOR_STAKING_PRO: "Eligible for staking pro",
    },
  },
  VIEW_STAKE_AMOUNT_INPUT: {
    name: "View stake amount input",
    properties: {
      CURRENCY: "Currency",
      PROVIDER_TYPE: "Provider Type",
    },
  },
  ENTER_STAKE_AMOUNT: {
    name: "Enter stake amount",
    properties: {
      AMOUNT: "Amount",
      CURRENCY: "Currency",
      PROVIDER_TYPE: "Provider Type",
      FUNDING_SOURCE: "Funding Source",
      NEXT_VIEW: "Next view in the deposit flow",
    },
  },
  VIEW_STAKING_AGREEMENT: {
    name: "View staking agreement",
    properties: {
      COUNTRY_CODE: "Country code",
    },
  },
  ACCEPT_STAKING_AGREEMENT: {
    name: "Accept staking agreement",
    properties: {
      COUNTRY_CODE: "Country code",
    },
  },
  DECLINE_STAKING_AGREEMENT: {
    name: "Decline staking agreement",
    properties: {
      COUNTRY_CODE: "Country code",
    },
  },
  VIEW_TAX_INFORMATION_DISCLAIMER: {
    name: "View staking tax informational disclaimer",
    properties: {
      COUNTRY_CODE: "Country code",
    },
  },
  ACCEPT_TAX_INFORMATION_DISCLAIMER: {
    name: "Accept staking tax informational disclaimer",
    properties: {
      COUNTRY_CODE: "Country code",
    },
  },
  VIEW_ASSET_INFORMATION_DISCLAIMER: {
    name: "View staking asset informational disclaimer",
    properties: {
      CURRENCY: "Currency",
    },
  },
  ACCEPT_ASSET_INFORMATION_DISCLAIMER: {
    name: "Accept staking asset informational disclaimer",
    properties: {
      CURRENCY: "Currency",
    },
  },
  PLACE_UNSTAKE: {
    name: "Unstake",
    properties: {
      AMOUNT: "Amount",
      ASSET: "Asset",
      CURRENCY: "Currency",
      IS_MOBILE: "Is Mobile",
      USED_HALF_BUTTON: "Used Half Button",
      USED_MAX_BUTTON: "Used Max Button",
    },
  },
  VIEW_REVIEW_STAKE_DEPOSIT: {
    name: "View review stake deposit",
    properties: {
      CURRENCY: "Currency",
      PROVIDER_TYPE: "Provider Type",
      AMOUNT: "Amount",
      FUNDING_SOURCE: "Funding Source",
      SCHEDULE: "Schedule",
    },
  },
  STAKE_DEPOSIT: {
    name: "Stake deposit",
    properties: {
      CURRENCY: "Currency",
      PROVIDER_TYPE: "Provider Type",
      AMOUNT: "Amount",
      FUNDING_SOURCE: "Funding Source",
      SCHEDULE: "Schedule",
    },
  },
  VIEW_PICK_STAKING_PRODUCT: {
    name: "View Staking product picker",
    properties: {
      CURRENCY: "Currency",
    },
  },
  SELECT_STAKING_PROVIDER_TYPE: {
    name: "Select Staking Provider Type",
    properties: {
      CURRENCY: "Currency",
      PROVIDER_TYPE: "Provider Type",
    },
  },
  SELECT_STAKING_DEPOSIT_SOURCE: {
    name: "Select Staking Deposit Source",
    properties: {
      CURRENCY: "Currency",
      PROVIDER_TYPE: "Provider Type",
      SOURCE: "Source",
    },
  },
  TOGGLE_STAKING_BUY_SELL: {
    name: "Toggle Staking in BuySell",
    properties: {
      CURRENCY: "Currency",
    },
  },
  PORTFOLIO_ACTION_STAKE: {
    name: "Stake Currency from Portfolio Action",
    properties: {
      CURRENCY: "Currency",
    },
  },
  PORTFOLIO_ACTION_UNSTAKE: {
    name: "Unstake Currency from Portfolio Action",
    properties: {
      CURRENCY: "Currency",
    },
  },
  OPEN_STAKING_TRANSACTION_MODAL: {
    name: "Open Staking Transaction from Transaction History",
    properties: {
      CURRENCY: "Currency",
      AMOUNT: "Amount",
      PROVIDER_TYPE: "Provider Type",
      REDEEMED_IN_FULL: "Redeemed in Full",
    },
  },
} as const;

export const PORTFOLIO_PAGE_EVENTS = {
  PORTFOLIO_PAGE_VIEW: {
    name: "Portfolio Page View",
    properties: {
      ACCOUNT_TYPE: "Account Type",
      IS_MULTI_ACCOUNT: "Is Multi Account?",
    },
  },
  BALANCE_ROW_LINK_CLICK: {
    name: "Balance Row – Link Click",
    properties: {
      ASSET: "Asset",
    },
  },
  BALANCE_ROW_HOLDS_CLICK: {
    name: "Balance Row – Holds Click",
    properties: {
      ASSET: "Asset",
    },
  },
  BALANCE_ROW_OPEN_OVERFLOW_MENU: {
    name: "Balance Row – Overflow Menu Click",
    properties: {
      ASSET: "Asset",
      IS_OPENING: "Is Opening?",
    },
  },
  BALANCE_ROW_OVERFLOW_MENU_ITEM_CLICK: {
    name: "Balance Row – Overflow Menu Item Click",
    properties: {
      ASSET: "Asset",
      ACTION: "Action",
      IS_SWITCHING_ACCOUNT: "Is Switching Account?",
    },
  },
  QUICK_ACTIONS_CLICK: {
    name: "Quick Actions – Click",
    properties: {
      ACTION: "Action",
      IS_SWITCHING_ACCOUNT: "Is Switching Account?",
    },
  },
  ACCOUNT_SNAPSHOT_ITEM_CLICK: {
    name: "Account Snapshot – Item Click",
    properties: {
      TIME_SINCE_PAGELOAD: "Time since pageload (s)",
      NUMBER_OF_ACCOUNT_SWITCHES: "# of account switches",
    },
  },
  ACCOUNT_SNAPSHOT_SEARCH_CLICK: {
    name: "Account Snapshot – Search Click",
  },
  CHART_TIMEFRAME_CLICK: {
    name: "Chart – Timeframe Click",
    properties: {
      TIMEFRAME: "Timeframe",
    },
  },
  TX_HISTORY_TAB_CLICK: {
    name: "TX History – Tab Click",
    properties: {
      VALUE: "Value",
    },
  },
  TX_HISTORY_OVERFLOW_ITEM_CLICK: {
    name: "TX History – Overflow Item Click",
    properties: {
      ITEM_TYPE: "Item Type",
    },
  },
  TX_HISTORY_DOWNLOAD_BUTTON_CLICK: {
    name: "TX History – Download Button Click",
    properties: {
      IS_SWITCHING_ACCOUNT: "Is Switching Account?",
      CALLSITE: "Callsite",
    },
  },
  OPEN_ORDERS_ITEM_CLICK: {
    name: "Open Orders – Item Click",
  },
  OPEN_ORDERS_CANCEL_ORDER_CLICK: {
    name: "Open Orders – Cancel Order Click",
    properties: {
      IS_CURRENT_ACCOUNT: "Is Current Account?",
    },
  },
  OPEN_ORDERS_SHOW_MORE_CLICK: {
    name: "Open Orders – Show More Click",
  },
  SWITCH_ACCOUNT_CLICK: {
    name: "Switch Account – Click",
  },
  RECURRING_ORDERS_SHOW_MORE_CLICK: {
    name: "Recurring buys – Show More Click",
  },
} as const;

const GUSD_EVENTS = {
  PORTFOLIO_CONVERT_TO_USD: {
    name: "Quick Convert to USD",
    properties: {
      AMOUNT: "Amount",
    },
  },
  PORTFOLIO_CONVERT_TO_GUSD: {
    name: "Quick Convert to GUSD",
    properties: {
      AMOUNT: "Amount",
    },
  },
} as const;

const BALANCES_PAGE = {
  BALANCES_TAB_CLICK: {
    name: "Click Balances Tab",
    properties: {
      TAB: "Tab",
    },
  },
} as const;

const EARN_EVENTS = {
  BEGIN_EARN_DEPOSIT: {
    name: "Begin Earn Deposit",
    properties: {
      CURRENCY: "Currency",
    },
  },
  ENTER_EARN_DEPOSIT_AMOUNT: {
    name: "Enter Earn Deposit Amount",
    properties: {
      CURRENCY: "Currency",
      AMOUNT: "Amount",
    },
  },
  ACCEPT_EARN_TERMS_AGREEMENT: {
    name: "Accept Earn Terms Agreement",
  },
  ACCEPT_MASTER_LOAN_AGREEMENT: {
    name: "Accept Earn Master Loan Agreement",
  },
  ACCEPT_STAKING_AGREEMENT: {
    name: "Accept Staking Agreement",
  },
  EARN_DEPOSIT: {
    name: "Earn Deposit",
    properties: {
      ASSET: "Asset", // BTC
      AMOUNT: "Amount", // Refers to Asset, ie: 0.005
      CURRENCY: "Currency", //USD
      FLOW_TYPE: "Flow Type",
      PROVIDER_ID: "Provider ID",
      VALUE: "Value", // Refers to Currency, ie: 123.05
      USED_HALF_BUTTON: "Used Half Button",
      USED_MAX_BUTTON: "Used Max Button",
      IS_MOBILE: "Is Mobile",
    },
  },
  BEGIN_EARN_REDEEM: {
    name: "Begin Earn Redeem",
    properties: {
      CURRENCY: "Currency",
    },
  },
  ENTER_EARN_REDEEM_AMOUNT: {
    name: "Enter Earn Redeem Amount",
    properties: {
      CURRENCY: "Currency",
      AMOUNT: "Amount",
    },
  },
  EARN_REDEEM: {
    name: "Earn Redeem",
    properties: {
      AMOUNT: "Amount",
      ASSET: "Asset",
      CURRENCY: "Currency",
      IS_MOBILE: "Is Mobile",
      USED_HALF_BUTTON: "Used Half Button",
      USED_MAX_BUTTON: "Used Max Button",
    },
  },
  RETAIL_EARN_TOGGLE: {
    name: "Retail Earn Toggle",
    properties: {
      VALUE: "Value",
    },
  },
  BUY_AND_EARN: {
    name: "Buy and Earn",
    properties: {
      ASSET: "Asset Name",
      ASSET_TICKER: "Asset Ticker",
      ASSET_APY: "Asset APY",
      ASSET_URL: "Asset URL",
      CURRENCY_PAIR: "Currency Pair",
      FEE: "Fee",
      MOBILE_PAIR: "Mobile Pair",
      QUANTITY: "Quantity",
      SUGGESTED_AMOUNTS_USED: "Suggested Amounts Used",
      TIME_EXECUTED: "Time of execution",
      TOTAL: "Total",
      PRICE: "Price",
      NOTIONAL_TOTAL: "Notional USD Total",
      PROVIDER_ID: "Provider Id",
    },
  },
  EARN_DISTRIBUTION_CLAIM: {
    name: "Earn distribution claim",
    properties: {
      STATUS: "Status",
    },
  },
  EARN_DISTRIBUTIONS_PAGE_VIEW: {
    name: "View Earn Distributions Page",
    properties: {
      ORIGIN: "Origin",
    },
  },
} as const;

export const HOMEPAGE_EVENTS = {
  SHOW_HOMEPAGE_CHECKLIST: { name: "Show Homepage Onboarding Bottomsheet Checklist" },
  COMPLETE_CHECKLIST_ITEM: {
    name: "Complete onboarding checklist item",
    properties: {
      CHECKLIST_ITEM: "Checklist Item",
    },
  },
  CHECKLIST_COMPLETE: { name: "Completed Homepage Onboarding Checklist" },
  PAYMENT_METHOD_ADDED: {
    name: "Payment method added from checklist",
    properties: {
      PAYMENT_TYPE: "Payment type",
    },
  },
  START_EARNING: { name: "Tap Homepage Start Earning" },
  START_STAKING: { name: "Tap Homepage Start Staking" },
  VIEW_PORTFOLIO: { name: "Tap Homepage View Portfolio" },
  START_TRADING: { name: "Tap Homepage Start Trading" },
  VIEW_ALL_WATCHLIST: { name: "Tap Homepage View All Watchlist" },
  FILTERED_ASSET: {
    name: "Tap Filtered Asset",
    properties: {
      FILTER: "Filter",
      CURRENCY: "Currency",
    },
  },
  OPEN_ARTICLE: {
    name: "Open Cryptopedia Article",
    properties: {
      ARTICLE_URL: "Article url",
    },
  },
  HOMEPAGE_SHOW_ACCOUNT_ALERT: {
    name: "Show Homepage Account status",
    properties: {
      STATUS_TYPE: "Status type",
    },
  },
  HOMEPAGE_COMPLETED_STATUS: "Complete Homepage Account status",
  VIEW_PENDING_BALANCES: { name: "Tap Homepage Pending Balances" },
  HOMEPAGE_DERIVATIVES_CARD_CTA: "Tap Homepage Gemini Derivatives card",
  HOMEPAGE_LEADERBOARD_CARD_CTA: "Tap Homepage Gemini Derivatives card",
};

export const GIFTING_EVENTS = {
  OPEN_REDEEM_GIFT_MODAL_FROM_SEND_PAGE: {
    name: "Opened redeem gift modal from send page",
  },
  USE_SUGGESTED_AMOUNT: {
    name: "Clicked suggested gift amount",
    properties: {
      SUGGESTED_AMOUNT: "Suggested amount",
    },
  },
  USE_GIVE_ANOTHER_GIFT: {
    name: "Clicked give another gift",
  },
  SEND_GIFT: {
    name: "Sent gift successfully",
    properties: {
      AMOUNT: "Gift amount (in BTC)",
    },
  },
  REDEEM_GIFT: {
    name: "Redeemed gift successfully",
  },
};

export const NOTIFICATION_CENTER_EVENTS = {
  SET_NOTIFICATION_PREFERENCES: {
    name: "Set notification preferences",
    properties: {
      NOTIFICATION_TYPE: "Notification type",
      STATUS: "Status",
    },
    values: {
      STATUS: {
        on: "on",
        off: "off",
      },
    },
  },
  VIEW_NOTIFICATIONS: {
    name: "View notifications",
  },
  TAP_NOTIFICATION_ACTION: {
    name: "Tap notification action",
    properties: {
      FILTER_NAME: "Filter name",
      DEEPLINK: "Deeplink",
    },
  },
};

export const PORTFOLIO_SETTINGS_EVENTS = {
  SET_HIDE_DUST: {
    name: "Set hide dust",
    values: {
      STATUS: {
        on: "on",
        off: "off",
      },
    },
  },
  SET_HIDE_BALANCES_ON_HOME: {
    name: "Set hide balances on home",
    values: {
      STATUS: {
        on: "on",
        off: "off",
      },
    },
  },
};

export const LEADERBOARD_EVENTS = {
  SET_LEADERBOARD_OPT_IN_OUT: {
    name: "Set the leaderboard opt in/out toggle",
    values: {
      STATUS: {
        on: "on",
        off: "off",
      },
    },
  },
  LEADERBOARD_MODAL_AUTO_DISPLAY: {
    name: "Leaderboard modal auto display",
  },
  LEADERBOARD_MODAL_CONDENSED_AUTO_DISPLAY: {
    name: "Leaderboard modal condensed auto display",
  },
  LEADERBOARD_MODAL_DISMISSED: {
    name: "Leaderboard modal dismissed",
  },
  LEADERBOARD_MODAL_JOIN: {
    name: "Leaderboard modal join button clicked",
  },
  LEADERBOARD_MODAL_OPT_IN: {
    name: "Leaderboard modal T&C accepted and opt in",
  },
  LEADERBOARD_MODULE: {
    TOGGLE: {
      name: "Leaderboard module toggle",
      properties: {
        ACTION: "Action",
      },
      values: {
        STATUS: {
          open: "open",
          close: "close",
        },
      },
    },
    CLICK: {
      name: "Leaderboard module click",
      properties: {
        button: "Button",
      },
      values: {
        BUTTONS: {
          settings: "Settings",
          leaderboard: "Leaderboard",
          join: "Join",
        },
      },
    },
  },
  LEADERBOARD_IMAGE_UPLOAD_START: {
    name: "Leaderboard image upload start",
  },
  LEADERBOARD_IMAGE_UPLOAD_LARGE_FILE_ERROR: {
    name: "Leaderboard image upload large file error",
  },
  LEADERBOARD_IMAGE_UPLOAD_SUCCESS: {
    name: "Leaderboard image upload success",
  },
  LEADERBOARD_IMAGE_UPLOAD_FAILURE: {
    name: "Leaderboard image upload failure",
  },
};

export const TAX_CENTER_EVENTS = {
  TAX_CENTER_FLOW_BEGINNING: {
    name: "Tax Center Flow Beginning",
  },
  COST_BASIS_METHOD: {
    name: "Cost basis method",
    properties: {
      OPEN_COST_BASIS_MODAL: "Open cost basis modal",
      CHOOSE_COST_BASIS_METHOD: "Choose cost basis method",
    },
  },
  TRANSFERRED_ASSETS: {
    name: "Transferred assets",
    properties: {
      FILTER: "Filter",
      CLICK_ON_ADD_DETAILS: "Click on add transferred assets details",
      ZERO_TO_TWENTY_FIVE: "0-25",
      TWENTY_SIX_T0_FIFTY: "26-50",
      FIFTY_ONE_TO_SEVENTY_FIVE: "51-75",
      SEVENTY_SIX_TO_ONE_HUNDRED: "76-100",
    },
  },
  GENERATE_STATEMENT: {
    name: "Generate statement",
    properties: {
      INPUT: "Input",
    },
  },
  UPDATE_STATEMENT: {
    name: "Update statement",
  },
};

export const SIGNED_OUT_EVENTS = {
  HEADER_CLICK: {
    name: "Signed out header click",
    properties: {
      TRIGGER: "Trigger",
    },
    callsites: {
      GEMINI_LOGO: "Gemini logo",
      BACK_LINK: "Back to Gemini.com",
      REGISTER_BUTTON: "Register button",
      SIGN_IN_BUTTON: "Sign in button",
    },
  },
  CTA_CLICK: {
    name: "Signed out CTA click",
    properties: {
      TRIGGER: "Trigger",
    },
    callsites: {
      CREATE_ACCOUNT_AT_BUY_SELL: "Create account button in AT buy/sell",
      SIGN_IN_BUTTON_AT_MODAL: "Sign in button in AT modal",
      CREATE_ACCOUNT_BUTTON_AT_MODAL: "Create account button in AT modal",
      SIGN_IN_LINK_AT_ORDERS_TABLE: "Sign in link in AT orders table",
      CREATE_ACCOUNT_AT_LINK_ORDERS_TABLE: "Create account link in AT orders table",
    },
  },
};

const DEPOSIT_CRYPTO_MODAL_EVENTS = {
  DEPOSIT_CRYPTO_SELECT_CURRENCY: {
    name: "Deposit crypto - select currency",
    properties: {
      Token: "token",
    },
  },
  DEPOSIT_CRYPTO_CONFIRM_WARNING_MODAL: {
    name: "Deposit crypto - confirm warning modal",
  },
  DEPOSIT_CRYPTO_DEPOSIT_SUCCESSFUL: {
    name: "Deposit crypto - deposit successful",
  },
  DEPOSIT_CRYPTO_CREATE_NEW_ADDRESS_LINK: {
    name: "Deposit crypto - create new address link",
  },
};

const ACTIVE_TRADER_EVENTS = {
  ACTIVE_TRADER: {
    REDESIGN: {
      USAGE: {
        name: "Using Active Trader redesign",
        properties: {
          IS_DUAL_MARKETS: "isDualMarkets",
          PRIMARY_PAIR: "primaryPair",
          SECONDARY_PAIR: "secondaryPair",
        },
      },
      GTM_TOGGLE: {
        name: "GTM toggle",
        properties: {
          ACTION: "Action",
        },
        values: {
          STATUS: {
            open: "open",
            close: "close",
          },
        },
      },
    },
    DUAL_MARKETS: {
      TOGGLE: {
        name: "Dual markets toggle",
        properties: {
          ACTION: "Action",
        },
        values: {
          STATUS: {
            open: "open",
            close: "close",
          },
        },
      },
    },
  },
};

const ALERT_EVENTS = {
  VIEW_ALERTS: {
    name: "View Alerts",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
    },
  },
  FILTER_ALERTS: {
    name: "Filter Alerts",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      ALERT_TYPE_FILTER: "Alert Type Filter",
    },
  },
  SORT_ALERTS: {
    name: "Sort Alerts",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      ALERT_SORT_ORDER: "Alert Sort Order",
    },
  },
  TOGGLE_PINNED_PAIR_ALERTS: {
    name: "Toggle Pinned Pair Alerts",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      IS_PINNED: "Is Pinned?",
    },
  },
  CREATE_ALERT: {
    name: "Create Alert",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      PRICE: "Price",
      PERCENTAGE: "Percentage",
      CREATED_DATE: "Created Date",
      ALERT_TYPE: "Alert type",
    },
  },
  DELETE_ALERT: {
    name: "Delete Alert",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      PRICE: "Price",
      CREATED_DATE: "Created Date",
      ALERT_TYPE: "Alert type",
    },
  },
  ENABLE_ALERT: {
    name: "Enable Alert",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      ALERT_TYPE: "Alert type",
    },
  },
  DISABLE_ALERT: {
    name: "Disable Alert",
    properties: {
      CURRENCY_PAIR: "Currency Pair",
      ALERT_TYPE: "Alert type",
    },
  },
  ENABLE_MARKET_ALERT: {
    name: "Enable Market Alert",
    properties: {
      ASSET: "Asset",
      CURRENCY_PAIR: "Currency Pair",
    },
  }, // for old UI; deprecated & marked for deletion soon
  DISABLE_MARKET_ALERT: {
    name: "Disable Market Alert",
    properties: {
      ASSET: "Asset",
      CURRENCY_PAIR: "Currency Pair",
    },
  }, // for old UI; deprecated & marked for deletion soon
} as const;

const PASSKEY_EVENTS = {
  ADD_PASSKEY_CLICKED: "Add passkey CTA",
  CREATE_PASSKEY_CLICKED: "Create passkey CTA",
  USER_REGISTERED_PASSKEY: {
    name: "User Registered a Passkey",
    properties: { Location: "Location", Platform: "Platform" },
  },
  PASSKEY_REGISTRATION_FAILED: {
    name: "Passkey Registration Failed",
    properties: {
      Message: "message",
    },
  },
  SIGNIN_WITH_PASSKEY_CLICKED: "Signin with passkey initiated",
  PASSKEY_LOGIN_SUCCESS: "Passkey Login Success",
  SIGNIN_WITH_PASSKEY_ERROR_FETCHING_PROOF: {
    name: "Signin with passkey error fetching proof",
    properties: { Error: "Error" },
  },

  PASSKEY_LOGIN_FAILED: { name: "Passkey Login Failed", properties: { Error: "Error" } },
  CREATE_PASSKEY_SKIP_FOR_NOW: {
    name: "Create passkey skip for now",
    properties: {
      Location: "Location",
    },
  },
  SIGNIN_WITH_PASSKEY_TOGGLE: {
    name: "Signin with passkey toggle",
    properties: {
      Enabled: "Enabled",
    },
  },
  PASSKEY_CREATION_AT_VERIFY: {
    name: "Passkey Creation at Verify",
    properties: {
      Selection: "Selection",
      Platform: "Platform",
    },
  },
} as const;
export const EVENTS = {
  PAGE_VIEW: {
    name: "Page View",
    properties: {
      PAGE_NAME: "Page Name",
    },
  },
  // TODO: Temporary homepage view tracker
  // Needs backend page name fix
  PAGE_VIEW_VARIANT: {
    name: "Page view variant",
    properties: {
      PAGE_VARIANT: "Page variant",
      USER_COUNTRY: "User Country",
    },
  },
  ASSET_PAGE_VIEW: {
    name: "Asset Page View",
    properties: {
      ASSET_EARN_APY: "Asset Earn APY",
      ASSET_NAME: "Asset Name",
      ASSET_TICKER: "Asset Ticker",
      ASSET_URL: "Asset URL",
    },
  },
  NAVIGATION_PAGE_VIEW: {
    name: "Web Page: View",
    properties: {
      CURRENT_URL: "Current URL",
      PREVIOUS_URL: "Previous URL",
    },
  },
  EXPERIMENT_STARTED: {
    name: "Experiment Started",
    properties: {
      EXPERIMENT_NAME: "Experiment Name",
      VARIANT_NAME: "Variant Name",
    },
  },
  TOGGLE_ACTIVE_TRADER: {
    name: "Toggle ActiveTrader Interface",
    properties: {
      ACTIVE_TRADER_ENABLED: "ActiveTrader Enabled",
      TRIGGER: "Location Triggered",
    },
  },
  ACTIVE_TRADER_FROM_OTHER_ORDER_TYPES: {
    name: "Looking for other order types",
    properties: {
      SWITCH_TO_ACTIVE_TRADER: "Switch to ActiveTrader",
    },
  },
  ACTIVE_TRADER_WELCOME: {
    name: "Welcome to ActiveTrader",
    properties: {
      ACTION: "Action",
    },
  },
  TOGGLE_BACK_TO_SIMPLE_MODE_FROM_AT_WELCOME: {
    name: "Toggle back to simple mode from AT welcome",
  },
  NAVIGATE_TRADE_PANEL: {
    name: "Navigate Trade Panel",
    properties: {
      PANEL_NAME: "Panel Name",
      ACTION: "Action",
      CURRENCY: "Currency",
    },
  },
  SELECT_MARKET_FILTER: {
    name: "Select Market Filter",
    properties: {
      FILTER_TYPE: "filter type",
    },
  },
  VIEW_DETAILS_FROM_MARKET: {
    name: "Tap View Details",
    properties: {
      VIEW_DETAILS_TAPPED: "View details tapped",
    },
  },
  ADD_TO_WATCHLIST: {
    name: "Add to Watchlist",
    properties: {
      CURRENCY: "Currency",
      PREVIOUS_SIZE: "Previous watchlist size",
      UPDATED_SIZE: "Updated watchlist size",
    },
  },
  SIGN_OUT: {
    name: "Sign out",
  },
  LOOKING_FOR_DIFFERENT_ORDER_TYPES: "Looking For Different Order Types",
  VIEWED_HOLD_PERIOD_TOOLTIP: "Viewed hold period tooltop",
  PAYMENT_METHOD_REGISTRATION_FAILURE: {
    name: "Payment method registration failure",
    properties: {
      PAYMENT_METHOD_TYPE: "Payment method type",
      ERROR: "Error",
    },
  },
  PAYMENT_METHOD_REGISTRATION_SUCCESS: {
    name: "Payment method registration success",
    properties: {
      PAYMENT_METHOD_TYPE: "Payment method type",
    },
  },
  CHANGE_LANGUAGE: {
    name: "Change Language",
    properties: {
      PREV_LANGUAGE: "Previous language",
      NEW_LANGUAGE: "New language",
    },
  },
  UK_FIN_PROM_FORM: {
    name: "UK financial promotions form",
    properties: {
      Form: "Form",
      RequiredForms: "RequiredForms",
      ApiResponse: "ApiDataResponse",
    },
  },
  UK_FIN_PROM_API_SUCCESS: {
    name: "UK financial promotions success",
    properties: {
      Step: "Step", // Categorization | Appropriateness | Attestation
    },
  },
  OPEN_SINGAPORE_FAST_BANK_REGISTRATION: {
    name: "Open Singapore FAST bank registration modal",
  },
  PLACE_INSTANT_BUY_ORDER: "Web - Place Instant Buy Order",
  ATTEMPT_INSTANT_BUY_ORDER: "Web - Attempt Instant Buy Order",
  PLACE_SELL_ORDER: "Web - Place Sell Order",
  PLACE_CONVERT_ORDER: "Web - Place Convert Order",
  REVIEW_ORDER: "Web - Review Order",
  ORDER_TYPES_VIEWED: "Web - Order Types Viewed",
  ORDER_TYPE_SELECTED: {
    name: "Web - Order Type Selected",
    properties: {
      ORDER_TYPE: "Order Type",
    },
  },
  UK_FIN_PROM_LEAVE_JOURNEY: {
    name: "UK Financial Promotion - Leave Journey",
    properties: {
      Step: "step",
    },
  },
  UK_FIN_PROM_PROCEED: {
    name: "UK Financial Promotion - Proceed",
    properties: {
      Step: "step",
    },
  },
  ...ALERT_EVENTS,
  ...BANK_EVENTS,
  ...CLEARING_EVENTS,
  ...CREDIT_CARD_EVENTS,
  ...CREDIT_CARD_EVENTS_V2,
  ...CREDIT_CARD_DISPUTES_EVENTS,
  ...CREDIT_CARD_REWARD_DISPUTES_EVENTS,
  ...CREDIT_CARD_HOMEPAGE_EVENTS,
  ...CREDIT_CARD_PAYMENT_EVENTS,
  ...CREDIT_CARD_ACTIVATE_CARD_EVENTS,
  ...CREDIT_CARD_DASHBOARD_CARD_TILE_V2,
  ...CREDIT_CARD_WARM_WELCOME_EVENTS,
  ...CREDIT_CARD_INTRO_MODAL_EVENTS,
  ...CUSTODY_EVENTS,
  ...DEBIT_CARD_EVENTS,
  ...GROW_EVENTS,
  ...EARN_EVENTS,
  ...FCA_EVENTS,
  ...FIAT_TRANSFER_EVENTS,
  ...CRYPTO_TRANSFER_EVENTS,
  ...GIFTING_EVENTS,
  ...HOMEPAGE_EVENTS,
  ...INSTITUTIONAL_ONBOARDING_EVENTS,
  ...NOTIFICATION_CENTER_EVENTS,
  ...ONBOARDING_EVENTS,
  ...REWARD_HUB_EVENTS,
  ...STAKING_EVENTS,
  ...WITHDRAW_EVENTS,
  ...XFERS_EVENTS,
  ...SETTLEMENT_EVENTS,
  ...PORTFOLIO_SETTINGS_EVENTS,
  ...LEADERBOARD_EVENTS,
  ...SIGNED_OUT_EVENTS,
  ...GUSD_EVENTS,
  ...BALANCES_PAGE,
  ...PORTFOLIO_PAGE_EVENTS,
  ...DEPOSIT_CRYPTO_MODAL_EVENTS,
  ...ACTIVE_TRADER_EVENTS,
  ...PASSKEY_EVENTS,
} as const;

export type Events = (typeof EVENTS)[keyof typeof EVENTS];

export const ADWORDS_PROPERTIES = {
  VALUE: "value",
  CURRENCY: "currency",
} as const;

export const SNAPCHAT_PROPERTIES = {
  CURRENCY: "currency",
  PRICE: "price",
} as const;

export enum EarnFlowType {
  Transfer = "Transfer",
  BuyAndTransfer = "Buy and Transfer",
}

export enum GoogleAnalyticsEvents {
  CreateAccount = "Register",
  EmailVerify = "Email verify",
  PhoneVerify = "Phone verify",
  KYCComplete = "KYC complete",
  LinkFundingStarted = "Link funding started",
  LinkedFundingSource = "Linked funding source",
  DepositFunds = "Deposit Funds",
  PlaceTrade = "Place Trade",
}

export enum GoogleTagManagerEvents {
  REGISTER = "register",
  EMAIL_VERIFY = "emailVerify",
  PHONE_VERIFY = "phoneVerify",
  KYC_COMPLETE = "kycComplete",
  DEPOSIT = "deposit",
  TRADE = "trade",
}

export enum TwitterEvents {
  DepositFunds = "o4v3x",
  PlaceTrade = "o4v40",
  Verification = "o4wve",
  EarnDeposit = "o5nwc",
  ConfirmPhone = "o5vl3",
}

export enum FacebookEvents {
  AddToCart = "AddToCart",
  InstitutionalRegistration = "InstitutionalRegister",
}

export enum RedditEvents {
  CreateAccount = "SignUp",
  DepositFunds = "AddToCart",
  PlaceTrade = "Purchase",
}

export enum TikTokEvents {
  Registration = "Registration",
  ConfirmPhone = "Consult",
  DepositFunds = "AddBilling",
  PlaceTrade = "Purchase",
  EarnDeposit = "AddToCart",
}

export enum BingEvents {
  Registration = "Registration",
  ConfirmPhone = "ConfirmPhone",
  DepositFunds = "DepositFunds",
  PlaceTrade = "PlaceTrade",
  EarnDeposit = "EarnDeposit",
}

export enum BingVariableRevenueProperty {
  EventValue = "event_value",
  RevenueValue = "revenue_value",
  Currency = "currency",
}

export interface BingEventProperties {
  event_category: string;
  revenue_value?: string;
  currency?: string;
}

export const DEFAULT_BING_EVENT_PROPERTIES: BingEventProperties = {
  event_category: "Account",
};

export interface TwitterConversionEventProperties {
  tw_sale_amount: number;
  tw_order_quantity: number;
  value?: string;
  currency?: string;
  num_items?: string;
}

export const DEFAULT_TWITTER_CONVERSION_PROPERTIES: TwitterConversionEventProperties = {
  tw_sale_amount: 0,
  tw_order_quantity: 0,
};

type AnalyticsPropertyValue = string | number | boolean | string[] | number[];
export type AnalyticsEventName = string;
interface AnalyticsComplexPropertyValue {
  name: AnalyticsEventName;
  properties: {
    [propName: string]: AnalyticsPropertyValue;
  };
}
export type AnalyticsProperty = {
  [analyticsEventName in AnalyticsEventName]?: AnalyticsPropertyValue | AnalyticsComplexPropertyValue;
};
